import styled from 'styled-components/macro'
import { useStore } from 'Store'
import React from 'react'

export default function ToggleAudio() {
  const { audioEnabled, toggleAudio } = useStore()

  return (
    <Button onClick={toggleAudio}>
      ♫
      {!audioEnabled && (
        <SlashWrapper>
          <Slash />
        </SlashWrapper>
      )}
    </Button>
  )
}

const Button = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: calc(10px * var(--slight-scale));
  right: calc((104px + 10px + 10px) * var(--slight-scale));
  z-index: 101;
  height: calc(44px * var(--slight-scale));
  width: calc(44px * var(--slight-scale));
  border: none;
  display: block;
  font-size: calc(25px * var(--slight-scale));
  border: calc(2px * var(--slight-scale)) solid black;
  border-radius: calc(4px * var(--slight-scale));
  background-color: #ffd89b;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SlashWrapper = styled.div`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const Slash = styled.div`
  height: calc(40px * var(--slight-scale));
  width: calc(4px * var(--slight-scale));
  background-color: red;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
`
