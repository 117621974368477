import clientId from 'clientId'

export default (activity: string) => {
  fetch('/api/activities', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      clientId,
      activity,
    }),
  })
}
