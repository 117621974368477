export default `
# Chapter
$ bg = barn-klasserom
title = Onsdag
subtitle = På skolen

# Dialogue
[Luna neutral left 200]
[Bix surprised right 475]
[Noah neutral right 750]
Bix: Det kommer en stjernestorm i morgen.
Bix: Så skolen er stengt.

[Bix happy]
Bix: Skal vi spille MåneSverdet sammen?

[Noah happy]
Noah: Ja! Jeg vil ha den hjelmen du har.

[Luna sad]
[Noah neutral]
Luna: Jeg får ikke lov å spille.
Luna: Foreldrene mine sier jeg er for liten.

[Bix surprised]
Bix: Men vi er like gamle, og jeg får lov.

[Luna angry]
[Bix confused]
Luna: Jaaa! Det er så urettferdig!
Bix: Får broren din lov til å spille det?

[Luna sad]
Luna: Ja. Men han er 12 år.

[Bix happy]
Bix: Spill på hans maskin. Jeg deler konto...
Bix: ...med søsteren min. Det går kjempfint.

[Luna happy]
Luna: Hæh, kan man det? Jeg må prøve!

# SmileyScales
title(json) = {
  lines: [
    'Luna vil veldig gjerne spille MåneSverdet,',
    'men hun får ikke lov av foreldrene sine',
    '',
    'Synes du hun burde prøve likevel?',
  ],
  fontSize: 33,
}

# Chapter
$ bg = timo-soverom
title = Hjemme hos Luna
subtitle = Rommet til Timo

# Dialogue
[Luna neutral left 220]
[Timo confused right 680]
Timo: Hva gjør du på rommet mitt?

[Luna happy]
Luna: Kan jeg spille MåneSverdet på din maskin?
Timo: Øh, nei!

/* Burde det ikke vært MåneSverdet? */

[Luna angry]
Luna: Åååh, men du spiller det heeele tiden!

[Luna sad]
Luna: Kan ikke jeg få spille bare litt?

[Timo angry]
Timo: Nei! Gå vekk!

[Timo 570 z=2] /* TODO: Parse z */
[Edan surprised right 800]
Edan: Hva skjer?
Timo: Luna vil spille MåneSverdet på min maskin.

[Edan neutral]
Edan: Du er for liten, Luna.
Luna: Men alle andre får jo lov!
Edan: Jeg tror ikke _alle_ i klassen din får lov.
Luna: Noah og Bix får lov til å spille.
Luna: Kanskje jeg kan dele med Timo?
Edan: Jeg bestemmer ikke over dem, bare deg.
Edan: Det er andre i klassen som ikke får lov.
Luna: Hvem da? Alle jeg kjenner spiller.

[Edan confused]
Edan: Jeg husker ikke nå. Jeg spør på...
Edan: ...foreldremøtet senere i kveld.
Timo: Kan dere gå ut av rommet mitt nå?

# Text
paragraphs(json) = [
  'Luna får ikke lov til å spille MåneSverdet fordi hun er for liten. Familier er forskjellige og har ulike regler for aldersgrenser.',
  '',
  'På neste side ser dere eksempler på regler.',
]

# EvaluateStatements
star = true
title.text = Dra reglene dit det passer dere.
title.fontSize = 40

statements(json) = [
  'Jeg får aldri lov til å spille spill jeg er for liten til.',
  'Jeg får lov til å spille spill opp til 12-års grense.',
  'Jeg får lov til å spille det meste, men bare sammen med en voksen.',
  'Jeg får lov til å spille spill som vennene mine ikke får lov til å spille.',
  'Jeg får spille alt jeg vil.',
]
`
