import { useAutoMemo, useAutoEffect, useAutoCallback } from 'hooks.macro'
import { Translate, Norwegian, English } from 'languageContext'
import styled, { keyframes, css } from 'styled-components/macro'
import SceneWrapper from 'common/SceneWrapper'
import BackButton from 'common/BackButton'
import NextButton from 'common/NextButton'
import TypedText from 'common/TypedText'
import repeat from 'repeat'
import React from 'react'

const CHARACTER_WIDTH = 323

const lootImages = [
  'bone-helmet',
  'pink-potion',
  'axe',
  'blue-potion',
  'boots',
  'brass-helmet',
].map(name => `/media/large/interface/loot/${name}.png`)

export default ({
  pages,
  pageIndex,
  options: {
    bg,
    passwordPlaceholder,
    charactersBack,
    whenHasAnswer,
    conversation = [],
    characters = [],
    showName = [],
    forceSlide,
    bubble,
    large = [],
    treasureChest,
    whenIsBroke,
  },
  getPrevAnswerForTemplate,
  storeAnswer,
  answer,
}) => {
  let speakers = conversation.map(({ name }) => name.toLowerCase())
  const prevAnswer = treasureChest ? getPrevAnswerForTemplate('Dialogue') : null
  const money = 10 - 1.99 * ((prevAnswer || answer)?.purchases?.length || 0)
  const page = pages[pageIndex]

  if (answer) {
    if (money < 1.99 && whenIsBroke) {
      conversation = [
        {
          name: whenIsBroke.name,
          text: whenIsBroke.text,
        },
      ]
      speakers = [whenIsBroke.name.toLowerCase()]
      characters = characters.slice()
      const idx = characters.findIndex(x => x.name.toLowerCase() === whenIsBroke.name.toLowerCase())

      if (idx !== -1) {
        characters[idx] = { ...characters[idx], mood: whenIsBroke.mood }
      }
    } else if (whenHasAnswer) {
      conversation = [
        {
          name: whenHasAnswer.name,
          text: whenHasAnswer.text[(answer?.purchases?.length || 1) - 1],
        },
      ]
      speakers = [whenHasAnswer.name.toLowerCase()]
      characters = characters.slice()
      const idx = characters.findIndex(
        x => x.name.toLowerCase() === whenHasAnswer.name.toLowerCase(),
      )

      if (idx !== -1) {
        characters[idx] = { ...characters[idx], mood: whenHasAnswer.mood }
      }
    }
  }

  if (prevAnswer) answer = prevAnswer

  const prevCharacters = useAutoMemo(() => {
    for (let i = pageIndex - 1; i >= 0; i--) {
      const page = pages[i]
      if (page.type === 'Chapter') break
      if (!Array.isArray(page.options.characters)) {
        continue
      }
      const prevCharacters = {}
      for (const { name } of page.options.characters) {
        prevCharacters[name.toLowerCase()] = true
      }
      return prevCharacters
    }
    return {}
  })

  const prevWasBubble = useAutoMemo(() => {
    return Boolean(pages[pageIndex - 1]?.options?.bubble)
  })

  const isFirst = pages[pageIndex - 1]?.type === 'Chapter'

  const hadCharacterInPreviousNonBubbleFrame = name =>
    !prevWasBubble && Boolean(prevCharacters[name.toLowerCase()])

  const sortedCharacters = useAutoMemo(
    characters.slice().sort((a, b) => {
      const az = a.z || 0
      const bz = b.z || 0
      return az - bz
    }),
  )

  const [triedPurchase, setTriedPurchase] = React.useState(false)
  const [isTyping, setIsTyping] = React.useState(true)
  const [instantTyping, setInstantTyping] = React.useState({})

  const onTypeEnd = useAutoCallback(() => {
    setIsTyping(false)
  })

  const onTypeStart = useAutoCallback(() => {
    setIsTyping(true)
  })

  useAutoEffect(() => {
    if (!triedPurchase) return

    const timeout = setTimeout(() => {
      setTriedPurchase(false)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  })

  const isLarge = name => large.includes(name)
  const visibleName = name => showName.includes(name)

  const renderCharacters = (sortedCharacters, isBack) => (
    <Characters>
      {sortedCharacters.map(
        ({ name, id, mood, x, from, phone, down, up, tilt, flip, invisible }) => (
          <CharacterContainer
            key={name}
            x={x}
            down={down}
            up={up}
            from={from}
            animateIn={
              (!bubble || forceSlide) && !isBack && !hadCharacterInPreviousNonBubbleFrame(name)
            }
            disableAllAnimations={bubble}
          >
            <Scaler active={bubble && !isLarge(name)}>
              {bubble && !large.includes(name) && <Bubble />}
              <Flipper flip={flip}>
                <InnerCharacterContainer tilt={tilt}>
                  <Character
                    alt=""
                    name={name}
                    id={id}
                    mood={mood}
                    characterCount={characters.length}
                    bob={speakers.includes(name.toLowerCase())}
                    invisible={invisible}
                  />
                  {phone && (
                    <Phone
                      alt=""
                      src={`/media/large/characters/phone/${name.toLowerCase()}.png`}
                      direction={phone}
                      bob={speakers.includes(name)}
                    />
                  )}
                </InnerCharacterContainer>
              </Flipper>
            </Scaler>
          </CharacterContainer>
        ),
      )}
    </Characters>
  )

  return (
    <>
      {Boolean(charactersBack) && renderCharacters(sortedCharacters, true)}
      <SceneWrapper bg={bg}>
        {treasureChest && (
          <ComputerScreen>
            <Money warn={triedPurchase}>
              <Translate>
                <Norwegian>Dine penger</Norwegian>
                <English>Your money</English>
              </Translate>

              <strong>{money.toFixed(1)} EUR</strong>
            </Money>
            <Chests>
              {repeat(6, i => (
                <div key={i}>
                  <div
                    style={{ position: 'relative' }}
                    onClick={() => {
                      if (money < 1.99) {
                        setTriedPurchase(true)
                        return
                      }
                      storeAnswer(answer => {
                        if (!answer) answer = { purchases: [] }
                        return {
                          ...answer,
                          purchases: Array.from(new Set([...answer.purchases, i])).sort(),
                        }
                      })
                    }}
                  >
                    {answer?.purchases?.includes?.(i) ? (
                      <>
                        <LootCircle />
                        <LootImage src={lootImages[i]} alt="" />
                      </>
                    ) : (
                      <ChestImage src="/media/large/interface/kiste.png" alt="" />
                    )}
                    <PurchaseBar
                      tabIndex={answer?.purchases?.includes?.(i) ? -1 : 0}
                      style={{
                        opacity: answer?.purchases?.includes?.(i) ? 0 : 1,
                      }}
                    >
                      <Price>
                        1.99<PriceSuffix>EUR</PriceSuffix>
                      </Price>
                      <Buy inactive={money < 1.99}>
                        <Translate>
                          <Norwegian>Kjøp</Norwegian>
                          <English>Buy</English>
                        </Translate>
                      </Buy>
                    </PurchaseBar>
                  </div>
                </div>
              ))}
            </Chests>
          </ComputerScreen>
        )}
        {passwordPlaceholder && (
          <Password>
            <Translate>
              <Norwegian>Skriv inn passordet ditt</Norwegian>
              <English>Enter your password</English>
            </Translate>
          </Password>
        )}
        {Boolean(!charactersBack) && renderCharacters(sortedCharacters)}
        {(!bubble || visibleName?.length) && (
          <CharacterNames>
            {sortedCharacters.map(
              ({ name, x, from, invisible }) =>
                Boolean(visibleName(name) || !bubble) && (
                  <CharacterNameWrapper
                    invisible={invisible}
                    key={name}
                    x={x}
                    from={from}
                    animateIn={
                      (!bubble || visibleName(name)) &&
                      !charactersBack &&
                      !hadCharacterInPreviousNonBubbleFrame(name)
                    }
                  >
                    <NameTag />
                    <CharacterNameOuter>
                      <CharacterNameInner>{name}</CharacterNameInner>
                    </CharacterNameOuter>
                  </CharacterNameWrapper>
                ),
            )}
          </CharacterNames>
        )}
        <Conversation animateIn={isFirst}>
          <ConversationBoxImage />
          {conversation.map(({ name, text, music, hideName }, index) => {
            const character = sortedCharacters.find(
              x => x.name.toLowerCase() === name.toLowerCase(),
            )

            music = music || character?.music

            return (
              <ConversationItem key={index}>
                <ConversationName isHidden={hideName}>{name}:</ConversationName>
                <ConversationText>
                  {music && <sup>♪</sup>}
                  <TypedText
                    delay={isFirst && instantTyping[page.id] ? 1500 : 0}
                    instant={instantTyping[page.id]}
                    onEnd={onTypeEnd}
                    onStart={onTypeStart}
                    key={`${money}${characters.map(x => x.name).join(',')}`}
                  >
                    {text}
                  </TypedText>
                  {music && <sup>♫</sup>}
                </ConversationText>
              </ConversationItem>
            )
          })}
        </Conversation>
        <BottomRow>
          <BackButton animateIn={isFirst} />
          <NextButton
            animateIn={isFirst}
            disabled={treasureChest && (!answer || money > 1.99)}
            overrideOnClick={isTyping && (() => setInstantTyping(x => ({ ...x, [page.id]: true })))}
          />
        </BottomRow>
      </SceneWrapper>
    </>
  )
}

const ComputerScreen = styled.div`
  width: calc(735px * var(--scale));
  height: calc(454px * var(--scale));
  overflow: auto;
  position: absolute;
  top: calc(56px * var(--scale));
  left: calc(273px * var(--scale));
  > * {
    flex-basis 33.333%;
  }
  transform: perspective(400px) rotateX(1deg);
`

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
  padding-left: calc(95px * var(--scale));
  @media (max-width: 400px) {
    padding: 0 calc(85px * var(--scale));
    padding-left: calc(75px * var(--scale));
  }
`

const ConversationBoxImage = styled.img.attrs({
  src: '/media/large/interface/conversation-textbox.png',
  alt: '',
})`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(1017px * var(--scale));

  @media (max-width: 400px) {
    width: calc(1055px * var(--scale));
  }
`

const Conversation = styled.div`
  position: absolute;
  bottom: 0;
  padding-bottom: calc(30px * var(--scale));
  left: 50%;
  width: calc(79.5% - 6.4%);
  height: calc(90px * var(--scale));
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-family: Rubik, sans-serif;

  animation: ${keyframes`
    0% {
      transform: translateY(100%) translateX(-50%);
      filter: blur(4px);
    }
    100% {
      transform: translateY(0) translateX(-50%);
      filter: blur(0);
    }
  `} ${p => (p.animateIn ? '0.5s' : '0s')} forwards;

  @media (max-width: 400px) {
    width: 79.5%;
    padding-left: 5px;
  }
`

const ConversationItem = styled.div`
  position: relative;
`

const ConversationName = styled.span`
  font-size: calc(40px * var(--scale));
  margin-right: calc(10px * var(--scale));
  user-select: none;
  ${p =>
    p.isHidden &&
    css`
      opacity: 0;
    `}
`

const ConversationText = styled.span`
  font-size: calc(40px * var(--scale));
`

const Characters = styled.div`
  position: absolute;
  bottom: calc(115px * var(--scale));
  width: 100%;
  pointer-events: none;
`

const fadeInLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-40%);
  }
  20% {
    opacity: 0;
    transform: translateX(-40%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const fadeInLeftName = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-40%) translateX(-50%);
  }
  20% {
    opacity: 0;
    transform: translateX(-40%) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateX(-50%);
  }
`

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(40%);
  }
  20% {
    opacity: 0;
    transform: translateX(40%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const fadeInRightName = keyframes`
  0% {
    opacity: 0;
    transform: translateX(40%) translateX(-50%);
  }
  20% {
    opacity: 0;
    transform: translateX(40%) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateX(-50);
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const CharacterContainer = styled.div`
  height: calc(617px * var(--scale));
  width: calc(323px * var(--scale));
  animation: ${p => {
    switch (p.from) {
      case 'left':
        return fadeInLeft
      case 'right':
        return fadeInRight
      default:
        return fadeIn
    }
  }}
    ${p => (p.animateIn ? '1.5s' : '0s')} forwards;
  position: absolute;
  bottom: 0;
  ${p =>
    !p.disableAllAnimations &&
    css`
      transition: left 1s, margin 1s;
    `}
  left: calc(${p => p.x}px * var(--scale));

  ${p =>
    p.down &&
    css`
      margin-bottom: calc(-30px * var(--scale));
    `}
  ${p =>
    p.up &&
    css`
      margin-bottom: calc(17px * var(--scale));
    `}
`

const InnerCharacterContainer = styled.div`
  transition: transform 1s;
  ${p =>
    p.tilt &&
    css`
      transform: rotate(-3deg) translateX(calc(-25px * var(--scale)));
    `}
`

const Flipper = styled.div`
  ${p =>
    p.flip &&
    css`
      transform: scaleX(-1);
    `}
`

const Scaler = styled.div`
  transform-origin: 50% 100%;
  ${p =>
    p.active &&
    css`
      transform: scale(0.5) translateY(calc(50px * var(--scale)));
      position: relative;
    `}
`

const Bubble = styled.div`
  background-color: white;
  --size: 380px;
  width: calc(var(--size) * var(--scale));
  height: calc(var(--size) * var(--scale));
  border-radius: calc(var(--size) * var(--scale));
  position: absolute;
  border: calc(8px * var(--scale)) solid #707070;
  bottom: calc(70px * var(--scale));
  left: 50%;
  transform: translateX(-50%);
`

const invisibilityAnimation = p =>
  p.invisible &&
  css`
    animation: ${keyframes`
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    `} 0.5s forwards;
  `

const Character = styled.img.attrs(p => ({
  src: `/media/large/characters/${p.id || p.name.toLowerCase()}-${p.mood}.png`,
  alt: '',
}))`
  width: calc(${CHARACTER_WIDTH}px * var(--scale));
  height: calc(617px * var(--scale));
  transform-origin: 50% 100%;

  ${p =>
    p.bob &&
    css`
      animation: ${keyframes`
        0% {
          transform: translateY(0);
        }
        50% {
          transform: rotate(-3deg);
        }
        100% {
          transform: translateY(0);
        }
      `} 2s infinite;
    `}

  ${invisibilityAnimation}
`

const Phone = styled.img`
  width: calc(323px * var(--scale));
  height: calc(617px * var(--scale));
  ${p => (p.direction === 'right' ? 'transform: scaleX(-1)' : '')};
  transform-origin: 50% 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${p =>
    p.bob &&
    css`
      animation: ${keyframes`
        0% {
          transform: translateY(0) ${p.direction === 'right' ? 'scaleX(-1)' : ''};
        }
        50% {
          transform: rotate(-4deg) ${p.direction === 'right' ? 'scaleX(-1)' : ''};
        }
        100% {
          transform: translateY(0) ${p.direction === 'right' ? 'scaleX(-1)' : ''};
        }
      `} 2s infinite;
    `}
`

const ChestImage = styled.img`
  width: calc(233px * var(--scale));
  height: calc(170px * var(--scale));
  display: block;
`

const LootCircle = styled.div`
  position: absolute;
  --size: 170px;
  width: calc(var(--size) * var(--scale));
  height: calc(var(--size) * var(--scale));
  border-radius: calc(var(--size) * var(--scale));
  background-color: #e8e3d7;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const LootImage = styled.img`
  width: calc(233px * var(--scale));
  height: calc(170px * var(--scale));
  transform: translateY(calc(10px * var(--scale)));
  display: block;
`

const PurchaseBar = styled.button.attrs({
  type: 'button',
})`
  border: calc(2px * var(--scale)) solid #48f7cd;
  background-color: white;
  width: calc(158px * var(--scale));
  display: flex;
  margin: 0 auto;
  border-radius: 1000px;
  text-align: center;
  position: relative;
  bottom: calc(15px * var(--scale));

  transition: filter 0.4s, transform 0.4s;
  transform: translate3d(0, 0, 0);

  :focus {
    outline: none;
    transform: translate3d(0, 0, 0) scale(1.1);
    filter: var(--focus-filter);
  }
`

const Buy = styled.div`
  border: 0;
  display: flex;
  width: 45%;
  border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  background-color: #48f7cd;
  font-size: calc(20px * var(--scale));
  font-family: inherit;
  cursor: pointer;
  align-self: stretch;
  justify-content: center;
  align-items: center;

  ${p =>
    p.inactive &&
    css`
      background-color: #ddd;
    `}
`

const Price = styled.div`
  font-size: calc(20px * var(--scale));
  width: 55%;
  margin: calc(7px * var(--scale)) 0;
`

const PriceSuffix = styled.span`
  font-size: calc(11px * var(--scale));
`

const Money = styled.div`
  position: absolute;
  top: calc(10px * var(--scale));
  right: calc(20px * var(--scale));
  font-size: calc(20px * var(--scale));

  ${p =>
    p.warn &&
    css`
      animation: 0.5s ${keyframes`
      0% {
        transform: rotate(0);
      }
      20% {
        transform: rotate(2deg);
        color: #fe663c;
      }
      40% {
        transform: rotate(-2deg);
        color: #fe663c;
      }
      60% {
        transform: rotate(2deg);
        color: #fe663c;
      }
      80% {
        transform: rotate(-2deg);
        color: #fe663c;
      }
      100% {
        transform: rotate(0)
      }
    `};
    `};
`

const Chests = styled.div`
  margin-top: calc(20px * var(--scale));
  display: flex;
  flex-wrap: wrap;
`

const CharacterNames = styled.div`
  position: absolute;
  outline: 1px solid red;
  bottom: 23.5%;
`

const NameTag = styled.img.attrs({
  src: '/media/large/interface/nametag-storyline.png',
  alt: '',
})`
  width: var(--width);
  height: var(--height);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`

const CharacterNameOuter = styled.div`
  position: absolute;
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(7px * var(--scale));
  display: flex;
  align-items: flex-start;
  height: calc(var(--height) - 40px * var(--scale));
`

const CharacterNameInner = styled.div`
  margin: auto;
  font-family: 'Walter turncoat', sans-serif;
  font-size: calc(30px * var(--scale));
  text-align: center;
`

const CharacterNameWrapper = styled.div`
  position: absolute;
  --width: calc(207px * var(--scale));
  --height: calc(74px * var(--scale));
  width: var(--width);
  height: var(--height);
  left: calc(${p => p.x + CHARACTER_WIDTH / 2}px * var(--scale));
  transform: translateX(-50%);
  transition: left 1s;

  animation: ${p => {
      switch (p.from) {
        case 'left':
          return fadeInLeftName
        case 'right':
          return fadeInRightName
        default:
          return fadeIn
      }
    }}
    ${p => (p.animateIn ? '1.5s' : '0s')} forwards;

  ${invisibilityAnimation}
`
const Password = styled.div`
  position: absolute;
  top: calc(330px * var(--scale));
  left: calc(495px * var(--scale));
  font-size: calc(24px * var(--scale));
  border: calc(2px * var(--scale)) solid #9a9a9a;
  padding: calc(5px * var(--scale));
  width: calc(400px * var(--scale));
  height: calc(44px * var(--scale));
  text-align: left;
  border-radius: calc(4px * var(--scale));
  color: #aaa;

  ${p =>
    p.filled &&
    css`
      border-color: #c30000;
      color: #c30000;
    `}
`
