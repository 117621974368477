import parse from './parse.js'
import lex from './lex.js'

export default function transformConfig(prefix, source) {
  if (!prefix || !source) throw Error('prefix and source required')

  source = stripComments(source)

  const entries = parse(lex(source, true))

  for (let i = 0; i < entries.length; i++) {
    entries[i].id = `${prefix}_${i + 1}`
  }

  return entries
}

export function stripComments(string) {
  return string.replace(/\/\*([^*]|[\r\n]|(\*+([^*/]|[\r\n])))*\*+\//g, '')
}

export { lex, parse }
