import LanguageButton from '../common/LanguageButton'
import styled, { css } from 'styled-components/macro'
import { currentLanguage } from 'languageContext'
import SpaceBackground from './SpaceBackground'
import { useHistory } from 'react-router-dom'

import StoreContext from 'Store'
import React from 'react'
import Logo from './Logo'

// ------ components for importing, viewing and styling images ------ //
const Img = styled.img`
  width: 100%;
`

// The confusing-looking ternaries let you send pure numbers. They are converted to pixels.
// If you send strings, you only get the string you sent. For percents and so on.
const ImgContainer = styled.button.attrs(p => ({
  h:
    (typeof p.size === 'number' ? p.size + 'px' : p.size) ||
    (typeof p.h === 'number' ? p.h + 'px' : p.h),
  w:
    (typeof p.size === 'number' ? p.size + 'px' : p.size) ||
    (typeof p.w === 'number' ? p.w + 'px' : p.w),
  x: typeof p.x === 'number' ? p.x + 'px' : p.x,
  y: typeof p.y === 'number' ? p.y + 'px' : p.y,
}))`
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: block;
  height: ${p => p.h};
  width: ${p => p.w};
  ${p =>
    p.center &&
    css`
      transform: translate3d(-50%, 0, 0);
    `}
`

// ------ components for text and interaction ----- //
const textsNO = [
  'I fremtiden bor Mennesker, Neptunianere og Oorter sammen i en Stjernekoloni langt ute i solsystemet.',
  'De som bor der går på skole, jobber og har venner, akkurat som deg og meg.',
  'Bli med en av innbyggerne i en uke og ta spennende valg om teknologi sammen. Velg en av flere karakterer først, og spill flere ganger for å få historien til alle.',
]

const textsEN = [
  'In the future, Humans, Neptunians and Oorts live together in a star colony in the far reaches of the solar system.',
  'Those who live there go to school, work and have friends, just like you and me.',
  'Join one of the inhabitants for a week of technology decision-making. Choose one character to start with, then play the game several times to get everyone’s story.',
]

const TextOuter = styled.div`
  background-image: url(${p => p.backgroundImg});
  transform-origin: 50% 50%;
  transform: translate3d(-50%, -50%, 0) scale(var(--scale));
  background-size: contain;
  position: absolute;
  height: 228px;
  width: 554px;
  left: 50%;
  top: 50%;

  @media (max-width: 1000px) {
    transform: translate3d(-50%, -50%, 0) scale(var(--scale)) scale(1.4);
  }

  @media (max-width: 700px) {
    transform: translate3d(-50%, -50%, 0) scale(var(--scale)) scale(2);
  }
`

const TextInner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  color: #006279;
  font: Medium 23px/28px Rubik;
`

const TextButtons = styled.div`
  display: flex;
  width: 100%;
  align-self: flex-end;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  padding: 0 25px;
`
const StyledButton = styled(ImgContainer)`
  cursor: pointer;
`
const Button = ({ src, ...p }) => (
  <StyledButton {...p}>
    <Img src={src} />
  </StyledButton>
)

const Text = styled.div`
  position: absolute;
  top: 50%;
  padding: 0 25px;
  left: 25px;
  font-size: 23px;
  font-weight: 500;
  transform: translate3d(0, -50%, 0);
  right: 25px;
`

export const TextBox = ({ backgroundImg, children }) => (
  <TextOuter backgroundImg={backgroundImg}>
    <TextInner>{children}</TextInner>
  </TextOuter>
)

const SkipButton = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  --local-scale: calc(var(--slight-scale) * 1.2);
  text-transform: lowercase;
  font-family: 'Walter Turncoat', sans-serif;
  background-image: url(${p => p.backgroundImg});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: calc(15px * var(--scale));
  bottom: calc(17px * var(--scale));
  height: calc(40px * var(--local-scale));
  width: calc(117px * var(--local-scale));
  cursor: pointer;
  z-index: 2;
  color: white;
  padding-bottom: calc(6px * var(--local-scale));
  padding-right: calc(2px * var(--local-scale));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(19px * var(--local-scale));
`

// ------ exported component ----- //
const Intro = ({ children }) => {
  const { interfacePNG, start } = React.useContext(StoreContext)
  const [textIndex, setTextIndex] = React.useState(0)
  const history = useHistory()
  const texts = currentLanguage === 'NO' ? textsNO : textsEN

  return (
    <SpaceBackground>
      <LanguageButton />
      <Logo />
      <TextBox backgroundImg={interfacePNG('character-select-textbox')}>
        <Text>{texts[textIndex]}</Text>
        <TextButtons>
          <Button
            style={{ opacity: textIndex }}
            size={18}
            onClick={() => {
              if (textIndex !== 0) {
                setTextIndex(textIndex - 1)
              }
            }}
            src={interfacePNG('back-button')}
          />
          <Button
            size={28}
            onClick={() => {
              if (textIndex < texts.length - 1) {
                setTextIndex(textIndex + 1)
              } else {
                start()
                history.push({ pathname: '/velg-karakter', search: history.location.search })
              }
            }}
            src={interfacePNG('next-button')}
          />
        </TextButtons>
      </TextBox>
      <SkipButton
        backgroundImg={interfacePNG('start-button')}
        onClick={() => {
          start()
          history.push({ pathname: '/velg-karakter', search: history.location.search })
        }}
      >
        Skip intro
      </SkipButton>
    </SpaceBackground>
  )
}

export default Intro
