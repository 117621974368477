export default `
# Chapter
title = Friday 
subtitle = At school
$ bg = barn-klasserom

# Dialogue
[Luna happy left 240]
[Bix neutral right 680]
$ bg = barn-klasserom

Luna: Bix, let’s go to the fair tomorrow!

[Bix happy]

Bix: Yes! I’ll just have to ask first.
Luna: It’s going to be such fun!

# Chapter
title.fontSize = 40
title.text = At Luna’s house
$ bg = luna-soverom

# Dialogue
$ bg = luna-soverom

[Luna happy 450]
Luna: I’ll check online when it opens.

[Luna confused]
Luna: Hm, what’s the password again?

# Dialogue
$ bg = luna-laptop-en
passwordPlaceholder = Enter your password
Luna: I made up a great, tricky password.

# DialogueChoice
$ bg = luna-laptop-en
title.text =
choices(list) = Luna100!PancakesAreBest!, Luna’s_Super difficult_Password, PrettyDifficultP@assword!, no0ne-Gue33-thi3-pa55word

# Dialogue
$ bg = luna-soverom
[Luna surprised left 450]

Luna: Oh no! What should I do now?

# Text
paragraphs(json) = [
  "Luna can’t remember her password!\\nSome people share their password with others to make it easier to remember.",
  "",
  "But then others can perhaps see things you don’t want to share."
]

# Choice
star = true
title.text = Is sharing passwords a good idea?
title.fontSize = 45
choices(json) = [
  { text: 'Never'},
  { text: 'Yes, with family members' },
  { text: 'Yes, with friends', wrong: true },
  { text: 'Yes, for some things but not for everything' },
  { text: 'Yes, with parents until you are old enough to remember' },
  { text: 'Only if everybody shares theirs', wrong: true },
]
`
