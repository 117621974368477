export const leftEyeBrowStages = [
  // STEP 1
  {
    fill: '#662f17',
    d:
      'm42.57,44.72a1,1,0,0,1-.66-.25L31.23,34.89a1,1,0,1,1,1.32-1.49L43.34,43a1,1,0,0,1-.66,1.75Z',
  },
  // STEP 2
  {
    fill: '#662f17',
    d:
      'M43.43,40.38A1,1,0,0,0,43.34,39L32.55,29.4a1,1,0,1,0-1.32,1.49L42,40.47a1,1,0,0,0,.66.25A1,1,0,0,0,43.43,40.38Z',
  },
  // STEP 3
  {
    fill: '#662f17',
    d:
      'M44.3,36.32A1,1,0,0,0,43.8,35L30.66,29.05a1,1,0,0,0-.83,1.83L43,36.81a.85.85,0,0,0,.41.09A1,1,0,0,0,44.3,36.32Z',
  },
  // STEP 4
  {
    fill: '#662f17',
    d:
      'm44.8,33.94a1,1,0,0,0-.87-1.12L29.61,31.06a1,1,0,0,0-.24,2l14.31,1.77h.13A1,1,0,0,0,44.8,33.94Z',
  },
  // STEP 5
  {
    fill: '#662f17',
    d: 'm23.48,33.05L38,33a1,1,0,0,0,0-2h0l-14.52.05a1,1,0,0,0,0,2Z',
  },
  // STEP 6
  {
    fill: '#662f17',
    d:
      'M23.68,37.56a1.08,1.08,0,0,1-.43-.1,1,1,0,0,1-.47-1.34,11.86,11.86,0,0,1,10.66-6.87,11.5,11.5,0,0,1,2.42.26,1,1,0,1,1-.42,1.95,9.5,9.5,0,0,0-2-.21A9.89,9.89,0,0,0,24.58,37,1,1,0,0,1,23.68,37.56Z',
  },
  // STEP 7
  {
    fill: '#662f17',
    d:
      'M23.68,35.56a1.08,1.08,0,0,1-.43-.1,1,1,0,0,1-.47-1.34,11.86,11.86,0,0,1,10.66-6.87,11.5,11.5,0,0,1,2.42.26,1,1,0,1,1-.42,1.95,9.5,9.5,0,0,0-2-.21A9.89,9.89,0,0,0,24.58,35,1,1,0,0,1,23.68,35.56Z',
  },
  // STEP 8
  {
    fill: '#662f17',
    d:
      'M23.68,32.56a1.08,1.08,0,0,1-.43-.1,1,1,0,0,1-.47-1.34,11.86,11.86,0,0,1,10.66-6.87,11.5,11.5,0,0,1,2.42.26,1,1,0,1,1-.42,1.95,9.5,9.5,0,0,0-2-.21A9.89,9.89,0,0,0,24.58,32,1,1,0,0,1,23.68,32.56Z',
  },
  // STEP 9
  {
    fill: '#662f17',
    d:
      'M23.68,32.56a1.08,1.08,0,0,1-.43-.1,1,1,0,0,1-.47-1.34,11.86,11.86,0,0,1,10.66-6.87,11.5,11.5,0,0,1,2.42.26,1,1,0,1,1-.42,1.95,9.5,9.5,0,0,0-2-.21A9.89,9.89,0,0,0,24.58,32,1,1,0,0,1,23.68,32.56Z',
  },
  // STEP 10
  {
    fill: '#662f17',
    d:
      'M23.68,32.56a1.08,1.08,0,0,1-.43-.1,1,1,0,0,1-.47-1.34,11.86,11.86,0,0,1,10.66-6.87,11.5,11.5,0,0,1,2.42.26,1,1,0,1,1-.42,1.95,9.5,9.5,0,0,0-2-.21A9.89,9.89,0,0,0,24.58,32,1,1,0,0,1,23.68,32.56Z',
  },
  // STEP 11
  {
    fill: '#662f17',
    d:
      'M24.35,27.7a1,1,0,0,1-.52-.15,1,1,0,0,1-.34-1.37,11.89,11.89,0,0,1,11.25-5.87,10.69,10.69,0,0,1,2.38.48,1,1,0,1,1-.6,1.9,9.29,9.29,0,0,0-2-.39,9.87,9.87,0,0,0-9.34,4.91A1,1,0,0,1,24.35,27.7Z',
  },
]

export const rightEyeBrowStages = [
  // STEP 1
  {
    fill: '#662f17',
    d:
      'M56.57,44.72a1,1,0,0,1-.75-.34A1,1,0,0,1,55.91,43L66.7,33.4A1,1,0,1,1,68,34.89L57.23,44.47A1,1,0,0,1,56.57,44.72Z',
  },
  // STEP 2
  {
    fill: '#662f17',
    d:
      'M56.57,40.72a1,1,0,0,1-.75-.34A1,1,0,0,1,55.91,39L66.7,29.4A1,1,0,1,1,68,30.89L57.23,40.47A1,1,0,0,1,56.57,40.72Z',
  },
  // STEP 3
  {
    fill: '#662f17',
    d:
      'M55.39,36.9A1,1,0,0,1,55,35l13.15-5.94A1,1,0,0,1,69,30.88L55.8,36.81A.85.85,0,0,1,55.39,36.9Z',
  },
  // STEP 4
  {
    fill: '#662f17',
    d: 'M54.8,34.81a1,1,0,0,1-.12-2L69,31.06a1,1,0,1,1,.25,2L54.93,34.81Z',
  },
  // STEP 5
  {
    fill: '#662f17',
    d:
      'M75.48,33.35h0L61,33a1,1,0,0,1-1-1,1,1,0,0,1,1-1l14.49.35a1,1,0,0,1,1,1A1,1,0,0,1,75.48,33.35Z',
  },
  // STEP 6
  {
    fill: '#662f17',
    d:
      'm76.75,37.47 a1,1,0,0,0,.47-1.34,11.86,11.86,0,0,0-10.66-6.87,11.5,11.5,0,0,0-2.42.26,1,1,0,1,0,.42,1.95,9.5,9.5,0,0,1,2-.21A9.89,9.89,0,0,1,75.42,37a1,1,0,0,0,.9.57A1.08,1.08,0,0,0,76.75,37.46Z',
  },
  // STEP 7
  {
    fill: '#662f17',
    d:
      'm76.75,35.55a1,1,0,0,0,.47-1.34,11.86,11.86,0,0,0-10.66-6.87,11.5,11.5,0,0,0-2.42.26,1,1,0,1,0,.42,1.95,9.5,9.5,0,0,1,2-.21A9.89,9.89,0,0,1,75.42,35a1,1,0,0,0,.9.57A1.08,1.08,0,0,0,76.75,35.46Z',
  },
  // STEP 8
  {
    fill: '#662f17',
    d:
      'm77,32.46a1,1,0,0,0,.47-1.34,11.86,11.86,0,0,0-10.66-6.87,11.5,11.5,0,0,0-2.42.26,1,1,0,1,0,.42,1.95,9.5,9.5,0,0,1,2-.21A9.89,9.89,0,0,1,75.42,32a1,1,0,0,0,.9.57A1.08,1.08,0,0,0,76.75,32.46Z',
  },
  // STEP 9
  {
    fill: '#662f17',
    d:
      'm77,32.46a1,1,0,0,0,.47-1.34,11.86,11.86,0,0,0-10.66-6.87,11.5,11.5,0,0,0-2.42.26,1,1,0,1,0,.42,1.95,9.5,9.5,0,0,1,2-.21A9.89,9.89,0,0,1,75.42,32a1,1,0,0,0,.9.57A1.08,1.08,0,0,0,76.75,32.46Z',
  },

  // STEP 10
  {
    fill: '#662f17',
    d:
      'm77,32.46a1,1,0,0,0,.47-1.34,11.86,11.86,0,0,0-10.66-6.87,11.5,11.5,0,0,0-2.42.26,1,1,0,1,0,.42,1.95,9.5,9.5,0,0,1,2-.21A9.89,9.89,0,0,1,75.42,32a1,1,0,0,0,.9.57A1.08,1.08,0,0,0,76.75,32.46Z',
  },
  // STEP 11
  {
    fill: '#662f17',
    d:
      'm75.43,27.55a1,1,0,0,0,.34-1.37,11.86,11.86,0,0,0-11.24-5.87,10.67,10.67,0,0,0-2.39.48,1,1,0,1,0,.6,1.9,9.17,9.17,0,0,1,2-.39,9.86,9.86,0,0,1,9.34,4.91,1,1,0,0,0,.86.49A1,1,0,0,0,75.23,27.55Z',
  },
]

export const leftEyeStages = [
  // STEP 1
  {
    fill: '#662f17',
    d: 'M28.18,41.1c1.83-3.41,3.94,0,7.35,1.88s6.82,1.33,5,4.74A7,7,0,0,1,28.18,41.1Z',
  },
  // STEP 2
  {
    fill: '#662f17',
    d: 'M32,51a7,7,0,0,0,8.61-4.9c1-3.72-2.19-2.48-5.92-3.51s-6.55-3.93-7.58-.2A7,7,0,0,0,32,51Z',
  },
  // STEP 3
  {
    fill: '#662f17',
    d: `M 26.19, 44.18
    a 7,7 0 1,0 14, 0
    a 7,7 0 1,0 -14, 0`,
  },
  // STEP 4
  {
    fill: '#662f17',
    d: `M 26.19, 44.18
    a 7,7 0 1,0 14, 0
    a 7,7 0 1,0 -14, 0`,
  },
  // STEP 5
  {
    fill: '#662f17',
    d: `M 26.19, 44.18
    a 7,7 0 1,0 14, 0
    a 7,7 0 1,0 -14, 0`,
  },
  // STEP 6
  {
    fill: '#662f17',
    d: `M 26.19, 44.18
    a 7,7 0 1,0 14, 0
    a 7,7 0 1,0 -14, 0`,
  },
  // STEP 7
  {
    fill: '#662f17',
    d: 'M40.19,44.18C40.19,48.05,36.87,49,33,49s-6.81-1-6.81-4.82a7,7,0,0,1,14,0Z',
  },
  // STEP 8
  {
    fill: '#662f17',
    d: 'M40.19,40.18C40.19,44.05,36.87,42,33,42s-6.81,2-6.81-1.82a7,7,0,0,1,14,0Z',
  },
  // STEP 9
  {
    fill: '#662f17',
    d: 'M40.19,40.18C40.19,44.05,36.87,42,33,42s-6.81,2-6.81-1.82a7,7,0,0,1,14,0Z',
  },
  // STEP 10
  {
    fill: '#662f17',
    d: 'M40.19,40.18C40.19,44.05,36.87,42,33,42s-6.81,2-6.81-1.82a7,7,0,0,1,14,0Z',
  },
  // STEP 11
  {
    fill: '#e63235',
    d:
      'M45.32,37.34c0,.89,0,9.92-13.66,17.29h0C18,47.26,18,38.23,18,37.34v-.07c0-4.08,2.95-7.82,7.39-7.82a6.51,6.51,0,0,1,6.27,4.91,6.51,6.51,0,0,1,6.27-4.91c4.44,0,7.39,3.74,7.39,7.82Z',
  },
]

export const rightEyeStages = [
  // STEP 1
  {
    fill: '#662f17',
    d: 'M59.48,48.94c-2.38-3,1.5-3.48,4.55-5.86s4.1-5.81,6.48-2.76a7,7,0,0,1-11,8.62Z',
  },
  // STEP 2
  {
    fill: '#662f17',
    d: 'M59.14,45.28c-.61-3.82,3-2.32,6.81-2.94s6.4-3.11,7,.71a7,7,0,1,1-13.82,2.23Z',
  },
  // STEP 3
  {
    fill: '#662f17',
    d: `M 59.36, 44.18
    a 7,7 0 1,0 14,0
    a 7,7 0 1,0 -14,0`,
  },
  // STEP 4
  {
    fill: '#662f17',
    d: `M 59.36, 44.18
    a 7,7 0 1,0 14,0
    a 7,7 0 1,0 -14,0`,
  },
  // STEP 5
  {
    fill: '#662f17',
    d: `M 59.36, 44.18
    a 7,7 0 1,0 14,0
    a 7,7 0 1,0 -14,0`,
  },
  // STEP 6
  {
    fill: '#662f17',
    d: `M 59.36, 44.18
    a 7,7 0 1,0 14,0
    a 7,7 0 1,0 -14,0`,
  },
  // STEP 7
  {
    fill: '#662f17',
    d:
      'm66.36,37.18a7,7,0,0,0-7,7C59.36,48.05,62.13,49,66,49s7.36-1,7.36-4.82A7,7,0,0,0,66.36,37.18Z',
  },
  // STEP 8
  {
    fill: '#662f17',
    d:
      'm66.36,33.18a7,7,0,0,0-7,7C59.36,44.05,62.13,42,66,42s7.36,2,7.36-1.82A7,7,0,0,0,66.36,33.18Z',
  },
  // STEP 9
  {
    fill: '#662f17',
    d:
      'm66.36,33.18a7,7,0,0,0-7,7C59.36,44.05,62.13,42,66,42s7.36,2,7.36-1.82A7,7,0,0,0,66.36,33.18Z',
  },
  // STEP 10
  {
    fill: '#662f17',
    d:
      'm66.36,33.18a7,7,0,0,0-7,7C59.36,44.05,62.13,42,66,42s7.36,2,7.36-1.82A7,7,0,0,0,66.36,33.18Z',
  },
  // STEP 11
  {
    fill: '#e63235',
    d:
      'm82.00,37.34v-.07c0-4.08-3-7.82-7.39-7.82a6.51,6.51,0,0,0-6.27,4.91,6.51,6.51,0,0,0-6.27-4.91c-4.44,0-7.39,3.74-7.39,7.82v.07c0,.89,0,9.92,13.66,17.29h0C82,47.26,82,38.23,82,37.34Z',
  },
]
export const mouthOuterStages = [
  // STEP 1
  {
    fill: '#662f17',
    d: 'M70,77c-.84-9.85-8-18.17-20-18.17-11.49,0-20,8.32-20,18.17Z',
  },
  // STEP 2
  {
    fill: '#662f17',
    d: 'M70,77c-.84-9.85-8-18.17-20-18.17-11.49,0-20,8.32-20,18.17Z',
  },
  // STEP 3
  {
    fill: '#662f17',
    d:
      'M33.65,72.74a1.49,1.49,0,0,1-1.36-2.12c3.55-7.75,10.38-12.38,18.26-12.38,7.64,0,14.24,4.5,18.08,12.34a1.5,1.5,0,1,1-2.69,1.32C62.62,65.13,57,61.24,50.55,61.24c-6.78,0-12.44,3.87-15.54,10.63A1.49,1.49,0,0,1,33.65,72.74Z',
  },
  // STEP 4
  {
    fill: '#662f17',
    d:
      'M66.82,72.75a1.48,1.48,0,0,1-1-.42c-5-4.79-9.58-6.83-15.38-6.83-6.92,0-13.45,4.07-16.15,6.8a1.5,1.5,0,1,1-2.13-2.11C35.9,66.37,43.39,62.5,50.4,62.5c6.56,0,11.94,2.36,17.46,7.67a1.5,1.5,0,0,1-1,2.58Z',
  },
  // STEP 5
  {
    fill: '#662f17',
    d: 'M69,68H31a1.5,1.5,0,0,1,0-3H69a1.5,1.5,0,0,1,0,3Z',
  },
  // STEP 6
  {
    fill: '#662f17',
    d:
      'M50.5,73c-7,0-14.5-3.87-18.28-7.7a1.5,1.5,0,0,1,2.13-2.11C37.06,65.93,43.59,70,50.5,70c5.8,0,10.4-2,15.38-6.84A1.5,1.5,0,0,1,68,65.33C62.45,70.63,57.06,73,50.5,73Z',
  },
  // STEP 7
  {
    fill: '#662f17',
    d:
      'M50,74.25c-7.65,0-14.24-4.5-18.09-12.34a1.5,1.5,0,1,1,2.69-1.32C38,67.36,43.57,71.25,50,71.25c6.77,0,12.43-3.87,15.53-10.63a1.5,1.5,0,0,1,2.73,1.25C64.73,69.62,57.91,74.25,50,74.25Z',
  },
  // STEP 9
  {
    fill: '#662f17',
    d: 'M72.88,58.43H26.81c1,11.27,9.41,20.79,23.19,20.79,13.15,0,23.19-9.52,23.19-20.79Z',
  },

  // STEP 10
  {
    fill: '#662f17',
    d:
      'M67.5,58.43l-.32,1.17a3,3,0,0,1-2.89,2.2H35.71a3,3,0,0,1-2.89-2.2l-.32-1.17H26.81A22.17,22.17,0,0,0,37.44,76.05h0a16.16,16.16,0,0,1,25.13,0h0A22.17,22.17,0,0,0,73.19,58.43Z',
  },
  // STEP 11
  {
    fill: '#662f17',
    d:
      'M67.5,58.43l-.32,1.17a3,3,0,0,1-2.89,2.2H35.71a3,3,0,0,1-2.89-2.2l-.32-1.17H26.81A22.17,22.17,0,0,0,37.44,76.05h0a16.16,16.16,0,0,1,25.13,0h0A22.17,22.17,0,0,0,73.19,58.43Z',
  },
]
export const mouthInnerStages = [
  // STEP 1
  {
    fill: '#eb4658',
    d: 'M36.34,77H63.88a16.51,16.51,0,0,0-27.54,0Z',
  },
  // STEP 2
  {
    fill: 'transparent',
    d: 'M36.34,77H63.88a16.51,16.51,0,0,0-27.54,0Z',
  },
  // STEP 3
  {
    fill: 'transparent',
    d:
      'M33.65,72.74a1.49,1.49,0,0,1-1.36-2.12c3.55-7.75,10.38-12.38,18.26-12.38,7.64,0,14.24,4.5,18.08,12.34a1.5,1.5,0,1,1-2.69,1.32C62.62,65.13,57,61.24,50.55,61.24c-6.78,0-12.44,3.87-15.54,10.63A1.49,1.49,0,0,1,33.65,72.74Z',
  },
  // STEP 4
  {
    fill: 'transparent',
    d:
      'M33.65,72.74a1.49,1.49,0,0,1-1.36-2.12c3.55-7.75,10.38-12.38,18.26-12.38,7.64,0,14.24,4.5,18.08,12.34a1.5,1.5,0,1,1-2.69,1.32C62.62,65.13,57,61.24,50.55,61.24c-6.78,0-12.44,3.87-15.54,10.63A1.49,1.49,0,0,1,33.65,72.74Z',
  },
  // STEP 5
  {
    fill: 'transparent',
    d:
      'M33.65,72.74a1.49,1.49,0,0,1-1.36-2.12c3.55-7.75,10.38-12.38,18.26-12.38,7.64,0,14.24,4.5,18.08,12.34a1.5,1.5,0,1,1-2.69,1.32C62.62,65.13,57,61.24,50.55,61.24c-6.78,0-12.44,3.87-15.54,10.63A1.49,1.49,0,0,1,33.65,72.74Z',
  },
  // STEP 6
  {
    fill: 'transparent',
    d:
      'M33.65,72.74a1.49,1.49,0,0,1-1.36-2.12c3.55-7.75,10.38-12.38,18.26-12.38,7.64,0,14.24,4.5,18.08,12.34a1.5,1.5,0,1,1-2.69,1.32C62.62,65.13,57,61.24,50.55,61.24c-6.78,0-12.44,3.87-15.54,10.63A1.49,1.49,0,0,1,33.65,72.74Z',
  },
  // STEP 7
  {
    fill: 'transparent',
    d:
      'M33.65,72.74a1.49,1.49,0,0,1-1.36-2.12c3.55-7.75,10.38-12.38,18.26-12.38,7.64,0,14.24,4.5,18.08,12.34a1.5,1.5,0,1,1-2.69,1.32C62.62,65.13,57,61.24,50.55,61.24c-6.78,0-12.44,3.87-15.54,10.63A1.49,1.49,0,0,1,33.65,72.74Z',
  },
  // STEP 9
  {
    fill: '#ed5565',
    d:
      'M53.22,79l.32,0c.5-.06,1-.14,1.49-.23l.24,0c.43-.09.85-.18,1.26-.29l.31-.08a26,26,0,0,0,2.83-.93l.2-.09c.42-.17.84-.35,1.24-.55l.11,0c.46-.22.91-.45,1.34-.7a16.15,16.15,0,0,0-25.12,0h0a22,22,0,0,0,3.9,1.79l.6.2c.37.12.74.23,1.12.33.56.15,1.14.29,1.73.4.23,0,.46.08.69.11.51.09,1,.16,1.55.21l.7.07c.74,0,1.49.1,2.27.1.53,0,1,0,1.56,0l.43,0Q52.61,79.11,53.22,79Z',
  },

  // STEP 10
  {
    fill: '#ed5565',
    d:
      'M53.22,79l.32,0c.5-.06,1-.14,1.49-.23l.24,0c.43-.09.85-.18,1.26-.29l.31-.08a26,26,0,0,0,2.83-.93l.2-.09c.42-.17.84-.35,1.24-.55l.11,0c.46-.22.91-.45,1.34-.7a16.15,16.15,0,0,0-25.12,0h0a22,22,0,0,0,3.9,1.79l.6.2c.37.12.74.23,1.12.33.56.15,1.14.29,1.73.4.23,0,.46.08.69.11.51.09,1,.16,1.55.21l.7.07c.74,0,1.49.1,2.27.1.53,0,1,0,1.56,0l.43,0Q52.61,79.11,53.22,79Z',
  },
  // STEP 11
  {
    fill: '#ed5565',
    d:
      'M53.22,79l.32,0c.5-.06,1-.14,1.49-.23l.24,0c.43-.09.85-.18,1.26-.29l.31-.08a26,26,0,0,0,2.83-.93l.2-.09c.42-.17.84-.35,1.24-.55l.11,0c.46-.22.91-.45,1.34-.7a16.15,16.15,0,0,0-25.12,0h0a22,22,0,0,0,3.9,1.79l.6.2c.37.12.74.23,1.12.33.56.15,1.14.29,1.73.4.23,0,.46.08.69.11.51.09,1,.16,1.55.21l.7.07c.74,0,1.49.1,2.27.1.53,0,1,0,1.56,0l.43,0Q52.61,79.11,53.22,79Z',
  },
]

export const teethStages = [
  // STEP 1
  {
    fill: 'transparent',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 2
  {
    fill: 'transparent',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 3
  {
    fill: 'transparent',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 4
  {
    fill: 'transparent',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 5
  {
    fill: 'transparent',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 6
  {
    fill: 'transparent',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 7
  {
    fill: 'transparent',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 8
  {
    fill: 'transparent',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 9
  {
    fill: '#fff',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 10
  {
    fill: '#fff',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
  // STEP 11
  {
    fill: '#fff',
    d: 'M35.77,61.8H64.23a3.06,3.06,0,0,0,3-2.2l.33-1.17h-35l.33,1.17A3.06,3.06,0,0,0,35.77,61.8Z',
  },
]
