/**
 * repeat(10, i => (
 *   <div key={i}>Hello world</div>
 * )
 */
export default (n, fn) => {
  const result = []
  for (let i = 0; i < n; i++) {
    result.push(fn(i))
  }
  return result
}
