import styled from 'styled-components/macro'
import React from 'react'

export default ({ title, shrinkWhenSmall }) => {
  const { lines, text, fontSize } = title

  return (
    <Title fontSize={fontSize} shrinkWhenSmall>
      {lines ? lines.join('\n') : text}
    </Title>
  )
}

const Title = styled.h2`
  font-size: calc(${p => p.fontSize}px * var(--scale));
  text-align: center;
  font-weight: normal;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(50px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
  white-space: pre-line;

  @media (max-width: 800px) {
    font-size: calc(${p => p.fontSize * 0.8}px * var(--scale));
  }
`
