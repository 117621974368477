export default `
# Chapter
$ bg = noah-soverom
title = Torsdag
subtitle = Hjemme hos Noah

# Dialogue
[Noah 450 happy flip]
Noah: Skal logge på MåneSverdet og spille.

bubble = true
$ bg = noah-laptop

[Noah 400]
[Limal happy right 600 flip]
Limal: Hei, Noah!

[Noah surprised]
Noah: Åh, sverdet ditt er så kult!

[Limal happy]
Limal: Jeg fikk det i en lootboks jeg kjøpte!
Limal: Det er som en skattekiste...
Limal: ...men du vet aldri hva du får i den.

[Noah happy]
Noah: Kult! Jeg vil også kjøpe.

treasureChest = true
$ bg(json) = { image: 'generisk-laptop', contain: true }
whenHasAnswer(json) = {
  name: 'Noah',
  mood: 'confused',
  text: [
    'Jeg fikk det ikke. jeg prøver igjen.',
    'Jeg fikk det ikke nå heller! Jeg åpner en til.',
    'Næh! Ikke denne gangen heller! En til.',
    'Nå er pengene snart slutt. Nå MÅ jeg få det!',
  ],
}
whenIsBroke(json) = {
  name: 'Noah',
  mood: 'surprised',
  text: 'Jeg fikk ikke sverdet!',
}

Limal: Klikk på den du vil kjøpe! Så kan du åpne.

[Limal confused]
[Noah surprised]
Limal: Jeg fikk det med én gang.

[Noah sad]
Noah: Jeg spør mamma om mer penger.

# Dialogue
$ bg = menneske-stue
[Noah flip 220 sad left]
[Sara 680 neutral right]
Noah: Mamma! Spillet virker ikke!

[Sara surprised]
Sara: Hva er det som ikke virker?
Noah: Jeg åpnet lootbokser for å få et sverd.
Noah: Men nå får jeg ikke fler.

[Sara neutral]
Sara: Det er fordi du har brukt opp pengene dine.
Noah: Men Limal fikk det med én gang!
Noah: Kan jeg få penger til å kjøpe flere?
Sara: Nei. Du har brukt nok penger i spillet.
Noah: Men jeg vil også ha sverdet!
Sara: Det er tilfeldig hva du får, vennen min!
Sara: Limal var bare veldig heldig.
Noah: Åååh, så teit!

# Choice
title.text = Har du spilt noen spill som har lootbokser eller tilfeldige premier du må betale for?
title.fontSize = 40

choices(json) = [
  { text: 'Ja', fontSize: 50 },
  { text: 'Nei', fontSize: 50 },
]

# Choice
star = true
title.text = Hva syns dere det er greit å kjøpe for ekte penger i et spill?
title.fontSize = 40
choices(json) = [
  'Flere nye liv eller brett',
  'Kjøpe ting som gjør deg flinkere',
  'Ting som gjør at du ser kulere ut',
  'Ikke greit å bruke ekte penger, bare poeng opptjent i spillet',
  'Overraskelser eller lootbokser fordi det er spennende',
  'Ingenting er greit å kjøpe for hverken penger eller poeng opptjent i spillet.',
]
`
