import { useAutoEffect, useAutoMemo } from 'hooks.macro'
import context from './context'
import React from 'react'

export default function DropTarget({ children, name }) {
  if (!name) {
    throw Error('DropTarget requires a unique name prop in the context of its container')
  }

  const [hover, setHover] = React.useState(false)
  const [element, ref] = React.useState(null)
  const child = React.Children.only(children)
  const { addDropTarget } = React.useContext(context)

  useAutoEffect(() => {
    return addDropTarget(name, element)
  })

  useAutoEffect(() => {
    if (!element) return

    document.addEventListener('touchmove', onTouchMove)
    document.addEventListener('touchend', onTouchEnd)

    return () => {
      document.removeEventListener('touchmove', onTouchMove)
    }

    function onTouchMove(e) {
      const { clientX, clientY } = e.touches[0]
      const rect = element.getBoundingClientRect()

      // prettier-ignore
      const validX = rect.left < clientX && rect.left + rect.width  >= clientX
      // prettier-ignore
      const validY = rect.top  < clientY && rect.top  + rect.height >= clientY

      if (validX && validY) {
        setHover(true)
      } else {
        setHover(false)
      }
    }

    function onTouchEnd() {
      setHover(false)
    }
  })

  return useAutoMemo(React.cloneElement(child, { ref, draggableHover: hover }))
}
