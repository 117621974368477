import EvaluateStatements from './EvaluateStatements'
import CorrectPassword from './CorrectPassword'
import CreatePassword from './CreatePassword'
import DialogueChoice from './DialogueChoice'
import SmileyScales from './SmileyScales'
import ChooseImage from './ChooseImage'
import Feedback from './Feedback.tsx'
import Dialogue from './Dialogue'
import Discuss from './Discuss'
import Chapter from './Chapter'
import Recommendation from './Recommendation'
import Slider from './Slider'
import Choice from './Choice'
import Done from './Done'
import Text from './Text'

export default {
  EvaluateStatements,
  CorrectPassword,
  CreatePassword,
  DialogueChoice,
  Recommendation,
  SmileyScales,
  ChooseImage,
  Feedback,
  Dialogue,
  Discuss,
  Chapter,
  Slider,
  Choice,
  Done,
  Text,
}
