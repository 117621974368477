/*
import { useAutoEffect, useAutoCallback } from 'hooks.macro'
import React from 'react'
*/

export default function useIsMobile() {
  return false

  /*
  const checkIfMobile = useAutoCallback(() => {
    return window.matchMedia('only screen and (max-width: 760px)').matches
  })
  const [isMobile, setIsMobile] = React.useState(checkIfMobile)

  useAutoEffect(() => {
    const interval = setInterval(() => {
      if (isMobile !== checkIfMobile()) {
        setIsMobile(!isMobile)
      }
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return isMobile
  */
}
