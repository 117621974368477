import { useAutoEffect } from 'hooks.macro'

export default function usePageLogging(page) {
  useAutoEffect(() => {
    console.group(
      `%cScene: ${page.type}`,
      'background: tomato; color: white; padding: 5px 10px; font-weight: bold; border-radius: 4px;',
    )
    console.log('%cPage id: ' + page.id, 'color: #aaa')
    console.log('%coptions ' + JSON.stringify(page.options, null, 2), 'color: #aaa')
    console.groupEnd()
  })
}
