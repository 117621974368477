import styled, { keyframes } from 'styled-components/macro'
import SceneWrapper from 'common/SceneWrapper'
import { useAutoEffect } from 'hooks.macro'
import React from 'react'

const animationTime = 3
const beige = '#FFD89B'

export default function Chapter({
  options: { title, subtitle, titleFontSize = 60 },
  nextPage,
  prevPage,
}) {
  useAutoEffect(() => {
    const timeout = setTimeout(() => {
      nextPage()
    }, animationTime * 1000)

    return () => {
      clearTimeout(timeout)
    }
  })

  if (typeof title === 'string') {
    title = {
      text: title,
      fontSize: titleFontSize,
    }
  }

  if (typeof subtitle === 'string') {
    subtitle = {
      text: subtitle,
      fontSize: 30,
    }
  }

  return (
    <SceneWrapper>
      <Box>
        {title && <Text fontSize={title.fontSize}>{title.text}</Text>}
        {subtitle && <Text fontSize={subtitle.fontSize}>{subtitle.text}</Text>}
      </Box>
    </SceneWrapper>
  )
}

const Box = styled.div`
  background-color: ${beige};
  border: calc(2px * var(--scale)) solid black;
  width: calc(378px * var(--scale));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  border-radius: calc(7px * var(--scale));
  padding: calc(43px * var(--scale));

  animation: ${keyframes`
    0% {
      transform: translateX(-50%) translateY(-50%) scale(0.8);
      filter: blur(3px);
      opacity: 0;
    }
    10% {
      transform: translateX(-50%) translateY(-50%) scale(1);
      filter: blur(0);
      opacity: 1;
    }
    20% {
      filter: blur(0);
      opacity: 1;
    }
    80% {
      transform: translateX(-50%) translateY(-50%) scale(1.15);
      filter: blur(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) scale(1.5);
      filter: blur(10px);
      opacity: 0;
    }
  `} ${animationTime}s forwards;
`

const Text = styled.div`
  font-size: calc(${(p) => p.fontSize}px * var(--scale));
  line-height: calc(${(p) => p.fontSize * 1.4}px * var(--scale));
`
