import { useContext, createContext } from 'react'

const StoreContext = createContext<{
  setAudioEnabled: (boolean) => void,
  setHasStarted: (boolean) => void,
  interfacePNG: (string) => string,
  toggleAudio: () => void,
  audioEnabled: boolean,
  hasStarted: boolean,
  start: () => void,
  gameRef: Element,
}>(undefined)

export default StoreContext

export const useStore = () => useContext(StoreContext)
