export default `
# Chapter
$ bg = barn-klasserom
title = Tuesday
subtitle = At school

# Dialogue
[Luna neutral left 250 z=2]
[Bix neutral right 680]
Bix: I saw that naff photo was taken down!

[Luna happy]
Luna: Yes, my dad fixed it.

[Bix surprised up=true]
Bix: But do you know what?
Bix: My sister is mad at your brother.

[Luna surprised up=true]
Luna: What has happened?

[Bix angry up=false]
Bix: He was mean to her on Ding.

[Bix surprised up=true]
Bix: She was in tears. See what he wrote!

[Teacher id=safran neutral 110 left]
[Bix 780]
[Luna 421]
Teacher: Please sit, we’re about to start.

# Chapter
$ bg = neptunianer-stue
title = At Luna’s house

# Dialogue
[Luna angry left 250]
[Galea neutral right 680]
Luna: Mum! Timo is being mean!

[Galea surprised]
Galea: Is he? What has he done?
Luna: He was nasty to Bix’s sister...
Luna: ...on the class Ding group.
Galea: Really? I’ll have a word with him.

[Luna happy music=true]
Luna: Tiimooo will get a rollicking...

[Galea angry]
Galea: Shush, don’t tease. I’ll fix this.

# Slider
title(json) = {
  text:
'Timo was mean to Bix’s sister online. Have you seen or heard of anyone who has been mean or behaved like a bully online?',
  fontSize: 50,
}

labels(json) = ['Never', 'Some times', 'Many times', 'All the time']

# Discuss
star = true
paragraphs(json) = [
'What do you think parents should do when someone is being mean online?'
]

`
