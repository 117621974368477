import { useAutoCallback, useAutoMemo, useAutoEffect } from 'hooks.macro'
import { Translate, Norwegian, English } from 'languageContext'
import styled, { keyframes, css } from 'styled-components/macro'
import owasp from 'owasp-password-strength-test'
import SceneWrapper from 'common/SceneWrapper'
import NextButton from 'common/NextButton'
import BackButton from 'common/BackButton'
import Tipsrakett from 'common/Tipsrakett'
import { softThumpHigh, success, failure } from 'sounds'
import { useStore } from 'Store'
import React from 'react'

export default ({ options: { bg, title, words, tipsrakett }, storeAnswer, answer }) => {
  const [clicked, setClicked] = React.useState(false)
  const { audioEnabled } = useStore()

  const playSound = useAutoCallback(choice => {
    if (!audioEnabled) return
    new Audio(softThumpHigh).play()
  })

  const add = useAutoCallback(choice => {
    storeAnswer(answer =>
      Array.isArray(answer?.words)
        ? {
            ...answer,
            words: answer.words.concat(choice),
            submitted: false,
          }
        : { words: [choice] },
    )
  })

  const remove = useAutoCallback(() => {
    storeAnswer(answer => {
      if (!answer) return answer
      if (!answer.words.length) return answer
      return {
        ...answer,
        words: [...answer.words.slice(0, -1)],
        submitted: false,
      }
    })
  })

  const use = useAutoCallback(e => {
    e.target.blur()
    setClicked(true)
    storeAnswer(answer => {
      if (!answer) return answer
      return {
        ...answer,
        submitted: true,
      }
    })
  })

  const secure = useAutoMemo(() => {
    if (!answer?.words) return false

    const result = owasp.test(answer.words.join(''))

    if (answer?.submitted) {
      console.log(result)
    }

    return !!result?.strong
  })

  useAutoEffect(() => {
    if (!clicked || !answer?.submitted) return
    if (secure) {
      new Audio(success).play()
    } else {
      new Audio(failure).play()
    }
  })

  return (
    <SceneWrapper bg={bg}>
      <Backdrop />
      <Modal>
        {answer?.submitted && !secure ? (
          <PasswordError fontSize={title.fontSize}>
            <Translate>
              <Norwegian>Ikke sikkert nok. Prøv igjen</Norwegian>
              <English>Not secure enough. Try again.</English>
            </Translate>
          </PasswordError>
        ) : answer?.submitted && secure ? (
          <PasswordSuccess fontSize={title.fontSize}>
            <Translate>
              <Norwegian>Passord godkjent!</Norwegian>
              <English>Password approved!</English>
            </Translate>
          </PasswordSuccess>
        ) : (
          <Title fontSize={title.fontSize}>{title.text}</Title>
        )}
        <Choices>
          {words.map(word => (
            <ChoiceWrapper key={word}>
              <Choice onMouseDown={() => playSound(word)} onClick={() => add(word)}>
                {word}
              </Choice>
            </ChoiceWrapper>
          ))}
        </Choices>
        <InputField error={!secure && answer?.submitted}>
          {answer?.words?.join?.('') || <>&nbsp;</>}
          {!answer?.submitted && <Cursor />}
        </InputField>
        <Buttons>
          <Button outline onClick={remove}>
            <Translate>
              <Norwegian>Slett</Norwegian>
              <English>Delete</English>
            </Translate>
          </Button>
          <Button onClick={use} inactive={Boolean(answer?.submitted)}>
            <Translate>
              <Norwegian>Bruk</Norwegian>
              <English>Use</English>
            </Translate>
          </Button>
        </Buttons>
        <BottomRow>
          <BackButton />
          {tipsrakett && <Tipsrakett />}
          <NextButton disabled={!answer?.submitted || !secure} />
        </BottomRow>
      </Modal>
    </SceneWrapper>
  )
}

const Button = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  padding: calc(15px * var(--scale)) calc(50px * var(--scale));
  font-size: calc(30px * var(--scale));
  border: 1px solid #48f7cd;
  background-color: #48f7cd;
  border-radius: 100px;
  margin: calc(5px * var(--scale));
  text-align: center;
  cursor: pointer;
  transition: opacity 1s;

  ${p =>
    p.outline &&
    css`
      background-color: white;
    `}

  ${p =>
    p.inactive &&
    css`
      pointer-events: none;
      opacity: 0.4;
      filter: grayscale(1);
    `}

  transition: transform 0.4s, filter 0.4s;
  transform: translate3d(0, 0, 0);
  :focus {
    transform: translate3d(0, 0, 0) scale(1.05);
    filter: var(--slight-focus-filter);
    outline: none;
    z-index: 1;
  }
`

const Buttons = styled.div`
  margin-top: calc(10px * var(--scale));
  text-align: center;
`

const InputField = styled.div`
  font-size: calc(30px * var(--scale));
  border: calc(2px * var(--scale)) solid black;
  padding: calc(20px * var(--scale));
  border-radius: calc(5px * var(--scale));
  margin-top: calc(30px * var(--scale));
  max-width: calc(800px * var(--scale));
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
`

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Backdrop = styled.div`
  position: absolute;
  background-color: rgba(51, 118, 169, 0.4);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1s forwards;
`

const Modal = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  padding: calc(60px * var(--scale)) calc(160px * var(--scale));
  position: absolute;
  bottom: calc(40px * var(--scale));
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(40px * var(--scale));
  border-radius: calc(40px * var(--scale));

  animation: ${keyframes`
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  `} 0.5s forwards;
`

const Title = styled.h2`
  font-size: calc(${p => p.fontSize}px * var(--scale));
  text-align: center;
  margin-top: calc(60px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
`

const PasswordError = styled.h2`
  font-size: calc(${p => p.fontSize}px * var(--scale));
  text-align: center;
  margin-top: calc(60px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
  color: #fe5c30;
`

const PasswordSuccess = styled.h2`
  font-size: calc(${p => p.fontSize}px * var(--scale));
  text-align: center;
  margin-top: calc(60px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
  color: #00db19;
`

const Choices = styled.div`
  text-align: center;
`

const ChoiceWrapper = styled.div`
  display: inline-block;
  margin: calc(5px * var(--scale));
`

const Choice = styled.button.attrs({
  type: 'button',
})`
  background-color: #e8e3d7;
  font-size: calc(30px * var(--scale));
  border-radius: calc(10px * var(--scale));
  border: calc(4px * var(--scale)) solid transparent;
  cursor: pointer;
  width: 100%;
  padding: calc(10px * var(--scale)) calc(20px * var(--scale));
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  z-index: 0;

  transition: transform 0.4s, filter 0.4s;
  transform: translate3d(0, 0, 0);
  :focus {
    transform: translate3d(0, 0, 0) scale(1.1);
    filter: var(--focus-filter);
    outline: none;
    z-index: 1;
  }
`

const Cursor = () => {
  const [on, setOn] = React.useState(true)

  useAutoEffect(() => {
    const interval = setInterval(() => {
      setOn(on => !on)
    }, 500)
    return () => {
      clearInterval(interval)
    }
  })

  return <span style={{ opacity: on ? 1 : 0 }}>|</span>
}
