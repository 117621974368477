export default `
# Chapter
$ bg = barn-klasserom
title = Monday
subtitle = At school 

# Dialogue
[Luna neutral left 250]
[Bix happy right 680]
Luna: Hi Bix!

[Bix surprised]
Bix: Hi! That photo of you was so naff!

[Luna confused]
Luna: What?
Bix: On Spacebook. Everyone’s laughing!

[Luna surprised]
Luna: Oh no! Who took it?

[Bix neutral]
Bix: Limal’s mum posted it.

[Luna sad]
Luna: Take it down!

[Bix sad]
Bix: I can’t delete other people’s photos.
Luna: Ooooh...

# Chapter
$ bg = luna-soverom
title = At Luna’s house 

# Dialogue
[Luna sad left 250]
[Edan surprised right 680]
Edan: Are you crying? What’s happened?

[Edan neutral]
Luna: Limal’s mum posted a stupid photo...
Luna: ...of me on Spacebook.

[Edan surprised]
Edan: Did she ask permission?
Luna: No, I didn’t know about it.
Luna: I’m not going to school tomorrow!

[Luna down=true]
[Edan up=true]
Luna: Everybody’s laughing at me.

# Choice
star = true
title(json) = {
  text: 'What should Luna’s dad do?',
  fontSize: 50,
}
showWrong = true
choices(json) = [
  { text: 'Phone the police.' },
  { text: 'Talk to Limal’s mum to have the picture taken down.' },
  { text: 'Report the picture to Spacebook.' },
  { text: 'Talk to the headmaster or a teacher.' },
  { text: 'Comment on the picture and ask to have it taken down.' },
  { text: 'Nothing.', wrong: true },
]

# Dialogue
[Luna sad left down=true 250]
[Edan neutral right 680]
Edan: I’ll fix this! You just go to bed.

[Edan tilt=true]
Edan: Try not to think about it. Ok?
Luna: I’ll try. Good night.

# Discuss
paragraphs(json) = [
'When is it all right for adults to show or post pictures of other people?',
'Have you experienced something similar?',
]
`
