export default `
# Chapter
$ bg = barn-klasserom
title = Friday 
subtitle = At school 

# Dialogue
[Noah flip left happy 240]
[Limal right neutral 700]
Noah: The Moon Sword shop opens tomorrow.

[Limal happy]
Limal: Yes! I can’t wait!
Limal: Can we play Moon Sword tonight?

[Noah confused]
Noah: No, I’m playing boardgames with my mum.

[Noah neutral]
Noah: Fridays are no-screen days.

[Limal neutral]
Limal: Cool! Can I join you some time?
Noah: Hehe. I’m sure you can.

# Chapter
$ bg = menneske-stue
title = At Noah’s house 

# Dialogue
[Noah flip 240 left happy]
[Sara right neutral 700]
Noah: You’re home! Can we play boardgames?

[Sara surprised]
Sara: Oops! We agreed a no-screen night.

[Sara sad]
Sara: But I need to work.

[Noah angry]
Noah: But you’ve been on the laptop...
Noah: ...all day at work already.
Sara: Yes, I know.

[Noah confused]
Noah: How long till you’ve finished?
Sara: Sorry, it will take all evening.

[Noah sad]
Noah: But I only get an hour a day...
Noah: ... on the laptop. Why do you get...
Noah: ... to use it as much as you like?
Sara: This is different. It’s for work.

[Noah angry]
Noah: It’s not fair! You said we’d play.
Sara: We’ll have to do it another day, Noah.

[Noah sad]
Noah: You promised...

[Sara right sad 700]
[Noah invisible=true flip=false 100]
___: ...

# EvaluateStatements
star = true
title.text = How does your family do it? 
title.fontSize = 40
labels.yes = Correct 
labels.uncertain = Sometimes 
labels.no = Not correct 

statements(json) = [
  'Our family argues about screen time.',
  'The same screen time rules apply for adults and children.',
  'Adults’ screen time is more important than children’s screen time.',
  'Adults don’t know what the kids are using the device for.',
  'Screen time is always fine if it’s to do with work or homework.',
]

# Discuss
fontSize = 45
lines(json) = [
'It’s a good idea to have screen time rules that everyone has agreed on. Suggest three good rules for children and adults.'
]
`
