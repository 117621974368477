import sessionStorage from 'sessionStorage'
import localStorage from 'localStorage'
import { v4 as uuid } from 'uuid'

const clientId =
  localStorage.getItem('familiespill.clientId') ||
  sessionStorage.getItem('familiespill.clientId') ||
  uuid()

localStorage.setItem('familiespill.clientId', clientId)
sessionStorage.setItem('familiespill.clientId', clientId)

export default clientId
