export default `
# Chapter
$ bg = noah-soverom
title = Saturday 
subtitle = At Noah’s house

# Dialogue
[Noah neutral 240 flip left]
[Sara happy 700 right]
Sara: I received an email from your teacher.
Sara: He said your reading has really improved.

[Noah happy]
Noah: I have learnt a lot from Moon Sword.
Noah: There is a lot of important text in the game.

[Sara surprised]
Sara: Hm. I never thought about that.
Noah: We chat with other gamers in writing.

[Sara happy]
Sara: That’s great!
Sara: I’ll get you something from that...
Sara: ... Moon shop for doing so well.
Noah: Yeees!

# Chapter
$ bg = monesverdet-shop
title.text = In the Moon Sword shop 
title.fontSize = 40

# Dialogue
[Noah 180 neutral left flip]
[Limal 454 surprised]
[Sara neutral 800 neutral]
Limal: Woah! Cool shop!

[Noah surprised]
Noah: Yes! They have everything here!
Limal: Look! Real versions of my sword!
Noah: Mum, can I have one of those?

[Sara happy]
Sara: Sure, you can have one each, as...
Sara:...Limal needs a birthday present.

[Noah happy]
Noah: Yeeeey!

# Text
paragraphs(json) = [
  'Noah has become good at reading and writing because he plays an online game.',
  'What other fun and useful things can you do online? You’ll find suggestions on the next page.',
]

# Choice
title.fontSize = 30
title.text = Choose three things you can do online. 
textWhenWrong = Impossible to do online. Choose another one. 

choices(json) = [
  { text: 'Read about dinosaurs' },
  { text: 'Write stories' },
  { text: 'Have your dinner', wrong: true },
  { text: 'Swim in the swimming pool', wrong: true },
  { text: 'Play hide and seek', wrong: true },
  { text: 'Do homework' },
  { text: 'Watch a film' },
  { text: 'Talk with your mates' },
  { text: 'Play football outdoors', wrong: true },
]
numberOfChoices = 3

# Feedback
$ bg = tegning-limal-noah
title = Congratulations. 
subtitle = You have now completed Noah’s story. 
overflow = 132%

# Recommendation 
$ bg = bakgrunn-edan 
title = Congratulations.
overflow = 105%

# Done
$ bg = tegning-limal-noah
download = tegne-limal-noah.jpg
title = Congratulations. 
subtitle = You have now completed Noah’s story. 
overflow = 132%
`
