import React from 'react'
import styled from 'styled-components/macro'

const EN = 'EN'
const NO = 'NO'

const enIcon = require('./static/en.png')
const noIcon = require('./static/no.png')

class LanguageButton extends React.Component {
  changeLanguage = () => {
    const currentLanguage = this.currentLanguage()
    window.location.search = `la=${currentLanguage === NO ? EN : NO}`
  }

  currentLanguage = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    return params.la || NO
  }
  render() {
    const language = this.currentLanguage()
    return (
      <LanguageImage
        src={language === NO ? enIcon : noIcon}
        alt="language"
        onClick={this.changeLanguage}
      />
    )
  }
}

const LanguageImage = styled.img`
  position: absolute;
  top: calc(16px * var(--slight-scale));
  right: calc(10px * var(--slight-scale));
  z-index: 100;
  cursor: pointer;
  color: white;
  width: calc(45px * var(--slight-scale));
  height: calc(32px * var(--slight-scale));
`
export default LanguageButton
