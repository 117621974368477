import * as luna from './luna'
import * as noah from './noah'

const config = [luna, noah]

export default config

export const availableCharacters = config
  .filter((story) => story.days.length && story.days.every((day) => day.pages.length))
  .map((story) => story.name.toLowerCase())
