import { useLocation } from 'react-router-dom'
import { useAutoCallback } from 'hooks.macro'
import styled from 'styled-components/macro'
import queryString from 'query-string'
import { Portal } from 'react-portal'
import React from 'react'

export default function DebugControls() {
  const [time, setTime] = React.useState(0)
  const location = useLocation()
  const search = queryString.parse(location.search)

  const changeTime = useAutoCallback(() => {
    setTime(time => {
      switch (time) {
        case 0:
          return 100
        case 100:
          return 500
        case 500:
          return 1000
        case 1000:
          return 2000
        default:
          return 0
      }
    })
  })

  if (search.debugControls === 'off') return null

  return (
    <Portal>
      <Container>
        <Title>Debug controls</Title>
        <TimeWrapper onClick={changeTime}>
          Time per frame: <Time>{time}</Time>ms
        </TimeWrapper>
        <div>
          <button tabIndex={-1} type="button" onClick={() => (window as any).firstPage(time)}>
            Go to first page
          </button>
          <button tabIndex={-1} type="button" onClick={() => (window as any).lastPage(time)}>
            Go to last page
          </button>
        </div>
        <div>
          <button tabIndex={-1} type="button" onClick={() => (window as any).prevPage()}>
            Back
          </button>
          <button tabIndex={-1} type="button" onClick={() => (window as any).nextPage()}>
            Next
          </button>
          <button tabIndex={-1} type="button" onClick={() => (window as any).storeAnswer()}>
            Clear answer
          </button>
        </div>
      </Container>
    </Portal>
  )
}

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  padding: 10px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom-right-radius: 5px;

  & button {
    margin: 3px;
    border-radius: 4px;
    padding: 3px 5px;
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
`

const Time = styled.div`
  background-color: white;
  border: 1px solid black;
  padding: 5px;
  display: inline-block;
`

const TimeWrapper = styled.div`
  text-align: center;
`
