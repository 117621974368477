import { useSetChildrenStyle } from 'common/SceneWrapper'
import { useAutoEffect, useAutoMemo } from 'hooks.macro'
import styled, { css } from 'styled-components/macro'
import usePageState from 'Storyline/usePageState'
import { Portal } from 'react-portal'
import { useStore } from 'Store'
import * as React from 'react'

export default function Tipsrakett() {
  const {
    options: {
      tipsrakett: { title, lines },
    },
  } = usePageState()
  const [hidden, setHidden] = React.useState(true)
  const setChildrenStyle = useSetChildrenStyle()
  const [open, setOpen] = React.useState(false)
  const [up, setUp] = React.useState(false)
  const { gameRef } = useStore()

  const result = useAutoMemo(() => {
    const result = []
    let list = null
    let key = 0

    for (const line of lines) {
      if (line[0] === '-') {
        if (!list) {
          addPendingList()
          list = []
        }
        list.push(line.slice(1).trim())
      } else if( line.includes('<a href')){
        const start = line.indexOf('<a href="')
        let end = start + '<a href="'.length
        let address= ''
        while (end  < line.length && line[end] !== '"'){
          address += line[end]
          end +=1
        }
        let hrefText = ''
        end +=2
        while (end < line.length && line[end] !== '<'){
          hrefText += line[end] 
          end +=1
        }
        end += 4
        result.push(
          <Paragraph key={key++}>
            {line.slice(0, start)}
            <a href={address}>{hrefText}</a>
            {line.slice(end, line.length)}
          </Paragraph>,
        )
      }
      else if (line.includes('https://')) {
        const start = line.indexOf('https://')
        let end = start + 'https://'.length
        while (end < line.length && !/\s/.test(line[end])) {
          end += 1
        }
        result.push(
          <Paragraph key={key++}>
            {line.slice(0, start)}
            <a href={line.slice(start, end)}>{line.slice(start, end)}</a>
            {line.slice(end, line.length)}
          </Paragraph>,
        )
      }else {
        addPendingList()
        result.push(<Paragraph key={key++}>{line}</Paragraph>)
      }
    }

    addPendingList()

    return result

    function addPendingList() {
      if (list) {
        result.push(
          <UnorderedList key={key++}>
            {list.map((text, i) => (
              <li key={i}>{text}</li>
            ))}
          </UnorderedList>,
        )
        list = null
      }
    }
  })

  useAutoEffect(() => {
    if (open) return

    const timeout1 = setTimeout(() => {
      setUp(false)
    }, 50)

    const timeout2 = setTimeout(() => {
      setHidden(true)
    }, 350)

    return () => {
      clearTimeout(timeout1)
      clearTimeout(timeout2)
    }
  })

  useAutoEffect(() => {
    if (!open) return
    setHidden(false)

    const timeout = setTimeout(() => {
      setUp(true)
    }, 50)

    return () => {
      clearTimeout(timeout)
    }
  })

  useAutoEffect(() => {
    if (!up) return

    setChildrenStyle({
      display: 'none',
    })

    return () => {
      setChildrenStyle()
    }
  })

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <img src="/media/large/interface/tipsrakett-dark-orange.png" alt="" />
        <div>Tips</div>
      </Button>
      {!hidden && (
        <Portal node={gameRef}>
          <Modal open={up}>
            <Close onClick={() => setOpen(false)} title="Lukk tipsmenyen">
              <img src="/media/large/interface/lukk.svg" alt="" width="100%" />
            </Close>
            <Title>{title}</Title>
            <TextContainer>{result}</TextContainer>
          </Modal>
        </Portal>
      )}
    </>
  )
}

const Button = styled.button.attrs({
  type: 'button',
})`
  --height: calc(48px * var(--scale));
  --width: calc(152px * var(--scale));

  background-color: transparent;
  display: block;
  border: none;
  position: relative;
  height: var(--height);
  width: var(--width);
  cursor: pointer;

  & > img {
    position: absolute;
    left: 0;
    top: 0;
    height: var(--height);
    width: var(--width);
  }

  & > div {
    position: absolute;
    left: 0;
    top: 0;
    height: var(--height);
    width: var(--width);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(25px * var(--scale));
    font-family: 'Walter Turncoat', sans-serif;
    text-transform: lowercase;
    padding-left: calc(24px * var(--scale));
    padding-bottom: calc(5px * var(--scale));
  }

  transition: transform 0.4s, filter 0.4s;
  transform: translate3d(0, 0, 0);
  :focus {
    transform: translate3d(0, 0, 0) scale(var(--focus-scale));
    filter: var(--focus-filter);
    outline: none;
  }
`

const Modal = styled.div`
  position: absolute;
  z-index: 101;
  bottom: calc(40px * var(--scale));
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(60px * var(--scale));
  background-color: #c0f3ff;
  border-radius: calc(40px * var(--scale));
  transition: transform 0.4s;
  padding: calc(100px * var(--scale));
  font-family: 'Rubik', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${p =>
    !p.open &&
    css`
      transform: translateY(100%) translateY(calc(40px * var(--scale)));
    `}
`

const Title = styled.h2`
  font-size: calc(44px * var(--scale));
  text-align: left;
  width: 100%;
`

const Paragraph = styled.p`
  font-size: calc(30px * var(--scale));
  text-align: left;
  margin-top: calc(30px * var(--scale));
  width: 100%;
`

const Close = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: calc(10px * var(--scale));
  right: calc(14px * var(--scale));
  width: calc(62px * var(--scale));
  height: calc(62px * var(--scale));
  border-radius: calc(1000px * var(--scale));
  border: none;
  background-color: transparent;
  font-size: calc(20px * var(--scale));
  cursor: pointer;
  display: block;

  transform-origin: 100% 0%;
  transition: transform 0.4s, filter 0.4s;
  transform: translate3d(0, 0, 0);
  :focus {
    transform: translate3d(0, 0, 0) scale(var(--focus-scale));
    filter: var(--focus-filter);
    outline: none;
  }
`

const UnorderedList = styled.ul`
  font-size: calc(30px * var(--scale));
  text-align: left;
  margin-top: calc(30px * var(--scale));
  margin-left: calc(30px * var(--scale));
`

const TextContainer = styled.div``
