import React from 'react'
import styled, { css } from 'styled-components/macro'
import StoreContext from 'Store'

const Container = styled.div`
  background-image: url(${p => p.backgroundImg});
  background-size: cover;
  height: 100%;
  width: 100%;
  display: relative;
`

// ------ components for importing, viewing and styling images ------ //
const Img = styled.img`
  width: 100%;
`

// The confusing-looking ternaries let you send pure numbers. They are converted to pixels.
// If you send strings, you only get the string you sent. For percents and so on.
const ImgContainer = styled.div.attrs(p => ({
  h:
    (typeof p.size === 'number' ? p.size + 'px' : p.size) ||
    (typeof p.h === 'number' ? p.h + 'px' : p.h),
  w:
    (typeof p.size === 'number' ? p.size + 'px' : p.size) ||
    (typeof p.w === 'number' ? p.w + 'px' : p.w),
  x: typeof p.x === 'number' ? p.x + 'px' : p.x,
  y: typeof p.y === 'number' ? p.y + 'px' : p.y,
}))`
  height: calc(${p => p.h} * var(--scale));
  width: calc(${p => p.w} * var(--scale));
  ${p =>
    p.center &&
    css`
      transform: translate3d(-50%, 0, 0);
    `}
`

const AbsoluteImgContainer = styled(ImgContainer)`
  position: absolute;
  top: calc(${p => p.y} * var(--scale));
  left: calc(${p => p.x} * var(--scale));
`

const Planet = ({ src, ...p }) => (
  <AbsoluteImgContainer {...p}>
    <Img src={src} />
  </AbsoluteImgContainer>
)

const Background = ({ children, noPlanets }) => {
  const { interfacePNG } = React.useContext(StoreContext)
  return (
    <Container backgroundImg={interfacePNG('character-select-background')}>
      {!noPlanets && (
        <>
          <Planet src={interfacePNG('neptun')} size={532} x={-55} y={454} />
          <Planet src={interfacePNG('pluto')} size={164} x={1035} y={414} />
          <Planet src={interfacePNG('haumea')} w={77} h={50} x={299} y={228} />
          <Planet src={interfacePNG('makemake')} size={30} x={107} y={253} />
        </>
      )}
      {children}
    </Container>
  )
}

export default Background
