import styled from 'styled-components/macro'
import { Route } from 'react-router-dom'
import React from 'react'
import Intro from './Intro'
import CharacterMenu from './CharacterMenu'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

function Menu() {
  return (
    <Container>
      <Route exact path="/">
        <Intro />
      </Route>
      <Route exact path="/velg-karakter">
        <CharacterMenu />
      </Route>
    </Container>
  )
}

export default Menu
