import styled, { keyframes } from 'styled-components/macro'
import SceneWrapper from 'common/SceneWrapper'
import Tipsrakett from 'common/Tipsrakett'
import NextButton from 'common/NextButton'
import BackButton from 'common/BackButton'
import React from 'react'

export default ({
  options: { tipsrakett, bg, paragraphs, link, fontSize = 45 },
  storeAnswer,
  answer,
}) => {
  return (
    <SceneWrapper bg={bg}>
      <Backdrop />
      <Modal>
        <Content>
          {paragraphs.map((text, i) => (
            <Text key={i} fontSize={fontSize + 'px'}>
              {text}
            </Text>
          ))}
          {Boolean(link) && (
            <Link href={link} target="_blank">
              {link}
            </Link>
          )}
        </Content>
        <BottomRow>
          <BackButton />
          {tipsrakett && <Tipsrakett />}
          <NextButton />
        </BottomRow>
      </Modal>
    </SceneWrapper>
  )
}

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Backdrop = styled.div`
  position: absolute;
  background-color: rgba(6, 85, 145, 0.7);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1s forwards;
`

const Modal = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  padding: calc(60px * var(--scale)) calc(180px * var(--scale));
  position: absolute;
  bottom: calc(40px * var(--scale));
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(40px * var(--scale));
  border-radius: calc(40px * var(--scale));
  display: flex;

  animation: ${keyframes`
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  `} 0.5s forwards;
`

const Text = styled.div`
  font-size: ${(p) => `calc(${p.fontSize}* var(--scale))`};
  text-align: center;
  margin-top: calc(60px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
  white-space: pre-line;
`

const Link = styled.a`
  font-size: calc(26px * var(--scale));
  text-align: center;
  margin-top: calc(60px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
  white-space: pre-line;
`
const Content = styled.div`
  margin: auto 0;
  width: 100%;

  ::after {
    content: '';
    height: calc(70px * var(--scale));
    display: block;
  }
`
