import { useAutoCallback, useAutoEffect } from 'hooks.macro'
import storage from './storage'
import React from 'react'

export default function usePersistentState<T>(
  defaultState: T,
  identifier: any,
  condition?: ((T) => boolean) | T,
) {
  const [prevIdentifier, setPrevIdentifier] = React.useState(identifier)

  const [state, setState] = React.useState(() => {
    if (storage.has(identifier)) {
      return storage.get(identifier)
    }
    return defaultState
  })

  useAutoEffect(() => {
    const removeListener = storage.onChange(identifier, value => {
      console.log('Received update for ' + identifier)
      setState(value)
    })

    return () => {
      removeListener()
    }
  })

  useAutoEffect(() => {
    if (identifier !== prevIdentifier) {
      setState(storage.get(identifier))
      setPrevIdentifier(identifier)
    }
  })

  const setStateAndPersist = useAutoCallback(nextState => {
    setState(prevState => {
      if (typeof nextState === 'function') {
        nextState = nextState(prevState)
      }

      if (typeof condition !== 'function' || (condition as (T) => boolean)(nextState)) {
        storage.set(identifier, nextState)
      }

      return nextState
    })
  })

  return [state, setStateAndPersist]
}
