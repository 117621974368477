export default `
# Chapter
$ bg = barn-klasserom

title = Tuesday 
subtitle = At school 

# Dialogue
[Noah flip neutral left 220]
[Limal neutral right 780]
Limal: Hi Noah!

[Noah happy]
Noah: There’s a shop opening on Saturday.
Noah: They’re selling Moon Sword stuff.

[Limal happy]
Limal: That’s so cool!
Noah: And we’re going there together!
Limal: Yes your mum told my mum yesterday.
Noah: I’m looking forward to it!

# Chapter
$ bg = mira-soverom

title.text = At Noah’s house 
title.fontSize = 45

# Dialogue
[Noah confused flip 500]
Noah: Nobody’s home yet.
Noah: Mira’s computer is on.
Noah: I wonder what she’s up to?

# Dialogue
$ bg(json) = {
  image: 'mira-data-en',
  contain: true,
  color: '#bcbcbc',
}
Noah: What’s her Ding password?

# CorrectPassword
choices(list) = iloveyou, monkey, army, princess 

# Dialogue
$ bg = mira-data-riktig-passord-en
Noah: Wow, that was easy!

$ bg = mira-soverom
[Noah happy flip 500]
Noah: Hehe, I’m smart.

[Noah confused]
Noah: Her Ding account is boring.

[Noah angry]
Noah: Only selfies and makeup. Yuck!

# Dialogue
$ bg = menneske-stue
[Sara neutral 680 right]
Sara: Hello! I’m home.

[Noah left 220 flip surprised]
Noah: I guessed Mira’s Ding password!

[Noah happy]
[Sara surprised]
Sara: Did you go on Mira’s Ding account?

[Noah neutral]
Noah: Yes. It was boring, and yucky.

[Sara angry]
Sara: That’s not allowed, Noah!

[Noah surprised]
Noah: But her password was so simple!
Sara: Easy or not. It is not allowed!
[Noah sad]
Noah: Ok... I won’t do it again.

# Text
paragraphs(json) = [
'Mira’s password is too simple. On this occasion it was Noah who guessed it, but had it been somebody else, the outcome could have been worse.',
'On the next page, make a good and safe password for Mira.',
]

# CreatePassword
star = true
words(list) = Sing, 28, !, holiday, Chocolate, _, 100, +, 15, birthday, Super, 03, star, ?, 85, dance, Puss, best, =, sweet 
title.fontSize = 30
title.text = Choose words to build a secure password

tipsrakett.lines(json) = [
'It is easier to remember sentences than random combinations of letters and numbers. The password should have at least 5 words or 16 characters, contain numbers, symbols, spaces and upper/lower case letters. Different passwords should be used for different services.',
'Make a note of your passwords.',
'It is all right to make a note of your passwords. It is more important that you make good passwords that are unique for all services than that you are able to remember them all. Jot down your passwords on a piece of paper and put it somewhere safe. It is useful to memorise passwords that you use often. It is not a good idea to write your passwords on a slip of paper that you leave on your desk or in your bag, or to store your passwords in a document on your computer.',
]
`
