import styled, { keyframes, css } from 'styled-components/macro'
import SceneWrapper from 'common/SceneWrapper'
import { useAutoCallback } from 'hooks.macro'
import NextButton from 'common/NextButton'
import BackButton from 'common/BackButton'
import { softThumpHigh } from 'sounds'
import { useStore } from 'Store'
import React from 'react'

export default ({ options: { bg, title, choices, help }, storeAnswer, answer }) => {
  const [willClose, setWillClose] = React.useState(false)
  const { audioEnabled } = useStore()

  const playSound = useAutoCallback(() => {
    if (!audioEnabled) return

    new Audio(softThumpHigh).play()
  })

  const playSoundOnSelect = useAutoCallback(e => {
    if (e.which === 13 || e.which === 32) playSound()
  })

  const isSelected = (text, image) => answer && answer.text === text && answer.image === image

  return (
    <SceneWrapper bg={bg} buttonText>
      <Backdrop willClose={willClose} />
      <Modal willClose={willClose}>
        <Title fontSize={title.fontSize}>{title.text}</Title>
        <Help>{help}</Help>
        <Choices>
          {choices.map(({ text, image }) => (
            <ImageWrapper key={text || image}>
              <Button
                onKeyDown={playSoundOnSelect}
                onMouseDown={playSound}
                onClick={() => {
                  if (answer && answer.text === text && answer.image === image) {
                    storeAnswer(null)
                  } else {
                    storeAnswer({ text, image })
                  }
                }}
                isSelected={isSelected(text, image)}
              >
                <Image alt={text} src={image} isSelected={isSelected(text, image)} />
              </Button>
            </ImageWrapper>
          ))}
        </Choices>
        <BottomRow>
          <BackButton />
          <NextButton
            disabled={!answer}
            millisecondsDelay={500}
            onBeforeNextPage={() => {
              setWillClose(true)
            }}
          />
        </BottomRow>
      </Modal>
    </SceneWrapper>
  )
}

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Backdrop = styled.div`
  position: absolute;
  background-color: rgba(51, 118, 169, 0.4);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1s forwards;

  ${p =>
    p.willClose &&
    css`
      animation: ${keyframes`
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    `} 0.5s forwards;
    `}
`

const Modal = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  padding: calc(60px * var(--scale));
  position: absolute;
  bottom: calc(40px * var(--scale));
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(40px * var(--scale));
  border-radius: calc(40px * var(--scale));

  animation: ${keyframes`
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  `} 0.5s forwards;

  ${p =>
    p.willClose &&
    css`
      animation: ${keyframes`
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    `} 0.5s forwards;
    `}
`

const Title = styled.h2`
  font-size: calc(${p => p.fontSize}px * var(--scale));
  text-align: center;
  margin-top: calc(10px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
`

const Choices = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ImageWrapper = styled.div`
  flex-basis: calc(100% / 3);
  padding: calc(10px * var(--scale));
  display: flex;
`

const Image = styled.img`
  background-color: #e8e3d7;
  flex-basis: 100%;
  border-radius: calc(10px * var(--scale));
  border: calc(4px * var(--scale)) solid transparent;
  cursor: pointer;
  height: calc(186px * var(--scale));
  object-fit: contain;
  ${p =>
    p.isSelected &&
    css`
      border-color: #0a5b99;
    `}
`

const Button = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
  display: block;
  width: 100%;
  flex-basis: 100%;
  display: flex;

  :focus {
    outline: none;

    ${p =>
      !p.isSelected &&
      css`
        & ${Image} {
          border-color: #666;
        }
      `}
  }
`

const Help = styled.div`
  font-size: calc(25px * var(--scale));
  color: #959595;
  text-align: center;
  transform: translateY(calc(-20px * var(--scale)));
`
