import styled, { keyframes, css } from 'styled-components/macro'
import usePageState from 'Storyline/usePageState'
import storylineContext from 'Storyline/context'
import { useAutoMemo } from 'hooks.macro'
import React from 'react'

export const DEFAULT_NUMBER_OF_STARS = 0

const setStarContainerStyleContext = React.createContext<any>(undefined)
const setChildrenStyleContext = React.createContext<any>(undefined)
const setStarStyleContext = React.createContext<any>(undefined)
const configContext = React.createContext({})

export const useSetStarContainerStyle = () => React.useContext(setStarContainerStyleContext)
export const useSetChildrenStyle = () => React.useContext(setChildrenStyleContext)
export const useSetStarStyle = () => React.useContext(setStarStyleContext)
export const useConfig = () => React.useContext(configContext)

const NOT_SPECIFIED = Symbol('NOT_SPECIFIED')

export default ({
  backgroundBlendMode,
  buttonText: inputButtonText = NOT_SPECIFIED,
  overflow,
  children,
  hideStar,
  hideSpaceShip,
  bgColor,
  ...props
}: any) => {
  const [starContainerStyle, setStarContainerStyle] = React.useState({})
  const [childStyle, setChildStyle] = React.useState({})
  const { template, pages, pageIndex } = usePageState()
  const [starStyle, setStarStyle] = React.useState({})

  const numberOfStars = useAutoMemo(() => {
    let numberOfStars = DEFAULT_NUMBER_OF_STARS || 0
    for (let i = 0; i < pages.length && i < pageIndex; i++) {
      if (pages[i].options.star) {
        numberOfStars += 1
      }
    }
    return numberOfStars
  })

  let {
    options: { bg, star },
  } = React.useContext(storylineContext)

  let contain = false
  let image

  if (typeof bg === 'string' && bg !== 'unset') {
    image = bg
  } else if (bg) {
    image = bg.image
    contain = bg.contain

    if (bg.color) {
      bgColor = bg.color
    }
  }

  if (typeof image === 'string' && !/\.[a-zA-Z]+$/.test(image)) {
    image += '.png'
  }

  if (typeof props.onClick === 'function') {
    props = {
      ...props,
      tabIndex: 0,
      onKeyUp: event => {
        if (event.key === 'Enter') {
          props.onClick(event)
        }
      },
    }
  }

  const config = useAutoMemo(() => {
    if (inputButtonText === NOT_SPECIFIED) {
      let buttonText = inputButtonText

      if (template === 'Dialogue') {
        buttonText = false
      } else {
        buttonText = true
      }

      return { buttonText }
    }
    return { buttonText: inputButtonText }
  })

  return (
    <setChildrenStyleContext.Provider value={setChildStyle}>
      <setStarContainerStyleContext.Provider value={setStarContainerStyle}>
        <setStarStyleContext.Provider value={setStarStyle}>
          <configContext.Provider value={config}>
            <SceneWrapper
              contain={contain}
              image={image}
              bgColor={bgColor}
              backgroundBlendMode={backgroundBlendMode}
              overflow={overflow}
              {...props}
            >
              { Boolean( !hideSpaceShip) &&
                <>
                  <SpaceShip />
                  <CollectedStars />
                </>
              }
              {(star && !hideStar) && (
                <StarContainer style={starContainerStyle}>
                  <Star starIndex={numberOfStars % 3} style={starStyle} />
                </StarContainer>
              )}
              <div style={childStyle}>{children}</div>
            </SceneWrapper>
          </configContext.Provider>
        </setStarStyleContext.Provider>
      </setStarContainerStyleContext.Provider>
    </setChildrenStyleContext.Provider>
  )
}

const SceneWrapper = styled.div`
    ${p =>
      p.backgroundBlendMode &&
      css`
        background-blend-mode: ${p.backgroundBlendMode};
      `}
    background-color: ${p => p.bgColor || 'transparent'};
    background-image: url('/media/large/interface/${p => p.image}');
    background-repeat: no-repeat;
    background-size: ${p => (p.contain ? 'contain' : 'cover')};
    position: relative;
    height: 100%;
    width: 100%;

    ${p =>
      p.overflow &&
      css`
        background-size: ${p.overflow};
        background-position: 50%;
      `}

    & > * {
      animation: ${keyframes`
        0% {
          opacity: 0;
        }
        100% {
          opacity 1;
        }
      `} 0.5s forwards;
    }
  `

const SpaceShip = () => {
  const { day } = usePageState()
  return (
    <>
      <SpaceShipContainer>
        <SpaceShipImage />
        <SpaceShipText>{day.title}</SpaceShipText>
      </SpaceShipContainer>
    </>
  )
}

export const CollectedStars = () => {
  const { pages, pageIndex } = usePageState()
  const stars = []

  for (let i = 0; i < DEFAULT_NUMBER_OF_STARS; i++) {
    stars.push(String(i))
  }

  for (let i = 0; i < pages.length && i < pageIndex; i++) {
    if (pages[i].options.star) {
      stars.push(pages[i].id)
    }
  }

  return (
    <StarsContainer>
      {stars.map((pageId, i) => (
        <StarImg key={pageId} src={`/media/large/interface/star.svg`} alt="" starIndex={i % 3} />
      ))}
    </StarsContainer>
  )
}

const StarsContainer = styled.div`
  position: absolute;
  top: calc(28px * var(--scale));
  left: calc(200px * var(--scale));
`

const StarImg = styled.img`
  width: calc(39px * var(--scale));
  height: calc(37px * var(--scale));

  margin-right: calc(5px * var(--scale));

  ${p =>
    p.starIndex === 1 &&
    css`
      transform: rotate(32deg);
    `}

  ${p =>
    p.starIndex === 2 &&
    css`
      transform: rotate(50deg);
    `}
`

const SpaceShipContainer = styled.div`
  width: calc(195px * var(--scale));
  height: calc(85px * var(--scale));
  position: absolute;
  top: calc(10px * var(--scale));
  left: 0;
  z-index: 102;
`

const SpaceShipImage = styled.img.attrs({
  src: '/media/large/interface/spaceship.png',
  alt: '',
})`
  width: calc(195px * var(--scale));
  height: calc(85px * var(--scale));
  position: absolute;
  top: 0;
  left: 0;
`

const SpaceShipText = styled.div`
  position: absolute;
  top: calc(25px * var(--scale));
  left: calc(40px * var(--scale));
  width: calc(115px * var(--scale));
  height: calc(30px * var(--scale));
  font-size: calc(25px * var(--scale));
  font-family: 'Walter turncoat', sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StarContainer = styled.div`
  --width: calc(39px * var(--scale));
  --height: calc(37px * var(--scale));

  position: absolute;
  top: calc(70px * var(--scale));
  left: calc(50% - var(--width) / 2);
  z-index: 100;
  pointer-events: none;
`

const Star = styled.img.attrs({
  src: `/media/large/interface/star.svg`,
  alt: '',
})`
  display: block;
  width: var(--width);
  height: var(--height);
  transform-origin: 50% 50%;
  --rotation: 0deg;

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1s;

  ${p =>
    p.starIndex === 1 &&
    css`
      --rotation: 32deg;
    `}

  ${p =>
    p.starIndex === 2 &&
    css`
      --rotation: 50deg;
    `}
`
