import { Translate, Norwegian, English } from 'languageContext'
import styled, { keyframes } from 'styled-components/macro'
import storylineContext from 'Storyline/context'
import { useAutoCallback } from 'hooks.macro'
import { useConfig } from './SceneWrapper'
import { softThump } from 'sounds'
import useAudio from 'useAudio'
import { useStore } from 'Store'
import React from 'react'

export default ({ animateIn = true }) => {
  const { prevPage } = React.useContext(storylineContext)
  const playSoftThump = useAudio(softThump)
  const { audioEnabled } = useStore()
  const { buttonText } = useConfig()

  const playSound = useAutoCallback(() => {
    if (!audioEnabled) return
    playSoftThump()
  })

  return (
    <Button
      onClick={() => {
        playSound()
        prevPage()
      }}
      animateIn={animateIn}
    >
      <Scaler>
        <ArrowImage />
        <Text>
          {buttonText && (
            <>
              <Translate>
                <Norwegian>tilbake</Norwegian>
                <English>back</English>
              </Translate>
            </>
          )}
        </Text>
      </Scaler>
    </Button>
  )
}

const ArrowImage = styled.img.attrs({
  src: '/media/large/interface/back-button.png',
})`
  width: calc(37px * var(--scale));
  height: calc(43px * var(--scale));
`

const Button = styled.button.attrs({
  title: 'jilbake',
  type: 'button',
})`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  font-size: calc(30px * var(--scale));
  font-family: 'Walter turncoat', sans-serif;

  animation: ${keyframes`
    0% {
      transform: translateX(calc(-30px * var(--scale)));
    }
    100% {
      transform: translateX(0);
    }
  `} ${p => (p.animateIn ? '0.5s' : '0s')} forwards;

  :focus {
    outline: none;
  }
`

const Text = styled.span`
  transform: translateY(calc(-4px * var(--scale)));
`

const Scaler = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.4s, filter 0.4s;
  transform: translate3d(0, 0, 0);
  ${Button}:focus & {
    transform: translate3d(0, 0, 0) scale(var(--focus-scale));
    filter: var(--focus-filter);
  }
`
