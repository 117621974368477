import { DraggableContainer, Draggable, DropTarget, ProvideIsMoving } from 'common/drag-n-drop'
import { useAutoMemo, useAutoCallback, useAutoEffect } from 'hooks.macro'
import { Translate, Norwegian, English } from 'languageContext'
import styled, { keyframes, css } from 'styled-components/macro'
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom'
import SceneWrapper from 'common/SceneWrapper'
import NextButton from 'common/NextButton'
import BackButton from 'common/BackButton'
import React from 'react'

export default ({ options: { bg, title, labels, statements }, storeAnswer, answer }) => {
  const [isPlacing, setIsPlacing] = React.useState(null)
  statements = useAutoMemo(statements.slice().reverse())

  const onDrop = useAutoCallback((choice, statement) => {
    storeAnswer(prev => ({
      ...prev,
      [statement]: choice,
    }))
  })

  const includedStatements = useAutoMemo(() => {
    return statements.slice().filter(statement => !answer?.[statement])
  })

  const reply = useAutoCallback(choice => {
    const statement = includedStatements[includedStatements.length - 1]
    setIsPlacing({ choice, statement })
  })

  useAutoEffect(() => {
    if (!isPlacing) return

    const { choice, statement } = isPlacing

    const timeout = setTimeout(() => {
      batchedUpdates(() => {
        onDrop(choice, statement)
        setIsPlacing(null)
      })
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  })

  return (
    <SceneWrapper bg={bg}>
      <Backdrop />
      <DraggableContainer onDrop={onDrop} dropDuration={300}>
        <>
          <div />
          <Modal isPlacing={Boolean(isPlacing)}>
            <Title fontSize={title.fontSize}>{title.text}</Title>
            <Explanation>
              <Translate>
                <Norwegian>Dra teksten ned til et svar</Norwegian>
                <English>Drag the text down to an answer.</English>
              </Translate>
            </Explanation>
            <Statements count={statements.length}>
              {includedStatements.map((statement, i) => (
                <Draggable
                  key={statement}
                  value={statement}
                  disabled={i !== includedStatements.length - 1}
                  scaleDownOnDrag
                >
                  <Statement
                    offset={i}
                    moveTo={
                      isPlacing && isPlacing.statement === statement ? isPlacing.choice : null
                    }
                  >
                    {statement}
                  </Statement>
                </Draggable>
              ))}
            </Statements>
            <ProvideIsMoving>
              {isMoving => (
                <Answers complete={!includedStatements.length}>
                  <AnswerContainer>
                    <DropTarget name="yes">
                      <Answer
                        color="#00db19"
                        hoverColor={'#00db1955'}
                        isMoving={isMoving}
                        onClick={() => reply('yes')}
                        tabIndex={!includedStatements.length ? -1 : 0}
                      >
                        {labels?.yes || (
                          <>
                            <Translate>
                              <Norwegian>
                                {' '}
                                Ja
                                <small>det passer for oss</small>
                              </Norwegian>
                              <English>
                                Yes
                                <small>that describes us</small>
                              </English>
                            </Translate>
                          </>
                        )}
                      </Answer>
                    </DropTarget>
                  </AnswerContainer>
                  <AnswerContainer>
                    <DropTarget name="unsure">
                      <Answer
                        color="#939393"
                        hoverColor={'#93939355'}
                        isMoving={isMoving}
                        onClick={() => reply('unsure')}
                        tabIndex={!includedStatements.length ? -1 : 0}
                      >
                        {labels?.uncertain || (
                          <Translate>
                            <Norwegian>Vet ikke</Norwegian>
                            <English>Don’t know</English>
                          </Translate>
                        )}
                      </Answer>
                    </DropTarget>
                  </AnswerContainer>
                  <AnswerContainer>
                    <DropTarget name="no">
                      <Answer
                        color="#c30000"
                        hoverColor={'#c3000055'}
                        isMoving={isMoving}
                        onClick={() => reply('no')}
                        tabIndex={!includedStatements.length ? -1 : 0}
                      >
                        {labels?.no || (
                          <Translate>
                            <Norwegian>
                              Nei
                              <small>det passer ikke for oss</small>
                            </Norwegian>
                            <English>
                              No
                              <small>that does not describe us</small>
                            </English>
                          </Translate>
                        )}
                      </Answer>
                    </DropTarget>
                  </AnswerContainer>
                </Answers>
              )}
            </ProvideIsMoving>
            <BottomRow>
              <BackButton />
              <NextButton disabled={includedStatements.length > 0} />
            </BottomRow>
          </Modal>
        </>
      </DraggableContainer>
    </SceneWrapper>
  )
}

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Backdrop = styled.div`
  position: absolute;
  background-color: rgba(51, 118, 169, 0.4);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1s forwards;
`

const Modal = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  padding: calc(60px * var(--scale));
  position: absolute;
  bottom: calc(40px * var(--scale));
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(40px * var(--scale));
  border-radius: calc(40px * var(--scale));

  animation: ${keyframes`
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  `} 0.5s forwards;

  ${p =>
    p.isPlacing &&
    css`
      pointer-events: none;
    `}
`

const Title = styled.h2`
  font-size: calc(${p => p.fontSize}px * var(--scale));
  text-align: center;
  margin-top: calc(30px * var(--scale));
  margin-bottom: calc(20px * var(--scale));
`

const Statements = styled.div`
  --width: calc(600px * var(--scale));
  --height: calc(180px * var(--scale));
  --offset: calc(10px * var(--scale));
  position: relative;
  width: calc(var(--width) + var(--offset) * ${p => p.count});
  height: calc(var(--height) + var(--offset) * ${p => p.count});
  margin: 0 auto;
  margin-top: calc(30px * var(--scale));
  margin-bottom: calc(20px * var(--scale));
`

const Statement = styled.div`
  position: absolute;
  top: calc(var(--offset) * ${p => p.offset});
  left: calc(var(--offset) * ${p => p.offset});
  width: var(--width);
  height: var(--height);
  background-color: #e8e3d7;
  border: calc(1px * var(--scale)) solid black;
  border-radius: 10px;
  font-size: calc(30px * var(--scale));
  padding: calc(20px * var(--scale)) calc(100px * var(--scale)) calc(30px * var(--scale));
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  animation: 0.5s ${keyframes`
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  `};

  ${p => {
    switch (p.moveTo) {
      case 'yes':
        return css`
          animation: 1000ms ${keyframes`
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-250px * var(--scale))) translateY(calc(205px * var(--scale))) scale(0.5);
          }
        `} forwards;
        `
      case 'unsure':
        return css`
          animation: 1000ms ${keyframes`
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateY(calc(205px * var(--scale))) scale(0.5);
          }
        `} forwards;
        `
      case 'no':
        return css`
          animation: 1000ms ${keyframes`
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(250px * var(--scale))) translateY(calc(205px * var(--scale))) scale(0.5);
          }
        `} forwards;
        `
      default:
        return null
    }
  }}
`

const Answers = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;

  ${p =>
    p.complete &&
    css`
      & ${Answer} {
        color: #ccc;
        border-color: #ccc;
      }
    `}
`

const AnswerContainer = styled.div`
  padding: 10px;
  flex-basis: 100%;
  display: flex;
`

const Answer = styled.button.attrs({
  type: 'button',
})`
  background-color: white;
  border: calc(2px * var(--scale)) solid ${p => p.color};
  padding: calc(20px * var(--scale));
  flex: 1 1 0px;
  font-size: calc(35px * var(--scale));
  border-radius: calc(10px * var(--scale));
  color: ${p => p.color};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(130px * var(--scale));
  & small {
    display: block;
  }

  :focus {
    background-color: ${p => p.hoverColor} !important;
    outline: none;
  }

  ${p =>
    p.isMoving &&
    css`
      :hover {
        background-color: ${p => p.hoverColor};
      }
      ${p.draggableHover &&
      css`
        background-color: ${p => p.hoverColor};
      `}
    `}
`

const Explanation = styled.div`
  font-size: calc(25px * var(--scale));
  text-align: center;
  color: #959595;
`
