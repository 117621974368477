export default `
# Chapter
$ bg = barn-klasserom
title = Mandag
subtitle = På skolen

# Dialogue
[Noah left 220 neutral]
[Limal right 500 neutral]
[Luna right 780 neutral flip]
Noah: Hei, Limal!

[Limal surprised]
Limal: Hvorfor var du ikke i bursdagen min i går?
Limal: Så du ikke invitasjonen?

[Noah confused]
Noah: Hææh? Hadde du selskap?

[Limal confused]
Limal: Mamma sendte ut invitasjon på Spacebook.

[Luna confused]
Luna: Alle i klassen fikk.

[Noah sad]
Noah: Jeg får ikke være på Spacebook.
Noah: Mamma har heller ikke konto.

[Limal sad]
Limal: Åh, så teit!

# Discuss
star = true
fontSize = 35
lines(json) = [
'Noah er ikke på den samme appen som de andre barna og føler seg glemt. \\
Noen barn får ikke lov til å være på internett like tidlig som andre barn.',
  '',
'Hvordan skal alle få med seg invitasjoner og beskjeder, så de ikke føler \\
seg glemt, sånn som Noah?',
]

# Chapter
$ bg = noah-soverom
title = Hjemme hos Noah

# Dialogue
[Noah sad left 250]
[Sara surprised right 680]
Sara: Er det noe galt? Er du lei deg?

[Noah flip]
Noah: Jeg fikk ikke vite om Limals bursdag.

[Sara confused]
Sara: Ble du ikke invitert?
Sara: Han er jo bestevennen din.
Noah: De sendte invitasjonen på Spacebook.

[Sara sad]
Sara: Åh! Det var dumt.
Noah: Hvorfor kan ikke jeg få Spacebook?
Sara: Det er 13 års aldersgrense.
Sara: Jeg synes ikke det er bra for deg.
Noah: Men alle andre i klassen har!

[Sara neutral]
Sara: Det er foreldremøte på onsdag.
Sara: Da snakker jeg med de andre foreldrene.

[Sara happy]
Sara: Ingen skal gå glipp av bursdager!

# Choice
compact = true
star = true
title.text = Hvorfor har de fleste sosiale medier aldersgrense 13 år?
title.fontSize = 50
subtitle = Velg ett riktig alternativ
textWhenWrong = Ikke riktig

choices(json) = [
  { text: 'De lagrer personlig informasjon (personvern)' },
  { text: 'Fordi ting på nettet er vanskelig å fjerne', wrong: true },
  { text: 'Barn bør ikke sitte så mye foran skjermen', wrong: true },
  { text: 'Det er vanskelig å bruke', wrong: true },
  { text: 'For å beskytte barn mot fremmede', wrong: true },
  { text: 'Innholdet passer ikke for barn i ulik alder', wrong: true },
]

tipsrakett.title = Hvorfor er det aldersgrense på sosiale medier?
tipsrakett.lines(json) = [
  'Det er to typer aldersgrenser på sosiale medier:',
  '- Aldersgrense som gjelder innsamling av personopplysninger',
  '- Aldersgrense på innhold',
  'De fleste sosiale medier har 13-årsgrense for å opprette en profil. Aldersgrensen er satt for å beskytte barns personopplysninger. Før barn er 13 år, må foreldre godkjenne at barnet oppretter en profil og deler personopplysninger. Sosiale medier har også egne aldersanbefalinger som gjelder innholdet eller hvem tjenesten er egnet for. Ulike «nettbutikker» (som App Store og Windows Store) bruker forskjellige systemer for klassifisering, og dette gjør at aldersgrensen på innhold kan variere.'
]
`
