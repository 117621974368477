import transform from 'transformConfig'

import sunday from './sunday.js'
import monday from './monday.js'
import tuesday from './tuesday.js'
import wednesday from './wednesday.js'
import thursday from './thursday.js'
import friday from './friday.js'
import saturday from './saturday.js'

export const name = 'Luna'

// prettier-ignore
export const days = [
  {
    title: 'Sunday',
    subtitle: 'Luna is at Limal’s birthday party',
    pages: transform('luna_sunday', sunday),
  },
  {
    title: 'Monday',
    subtitle: 'Luna and her dad, Edan, talk about uncomfortable pictures on Spacebook',
    pages: transform('luna_monday', monday),
  },
  {
    title: 'Tuesday',
    subtitle: 'Luna discovers that not everybody is nice on Ding',
    pages: transform('luna_tuesday', tuesday),
  },
  {
    title: 'Wednesday',
    subtitle: 'Luna wants to play Moon Sword even if she is too young',
    pages: transform('luna_wednesday', wednesday),
  },
  {
    title: 'Thursday',
    subtitle: 'Luna and Timo talk to their mum Galea about screen time',
    pages: transform('luna_thursday', thursday),
  },
  {
    title: 'Friday',
    subtitle: 'Luna has password problems',
    pages: transform('luna_friday', friday),
  },
  {
    title: 'Saturday',
    subtitle: 'Luna and Bix have fun at the fairground',
    pages: transform('luna_saturday', saturday),
  },
]
