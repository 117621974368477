export default function trace(source, index) {
  if (!source) return '<anonymous>'
  const lines = source.split('\n')
  let pos = 0

  for (const line of lines) {
    for (let i = 0; i < line.length; i++) {
      if (pos === index) return line + '\n' + ' '.repeat(i) + '^'
      pos += 1
    }
    if (pos === index) return line + '\n^'
    pos += 1
  }

  return index
}
