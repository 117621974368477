export default `
bg = generisk-gang

# Chapter
title = Sunday
subtitle = At the shopping centre

# Dialogue
[Noah neutral left 250]
[Sara neutral right 680]
Noah: What are we going to buy, Mum?

[Sara happy]
Sara: You need a new coat, Noah.

[Mira happy right 800]
[Sara 515]
Mira: Make sure you get a cool one!

# ChooseImage
title(json) = { text: 'Choose a coat for Noah', fontSize: 30 }
help = Select a coat,then press the NEXT button.
choices(json) = [
  {
    image: '/media/noah-jackets/red.png',
  },
  {
    image: '/media/noah-jackets/blue.png',
  },
  {
    image: '/media/noah-jackets/army.png',
  },
  {
    image: '/media/noah-jackets/purple.png',
  },
  {
    image: '/media/noah-jackets/yellow.png',
  },
  {
    image: '/media/noah-jackets/refleks.png',
  },
]

# Dialogue
[Noah neutral left 250]
[Sara neutral right 515]
[Mira surprised right 800]
Mira: Woah, that’s cool!

[Noah happy]
[Sara happy]
Noah: This was my favourite.
Sara: Let’s go home.
`
