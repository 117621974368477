export default `
# Chapter
$ bg = barn-klasserom
title = Onsdag
subtitle = På skolen

# Dialogue
[Noah left neutral flip 240]
[Limal right neutral 680]
Noah: Hei Limal! Har du hørt at det kommer...
Noah: ...en stjernestorm i morgen?

[Limal happy]
Limal: Kult, da slipper vi å gå på skolen!

[Noah happy]
Noah: Vi kan spille MåneSverdet hele dagen!
Limal: Yes!

# Chapter
$ bg = menneske-stue
title = Hjemme hos Noah

# Dialogue
[Noah left neutral flip 210]
[Mira right neutral 720]
Mira: Hei! Jeg skal sitte barnevakt for deg.

[Mira happy]
Mira: Vil du se film?

[Noah happy]
Noah: Kan vi se Super-Dino?
Mira: Nei, den er for kjedelig.
Mira: Vi ser Stjerne-Krig.

[Noah surprised]
Noah: Så kult!

[Mira confused]
Mira: Den har 12 års aldersgrense...

[Mira happy]
Mira: ...men du er tøff, så det går nok bra.

# Chapter
title = To timer senere

# Dialogue
[Noah sad flip 460]
Noah: Filmen var ganske skummel.
Noah: Jeg har ikke lyst til å sove alene...
Noah: ...men tør ikke si noe til Mira.

# Choice
title.fontSize = 45
title.text = Hvorfor har filmer aldersgrense?
subtitle = Det er flere svar som kan være riktige, velg ett
choices(json) = [
  { text: 'De i filmen snakker stygt' },
  { text: 'Mange sloss og skyter på hverandre' },
  { text: 'Det skjer skumle ting' },
  { text: 'Det skjer morsomme ting', wrong: true },
  { text: 'De i filmen er nakne' },
  { text: 'Filmen er kjedelig for barn', wrong: true },
]
tipsrakett.title = Hvorfor er det aldersgrense på filmer?
tipsrakett.lines(json) = [
  'Filmer har aldersgrenser for å beskytte barn fra innhold som kan være skadelig å se. At noe er skadelig betyr at barn kan blir engstelig eller skremt av det de ser, eller at innholdet gir ubehag, virker forstyrrende eller støtende. Aldersgrensen sier altså ikke noe om filmen passer for barn, så det kan være lurt å sjekke filmens målgruppe i tillegg. Barn har forskjellig tålegrense og er ulike når det gjelder modenhet. Dette er det viktig å ta hensyn til når man skal velge film.',
'Hvis du vil vite mer om aldersgrenser, eller lurer på hvilken aldersgrense en kinofilm har, kan du gå til <a href="https://medietilsynet.no/filmdatabasen">Medietilsynets nettsider eller filmdatabase.</a>',
]

# Discuss
star = true
paragraphs(json) = [
  'Hva gjør du hvis du har sett noe som var litt for skummelt? Og hva synes mamma eller pappa du burde gjøre?',
]
`
