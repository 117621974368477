import { useAutoMemo } from 'hooks.macro'
import { useAutoEffect } from 'hooks.macro'
import React from 'react'
import AnimatedPath from 'diggle-react-animated-path'
import uuid from 'uuid/v4'
import {
  teethStages,
  leftEyeStages,
  rightEyeStages,
  mouthOuterStages,
  mouthInnerStages,
  leftEyeBrowStages,
  rightEyeBrowStages,
} from './paths'

const Emoji = ({ value: valueFromProps }) => {
  valueFromProps = Math.min(valueFromProps, 1)

  const [value, setValue] = React.useState(valueFromProps)

  useAutoEffect(() => {
    const af = requestAnimationFrame(() => {
      setValue(valueFromProps)
    })

    return () => {
      cancelAnimationFrame(af)
    }
  })

  const id = useAutoMemo(() => 'emoji-' + uuid())

  return useAutoMemo(() => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0  100 100" id={id}>
      {/* Important to have the same order in rendering because of overriding*/}
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="50"
          y1="-43.73"
          x2="50"
          y2="100.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.36" stopColor="#eb4658" />
          <stop offset="0.44" stopColor="#ec4e53" />
          <stop offset="0.58" stopColor="#ef6445" />
          <stop offset="0.74" stopColor="#f5872f" />
          <stop offset="0.93" stopColor="#fcb810" />
          <stop offset="1" stopColor="#ffcc03" />
        </linearGradient>
      </defs>
      {/* Face yellow */}
      <path
        fill="#ffcc03"
        d="M 5.77, 50 a 44.23,44.23 0 1,0 88.46, 0 a 44.23,44.23 0 1, 0 -88.46,0"
      />
      {/*
        <AnimatedPath step={value} stages={mouthStages} />
        <AnimatedPath step={value} stages={dropStages} />
        <AnimatedPath step={value} stages={leftEyeStages} />
        <AnimatedPath step={value} stages={rightEyeStages} />
        */}

      {/* Override of original face, color gradient for angry */}
      <path
        style={{ opacity: 1 - value * 5 }}
        fill="url(#linear-gradient)"
        d="M 5.77, 50 a 44.23,44.23 0 1,0 88.46, 0 a 44.23,44.23 0 1, 0 -88.46,0"
      />
      {/* face-shade */}
      <path
        d="M49,8.05A40.35,40.35,0,0,0,17,73,40.35,40.35,0,0,1,84,28.29,40.35,40.35,0,0,0,49,8.05Z"
        fill="#ffe671"
        opacity="0.3"
        style={{ mixBlendMode: 'lighten' }}
      />
      <AnimatedPath step={value} stages={leftEyeBrowStages} />
      <AnimatedPath step={value} stages={rightEyeBrowStages} />
      <AnimatedPath step={value} stages={leftEyeStages} />
      <AnimatedPath step={value} stages={rightEyeStages} />
      <g id={`${id}-teethAndToungue`}>
        {value < 0.78 && (
          <style>
            {`#${id}-teethAndToungue path {
              opacity: 0;
            }`}
          </style>
        )}
        <AnimatedPath step={value} stages={mouthInnerStages} />
        <AnimatedPath step={value} stages={teethStages} />
      </g>
      <AnimatedPath step={value} stages={mouthOuterStages} />
    </svg>
  ))
}

export default Emoji
