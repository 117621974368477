import styled, { keyframes, css } from 'styled-components/macro'
import { Translate, Norwegian, English } from 'languageContext'
import SceneWrapper from 'common/SceneWrapper'
import Tipsrakett from 'common/Tipsrakett'
import NextButton from 'common/NextButton'
import BackButton from 'common/BackButton'
import React from 'react'

export default ({
  options: {
    bg,
    fontSize,
    paragraphs,
    lines,
    tipsrakett,
    title,
    lineHeight = 1,
    alignLeft = false,
  },
  storeAnswer,
  answer,
}) => {
  return (
    <SceneWrapper bg={bg}>
      <Backdrop />
      <Modal>
        {title && (
          <Text fontSize={title.fontSize} alignLeft={alignLeft}>
            {' '}
            {title.text}{' '}
          </Text>
        )}
        {paragraphs &&
          paragraphs.map((text, i) => (
            <Text fontSize={fontSize} key={i} alignLeft={alignLeft}>
              {text}
            </Text>
          ))}
        {lines && (
          <Text fontSize={fontSize} lineHeight={lineHeight} alignLeft={alignLeft}>
            {lines.join('\n')}
          </Text>
        )}
        <Circle>
          <CircleText>
            <Translate>
              <Norwegian>Snakk sammen</Norwegian>
              <English>Discuss</English>
            </Translate>
          </CircleText>
          <img alt="" src="/media/large/interface/help.svg" />
        </Circle>
        <BottomRow>
          <BackButton />
          {tipsrakett && <Tipsrakett />}
          <NextButton />
        </BottomRow>
      </Modal>
    </SceneWrapper>
  )
}

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Backdrop = styled.div`
  position: absolute;
  background-color: rgba(51, 118, 169, 0.4);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1s forwards;
`

const Modal = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  padding: calc(60px * var(--scale)) calc(180px * var(--scale));
  position: absolute;
  bottom: calc(40px * var(--scale));
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(40px * var(--scale));
  border-radius: calc(40px * var(--scale));

  animation: ${keyframes`
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  `} 0.5s forwards;
`

const Text = styled.div`
  font-size: calc(${p => p.fontSize || 50}px * var(--scale));
  text-align: ${p => (p.alignLeft ? 'left' : 'center')};
  margin-top: calc(60px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
  white-space: pre-line;
  line-height: ${p => p.lineHeight || 1};

  ${p =>
    p.line &&
    css`
      margin-top: 0;
      margin-bottom: 0;
    `}
`

const Circle = styled.div`
  --image-diameter: calc(80px * var(--scale));
  --diameter: calc(180px * var(--scale));

  text-align: center;
  margin-top: calc(60px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
  white-space: pre-line;
  background-color: #0a5b99;
  height: var(--diameter);
  width: var(--diameter);
  border-radius: var(--diameter);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & img {
    width: var(--image-diameter);
    height: var(--image-diameter);
  }

  margin-left: auto;
  margin-right: auto;
`

const CircleText = styled.div`
  font-size: calc(26px * var(--scale));
  font-weight: bold;
  color: white;
  line-height: 0.8;
  margin-bottom: calc(10px * var(--scale));
`
