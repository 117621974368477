import styled, { keyframes } from 'styled-components/macro'
import React from 'react'

//ELECTRON
const moveY = keyframes`
 0%  { transform: translateY(0px) translate(-50%, 50%); }
 50%  { transform: translateY(-78px) translate(-50%, 50%); }
`

const moveX = keyframes`
 0%  { transform: translateX(0px); }
 50%  { transform: translateX(78px); }
`

//these are magic numbers
const animTime = 1.4
const delay = animTime * 140
const timeFn = 'cubic-bezier(0.42, 0, 0.58, 1) infinite both'

const CircleBox = styled.div`
  animation: ${moveX} ${animTime}s ${delay}ms ${timeFn};
  position: absolute;
  bottom: 0px;
  z-index: 5;
  left: 0px;
  transform: translateX(0px);
`
const Circle = styled.div`
  animation: ${moveY} ${animTime}s ${timeFn};
  background: ${p => p.color || 'black'};
  transform: translateY(0px) translate(-50%, 50%);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  z-index: 5;
`

const Electron = ({ color }) => (
  <CircleBox>
    <Circle color={color} />
  </CircleBox>
)

//END ELECTRON

//PATH
const PathInner = styled.div`
  border: 2px solid ${p => (p.transparent ? 'transparent' : 'darkgrey')};
  transform: ${p => `rotate(-45deg) rotate(${p.deg || 0}deg)`};
  transform-origin: center;
  border-radius: 200% 25%;
  box-sizing: border-box;
  position: relative;
  height: 80px;
  width: 80px;
`

const PathOuter = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`

const Path = ({ deg, children, transparent }: { deg: number, children?: any, transparent?: boolean }) => (
  <PathOuter>
    <PathInner transparent={transparent} deg={deg}>
      {children && children}
    </PathInner>
  </PathOuter>
)
//PATH END

const AtomContainer = styled.div`
  position: relative;
  height: 120px;
  margin: 50px;
  width: 120px;
`
const Container = styled.div`
  flex-direction: column;
  display: flex;
`
const Text = styled.div`
  letter-spacing: 0.8px;
  font-weight: 800;
  color: #434a54;
  font-size: 22px;
  margin: 0 auto;
  display: flex;
`
const Atom = () => (
  <Container>
    <Text>LOADING</Text>
    <AtomContainer>
      <Path deg={-120} />
      <Path deg={0} />
      <Path deg={120} />
      <Path transparent deg={-120}>
        <Electron color={'hotpink'} />
      </Path>
      <Path transparent deg={0}>
        <Electron color={'skyblue'} />
      </Path>
      <Path transparent deg={120}>
        <Electron color={'green'} />
      </Path>
    </AtomContainer>
  </Container>
)

export default Atom
