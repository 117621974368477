import { useAutoEffect, useAutoMemo } from 'hooks.macro'
import usePageState from 'Storyline/usePageState'
import { softClick } from 'sounds'
import { useStore } from 'Store'
import useAudio from 'useAudio'
import React from 'react'

const isSafari = (() => {
  try {
    return /safari/i.test(window.navigator.userAgent)
  } catch (e) {}
  return false
})()

export default function TypedText({ children, delay, instant, onStart, onEnd }) {
  const { instantTyping } = usePageState()
  const { audioEnabled } = useStore()
  const playSoftClick = useAudio(softClick, { precache: 10 })
  const [shouldDelay, setShouldDelay] = React.useState(true)

  if (instantTyping) instant = true

  if (typeof children !== 'string') {
    if (process.env.NODE_ENV === 'development') {
      setTimeout(() => {
        // throw Error('TypedText expects children to be a string')
      })
    }
    children = ''
  }

  const letters = useAutoMemo(() => {
    const letters = []
    let underline = false

    for (let i = 0; i < children.length; i++) {
      if (children[i] === '_') {
        underline = !underline
      } else {
        letters.push({
          letter: children[i],
          underline,
        })
      }
    }

    return letters
  })

  const [index, setIndex] = React.useState(0)

  useAutoEffect(() => {
    if (shouldDelay) return

    let interval, timeout
    let i = 0

    if (instant) {
      setIndex(letters.length)
      if (typeof onEnd === 'function') {
        onEnd()
      }
      return
    }

    setIndex(0)

    timeout = setTimeout(() => {
      if (typeof onStart === 'function') {
        onStart()
      }

      interval = setInterval(() => {
        if (i < letters.length) {
          do {
            setIndex(++i)
          } while (i < letters.length && letters[i].letter === ' ')
          if (!instantTyping && audioEnabled && i % 3 === 0) {
            if (!isSafari) {
              playSoftClick()
            }
          }
        } else {
          if (typeof onEnd === 'function') {
            onEnd()
          }
          clearInterval(interval)
        }
      }, 50)
    }, delay)

    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  })

  useAutoEffect(() => {
    const timeout = setTimeout(() => {
      setShouldDelay(false)
    }, 700)

    return () => {
      clearTimeout(timeout)
    }
  })

  return (
    <span data-debug="letters">
      {letters.slice(0, index).map((props, i) => (
        <Letter key={i} {...props} />
      ))}
      <span style={{ opacity: 0 }}>
        {letters.slice(index).map((props, i) => (
          <Letter key={i} {...props} />
        ))}
      </span>
    </span>
  )
}

const Letter = ({ letter, underline }) => {
  return (
    <span
      style={{
        textDecoration: underline ? 'underline' : null,
      }}
    >
      {letter}
    </span>
  )
}
