import transformConfig from 'transformConfig'
import sunday from './sunday'
import monday from './monday'
import tuesday from './tuesday'
import wednesday from './wednesday'
import thursday from './thursday'
import friday from './friday'
import saturday from './saturday'

export const name = 'Noah'

// prettier-ignore
export const days = [
  {
    title: 'Søndag',
    subtitle: 'Noah er ute og handler med Sara',
    pages: transformConfig('noah_sunday', sunday),
  },
  {
    title: 'Mandag',
    subtitle: 'Noah har det vanskelig med Spacebook',
    pages: transformConfig('noah_monday', monday),
  },
  {
    title: 'Tirsdag',
    subtitle: 'Noah finner ut at ikke alle er like gode på å lage passord',
    pages: transformConfig('noah_tuesday', tuesday),
  },
  {
    title: 'Onsdag',
    subtitle: 'Noah ser på film sammen med Mira',
    pages: transformConfig('noah_wednesday', wednesday),
  },
  {
    title: 'Torsdag',
    subtitle: 'Noah og Limal åpner lootboks',
    pages: transformConfig('noah_thursday', thursday),
  },
  {
    title: 'Fredag',
    subtitle: 'Noah og Sara prater om skjermtid',
    pages: transformConfig('noah_friday', friday),
  },
  {
    title: 'Lørdag',
    subtitle: 'Noah og Limal er i MåneSverdet-butikken',
    pages: transformConfig('noah_saturday', saturday),
  },
]
