export default `
# Chapter
title = Fredag
subtitle = På skolen
$ bg = barn-klasserom

# Dialogue
[Luna happy left 240]
[Bix neutral right 680]
$ bg = barn-klasserom

Luna: Hei Bix. Blir du med på tivoli i morgen?

[Bix happy]

Bix: Ja! Jeg må bare spørre først.
Luna: Det blir kjempegøy!

# Chapter
title.fontSize = 40
title.text = Hjemme hos Luna
$ bg = luna-soverom

# Dialogue
$ bg = luna-soverom

[Luna happy 450]
Luna: Jeg sjekker på laptopen når tivoliet åpner.

[Luna confused]
Luna: Hmmm... Hva var passordet igjen?

# Dialogue
$ bg = luna-laptop
passwordPlaceholder = Skriv inn passordet ditt
Luna: Jeg lagde et bra, og vanskelig, passord.

# DialogueChoice
$ bg = luna-laptop
title.text =
choices(list) = Luna100!PannekakerErBest!, Lunas_Supervanskelige_Passord, BraVanskeligP@assord!, 1ng3n-gj3tt3r-pa55ord3t

# Dialogue
$ bg = luna-soverom
[Luna surprised left 450]

Luna: Å nei! Hva gjør jeg nå?

# Text
paragraphs(json) = [
  "Luna husker ikke passordet sitt!\\nNoen deler passordet med andre så det blir lettere å huske.",
  "",
  "Men da kan andre kanskje se ting du ikke vil dele."
]

# Choice
star = true
title.text = Er det lurt å dele passord?
title.fontSize = 45
choices(json) = [
  { text: 'Aldri' },
  { text: 'Ja, med familien' },
  { text: 'Ja, med venner', wrong: true },
  { text: 'Ja, for noen ting, men ikke for alt' },
  { text: 'Ja, med foreldre til man er stor nok til å huske selv' },
  { text: 'Bare hvis alle deler med hverandre', wrong: true },
]
`
