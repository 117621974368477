export default `
# Chapter
$ bg = generisk-gang
title = Søndag
subtitle = På bursdagsfest

# Dialogue
[Luna neutral left 300]
[Limal happy right 650]
Limal: Velkommen til bursdagen min.
Luna: Gratulerer med dagen Limal!
Limal: Vil du ha noe å spise?

# ChooseImage
title.text = Hva skal Luna spise?
title.fontSize = 50
help = Trykk på en av tingene og så neste-knappen
choices(json) = [ 
  {
    text: 'Sjokoladekake',
    image: '/media/luna-food/chocolate.png',
  },
  {
    text: 'Bløtkake',
    image: '/media/luna-food/kake.png',
  },
  {
    text: 'Taco',
    image: '/media/luna-food/taco.png',
  },
  {
    text: 'Pizza',
    image: '/media/luna-food/pizza.png',
  },
  {
    text: 'Pølse i brød',
    image: '/media/luna-food/hotdog.png',
  },
  {
    text: 'Potetgull',
    image: '/media/luna-food/potetgull.png',
  },
]

# Dialogue
[Luna neutral left 250]
[Edan neutral right 700]
Edan: Luna, nå er det sent. Vi må dra hjem.
Luna: Ok da, pappa. Ha det Limal!

[Edan 500]
[Limal 850 right neutral]
Limal: Ha det! Ser deg på skolen.
`
