import isUpperCase from './isUpperCase.js'
import trace from './trace.js'

export default function parse(tokens) {
  let prevNameInConversation = null
  const persistentOptions = {}
  const result = []

  let prev = null
  let i = 0

  while (i < tokens.length) {
    if (prev === i) throw Error('infinite loop')
    prev = i
    const token = tokens[i]

    if (!token)
      throw Error('Invalid token at ' + i + '/' + tokens.length + ': ' + JSON.stringify(token))

    switch (token.type) {
      case 'template':
        parseTemplate()
        break
      case 'option':
        assignToOptions(token, persistentOptions)
        i += 1
        break
      default:
        throw Error(
          `Unexpected token of type "${token.type}"\n${trace(token.meta.source, token.meta.index)}`,
        )
    }
  }

  return result

  function parseTemplate() {
    const templateType = tokens[i].name

    let current = {
      type: templateType,
      options: {
        ...persistentOptions,
      },
    }

    result.push(current)

    for (let j = i + 1; j < tokens.length; j++) {
      const token = tokens[j]

      if (!token) {
        i = j
        return
      }

      if (token.type === 'template') {
        i = j
        return
      }

      if (token.type === 'character') {
        if (current.options.conversation) {
          current = JSON.parse(JSON.stringify(current))
          delete current.options.conversation
          result.push(current)
        }

        const update = {}
        for (const instruction of token.config) {
          if (isUpperCase(instruction[0])) {
            update.name = instruction
          } else if (instruction === 'flip') {
            update.flip = true
          } else if (instruction === 'tilt') {
            update.tilt = true
          } else if (/^\d+$/.test(instruction)) {
            update.x = parseInt(instruction, 10)
          } else if (instruction === 'left' || instruction === 'right') {
            update.from = instruction
          } else if (instruction.includes('=')) {
            const index = instruction.indexOf('=')
            const key = instruction.slice(0, index).trim()
            let val = instruction.slice(index + 1)
            if (/^\d+$/.test(val)) {
              val = parseInt(val, 10)
            } else if (val === 'true') {
              val = true
            } else if (val === 'false') {
              val = false
            }
            update[key] = val
          } else {
            update.mood = instruction
          }
        }
        if (!update.name) throw Error('A character update must contain a name')
        current.options.characters = current.options.characters || []
        const prevIndex = current.options.characters.findIndex(
          x => x.name.toLowerCase() === update.name.toLowerCase(),
        )
        const prev = current.options.characters[prevIndex]

        if (prev)
          current.options.characters[prevIndex] = {
            ...prev,
            ...update,
          }
        else current.options.characters.push(update)

        for (const character of current.options.characters) {
          if (!character.name) {
            throw Error(
              `You must provide a name for all characters. Got ${JSON.stringify(
                character,
                null,
                2,
              )}`,
            )
          }
          if (!character.mood) {
            throw Error(
              `You must provide a mood for all characters. Got ${JSON.stringify(
                character,
                null,
                2,
              )}`,
            )
          }
        }

        continue
      }

      if (token.type === 'option') {
        if (current.options.conversation) {
          current = JSON.parse(JSON.stringify(current))
          delete current.options.conversation
          result.push(current)
        }

        if (token.persistent) {
          assignToOptions(token, persistentOptions)
        }

        assignToOptions(token, current.options)

        continue
      }

      if (token.type === 'dialogue') {
        if (current.options.conversation) {
          current = JSON.parse(JSON.stringify(current))
          result.push(current)
        }

        let { name, text } = token
        let hideName = false

        if (/^_+$/.test(name)) {
          name = prevNameInConversation || name
          hideName = true
        }

        prevNameInConversation = name

        current.options.conversation = [
          {
            hideName,
            name,
            text,
          },
        ]

        continue
      }

      throw Error(
        `Could not parse template config from token "${token.type}":\n${trace(
          token.meta.source,
          token.meta.index,
        )}`,
      )
    }

    i = tokens.length
  }

  function assignToOptions(token, options) {
    let { key, val } = token

    if (val === 'true') val = true
    if (val === 'false') val = false

    let reference = options
    const keys = key.split('.')

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i]
      const nextKey = keys[i + 1]
      if (!reference[key]) {
        if (/^[0-9]+$/.test(nextKey)) {
          reference[key] = []
        } else {
          reference[key] = {}
        }
      }
      reference = reference[key]
    }

    reference[keys[keys.length - 1]] = val
  }
}
