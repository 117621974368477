import transform from 'transformConfig'

import sunday from './sunday.js'
import monday from './monday.js'
import tuesday from './tuesday.js'
import wednesday from './wednesday.js'
import thursday from './thursday.js'
import friday from './friday.js'
import saturday from './saturday.js'

export const name = 'Luna'

// prettier-ignore
export const days = [
  {
    title: 'Søndag',
    subtitle: 'Luna er i Limals bursdagsfeiring',
    pages: transform('luna_sunday', sunday),
  },
  {
    title: 'Mandag',
    subtitle: 'Luna og faren Edan prater om ubehagelige bilder på Spacebook',
    pages: transform('luna_monday', monday),
  },
  {
    title: 'Tirsdag',
    subtitle: 'Luna finner ut at ikke alle er hyggelige på Ding',
    pages: transform('luna_tuesday', tuesday),
  },
  {
    title: 'Onsdag',
    subtitle: 'Luna har lyst til å spille MåneSverdet selv om hun er for liten',
    pages: transform('luna_wednesday', wednesday),
  },
  {
    title: 'Torsdag',
    subtitle: 'Luna og Timo prater med moren Galea om skjermtid',
    pages: transform('luna_thursday', thursday),
  },
  {
    title: 'Fredag',
    subtitle: 'Luna har problemer med passord',
    pages: transform('luna_friday', friday),
  },
  {
    title: 'Lørdag',
    subtitle: 'Luna og Bix har det gøy på tivoli',
    pages: transform('luna_saturday', saturday),
  },
]
