import styled, { keyframes, css } from 'styled-components/macro'
import { Translate, Norwegian, English } from 'languageContext'
import SceneWrapper from 'common/SceneWrapper'
import BackButton from 'common/BackButton'
import NextButton from 'common/NextButton'
import React from 'react'

export default ({ pages, pageIndex, options: { choices, bg }, answer, storeAnswer }) => {
  const isFirst = pages[pageIndex - 1]?.type === 'Chapter'
  const isComplete = Boolean(answer && answer.length >= 3)

  return (
    <SceneWrapper bg={bg} buttonText={false}>
      <Password filled={Boolean(answer)}>
        {answer?.[answer?.length - 1] || <>Skriv inn passordet ditt</>}
      </Password>
      {answer && (
        <ComputerError>
          {isComplete ? (
            <Translate>
              <Norwegian>For mange forsøk. Maskinen er låst.</Norwegian>
              <English>Too many attempts. The device is locked.</English>
            </Translate>
          ) : (
            <Translate>
              <Norwegian>Feil passord. Du har {3 - answer.length} forsøk igjen.</Norwegian>
              <English>Incorrect password, {3 - answer.length} attempts remaining.</English>
            </Translate>
          )}
        </ComputerError>
      )}
      <Options animateIn={isFirst}>
        {choices.map(choice => (
          <Option
            tabIndex={isComplete ? -1 : 0}
            key={choice}
            onClick={() => {
              if (isComplete) return

              if (!answer) {
                storeAnswer([choice])
              } else if (!answer.includes(choice)) {
                storeAnswer([...answer, choice])
              }
            }}
          >
            <InnerOption inactive={answer && answer.includes(choice)}>{choice}</InnerOption>
          </Option>
        ))}
      </Options>
      <BottomRow>
        <BackButton animateIn={isFirst} />
        <NextButton animateIn={isFirst} disabled={!isComplete} />
      </BottomRow>
    </SceneWrapper>
  )
}

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Option = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  background-color: transparent;
  display: block;
  width: 50%;
  padding: calc(5px * var(--scale));
  text-align: center;
`

const InnerOption = styled.div`
  background-color: #50ecd1;
  border: calc(2px * var(--scale)) solid black;
  padding: calc(15px * var(--scale)) calc(20px * var(--scale));
  font-size: calc(25px * var(--scale));
  border-radius: 4px;

  ${p =>
    p.inactive &&
    css`
      background-color: white;
      border-color: #c30000;
      color: #c30000;
    `}
`

const Options = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 0;
  padding-bottom: calc(10px * var(--scale));
  left: 50%;
  width: calc(79.5% - 6.4%);
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Rubik, sans-serif;

  animation: ${keyframes`
    0% {
      transform: translateY(100%) translateX(-50%);
      filter: blur(4px);
    }
    100% {
      transform: translateY(0) translateX(-50%);
      filter: blur(0);
    }
  `} ${p => (p.animateIn ? '1s' : '0s')} forwards;
`

const ComputerError = styled.div`
  color: #c30000;
  position: absolute;
  top: calc(394px * var(--scale));
  left: calc(690px * var(--scale));
  transform: translateX(-50%);
  font-size: calc(24px * var(--scale));
`

const Password = styled.div`
  position: absolute;
  top: calc(330px * var(--scale));
  left: calc(495px * var(--scale));
  font-size: calc(24px * var(--scale));
  border: calc(2px * var(--scale)) solid #9a9a9a;
  padding: calc(5px * var(--scale));
  width: calc(400px * var(--scale));
  height: calc(44px * var(--scale));
  text-align: left;
  border-radius: calc(4px * var(--scale));
  color: #aaa;

  ${p =>
    p.filled &&
    css`
      border-color: #c30000;
      color: #c30000;
    `}
`
