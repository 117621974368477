export default `
# Chapter
$ bg = barn-klasserom
title = Wednesday 
subtitle = At school 

# Dialogue
[Luna neutral left 200]
[Bix surprised right 475]
[Noah neutral right 750]
Bix: A starstorm is forecast for tomorrow.
Bix: So school will be closed.

[Bix happy]
Bix: Do you want to play Moon Sword?

[Noah happy]
Noah: Yes! I want that helmet you have.

[Luna sad]
[Noah neutral]
Luna: I’m not allowed to play.
Luna: My parents say I’m too young.

[Bix surprised]
Bix: But we’re the same age, and I’m allowed.

[Luna angry]
[Bix confused]
Luna: Yess! It’s so unfair!
Bix: Is your brother allowed to play it?

[Luna sad]
Luna: Yes. But he is 12 years old.

[Bix happy]
Bix: Play on his computer. I share an ...
Bix: ...account with my sister. It’s fine!

[Luna happy]
Luna: Oh, is that possble? I’ll have a go!

# SmileyScales
title(json) = {
  lines: [
    'Luna really wants to play Moon Sword',
    'but her parents won’t let her.',
    '',
    'Do you think she should try all the same?'
  ],
  fontSize: 33,
}

# Chapter
$ bg = timo-soverom
title = At Luna’s house
subtitle = Timo’s room

# Dialogue
[Luna neutral left 220]
[Timo confused right 680]
Timo: What are you doing in my room?

[Luna happy]
Luna: Can I play Moon Sword on the computer?
Timo: Eh, no!

/* Burde det ikke vært MåneSverdet? */

[Luna angry]
Luna: Oh, but you play it aaall the time!

[Luna sad]
Luna: Can’t I play just a little bit?

[Timo angry]
Timo: No! Go away!

[Timo 570 z=2] /* TODO: Parse z */
[Edan surprised right 800]
Edan: What’s happening?
Timo: Luna wants to play Moon Sword here.

[Edan neutral]
Edan: You are too young, Luna.
Luna: But everybody else is allowed!
Edan: I don’t think they’re _all_ allowed.
Luna: Noah and Bix are allowed to play.
Luna: Perhaps I can share with Timo?
Edan: I don’t decide for them, only you.
Edan: Others in your class aren’t allowed.
Luna: Who? Everyone I know play.

[Edan confused]
Edan: Can’t remember just now. I’ll ask...
Edan: ...at the parents’ meeting tonight.
Timo: Can you leave my room now?

# Text
paragraphs(json) = [
  'Luna isn’t allowed to play Moon Sword because she is too young. No families are the same and they have different age limits.',
  '',
  'The next page gives some examples of rules.',
]

# EvaluateStatements
star = true
title.text = Drag the rules to where you want them.
title.fontSize = 40

statements(json) = [
  'I never get to play games that I’m too young for.',
  'I’m allowed to play games that are rated for up to 12 years.',
  'I’m allowed to play most games, but only with an adult.',
  'I’m allowed to play games that my friends aren’t allowed to play.',
  'I get to play whatever I want.',
]
`
