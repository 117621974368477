import trace from './trace.js'
import json5 from 'json5'

export default function lex(source, includePos = false) {
  const result = []

  let i = 0

  while (i < source.length) {
    if (/\s/.test(source[i])) i += 1
    else if (source[i] === '#') newTemplate()
    else if (source[i] === '[') parseCharacter()
    else if (hasOnLine('=')) setOption()
    else if (hasOnLine(':')) addDialogue()
    else throw Error(`Syntax error at:\n${trace(source, i)}`)
  }

  function newTemplate() {
    i += 1
    const start = i
    while (source[i] && source[i] !== '\n') {
      i += 1
    }
    push({ type: 'template', name: source.slice(start, i).trim() })
  }

  function setOption() {
    let persistent = false
    if (source[i] === '$') {
      persistent = true
      i += 1
    }
    const equalsIndex = nextIndexOnLine('=')
    let key = source.slice(i, equalsIndex).trim()
    const keywordMatch = key.match(/^(?<key>[a-zA-Z.][a-zA-Z.0-9]*)\s*(\((?<dataType>[^)]+)\))?/)
    let dataType = null
    if (keywordMatch) {
      key = keywordMatch.groups.key
      dataType = keywordMatch.groups.dataType || null
    }
    i = equalsIndex + 1
    const endIndex = getEndIndexForOption(dataType)
    let val = source.slice(equalsIndex + 1, endIndex).trim()
    i = endIndex
    switch (dataType) {
      case 'list':
        val = val.split(',').map(x => x.trim())
        break
      case 'json':
        val = json5.parse(val)
        break
      default:
        if (dataType) throw Error(`Unknown data type: ${dataType}`)
        break
    }
    push({ type: 'option', key, val, dataType, persistent })
  }

  function addDialogue() {
    const index = nextIndexOnLine(':')
    const name = source.slice(i, index).trim()
    i = index + 1
    let newlineIndex = i
    for (let j = i; j < source.length && source[j] !== '\n'; j++) {
      newlineIndex = j + 1
    }
    const text = source.slice(i, newlineIndex).trim()
    i = newlineIndex
    push({ type: 'dialogue', name, text })
  }

  function hasOnLine(letter) {
    return nextIndexOnLine(letter, true) !== -1
  }

  function nextIndexOnLine(letter, allowFail = false) {
    let j
    for (j = i; j < source.length && source[j] !== '\n'; j++) {
      if (source[j] === letter) return j
    }
    if (letter === '\n' && source[j] === '\n') return j
    if (!allowFail) throw Error(`Expected: "${letter}" on line:\n${trace(source, i)}`)
    return -1
  }

  function parseCharacter() {
    let start = i + 1
    while (i < source.length && source[i] !== ']') i += 1
    if (source[i] !== ']') throw Error(`Expected "]" ${trace(source, start)}`)
    const end = i++
    push({
      type: 'character',
      config: source.slice(start, end).split(/\s+/g),
    })
  }

  function push(token) {
    if (includePos) {
      token.meta = { source, index: i }
    }
    result.push(token)
  }

  function getEndIndexForOption(dataType) {
    if (dataType === 'json') {
      for (let j = i + 1; j < source.length; j++) {
        try {
          json5.parse(source.slice(i, j))
          return j
        } catch (e) {}
      }
      throw Error(`Failed to parse json5\n${trace(source, i)}`)
    }

    for (let j = i; j < source.length; j++) {
      if (source[j] === '\n') return j
    }
    return source.length
  }

  return result
}
