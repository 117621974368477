export default `
# Chapter
$ bg = barn-klasserom
title = Mandag
subtitle = På skolen

# Dialogue
[Luna neutral left 250]
[Bix happy right 680]
Luna: Hei Bix!

[Bix surprised]
Bix: Hei! Så teit det bildet av deg var!

[Luna confused]
Luna: Hæ?
Bix: På Spacebook. Alle snakker om det!

[Luna surprised]
Luna: Å nei! Hvem har tatt det?

[Bix neutral]
Bix: Moren til Limal la det ut.

[Luna sad]
Luna: Ta det vekk!

[Bix sad]
Bix: Jeg kan ikke slette andres bilder.
Luna: Åååååh...

# Chapter
$ bg = luna-soverom
title = Hjemme hos Luna

# Dialogue
[Luna sad left 250]
[Edan surprised right 680]
Edan: Gråter du? Hva har skjedd?

[Edan neutral]
Luna: Moren til Limal la ut et dumt bilde...
Luna: ...av meg på Spacebook.

[Edan surprised]
Edan: Spurte hun om lov?
Luna: Nei, jeg visste det ikke.
Luna: Jeg vil ikke på skolen i morgen!

[Luna down=true]
[Edan up=true]
Luna: Alle ler av meg.

# Choice
star = true
title(json) = {
  text: 'Hva bør Lunas pappa gjøre?',
  fontSize: 50,
}
showWrong = true
choices(json) = [
  { text: 'Ringe politiet.' },
  { text: 'Snakke med Limals mamma for å slette bildet.' },
  { text: 'Rapportere bildet til Spacebook.' },
  { text: 'Snakke med rektor eller lærer.' },
  { text: 'Skrive en kommentar på bildet og be om å få det slettet.' },
  { text: 'Ingenting.', wrong: true },
]

# Dialogue
[Luna sad left down=true 250]
[Edan neutral right 680]
Edan: Jeg fikser dette! Bare gå og legg deg.

[Edan tilt=true]
Edan: Prøv å ikke tenk mer på det. Ok?
Luna: Jeg skal prøve. Natta.

# Discuss
paragraphs(json) = [
  'Når er det greit at voksne viser eller legger ut bilder av andre?',
  'Har du opplevd noe liknende selv?',
]
`
