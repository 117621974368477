export default `
# Chapter
$ bg = barn-klasserom
title = Monday 
subtitle = At school 
# Dialogue
[Noah left 220 neutral]
[Limal right 500 neutral]
[Luna right 780 neutral flip]
Noah: Hi, Limal!

[Limal surprised]
Limal: You didn’t come to my birthday party.
Limal: Didn’t you see the invitation?

[Noah confused]
Noah: What? Did you have a party?

[Limal confused]
Limal: My mum sent the invitations on Spacebook.

[Luna confused]
Luna: The whole class was invited.

[Noah sad]
Noah: I’m not allowed on Spacebook.
Noah: My mum doesn’t have an account either.

[Limal sad]
Limal: Oh, that’s so not cool!

# Discuss
star = true
fontSize = 35
lines(json) = [
  'Noah doesn’t use the same app as the other kids and feels left out. \\
  Some children are allowed online earlier than other children.',
  '',
  'How can everyone see invitations and messages so they don’t feel left out, like Noah?'
]

# Chapter
$ bg = noah-soverom
title = At Noah’s house 

# Dialogue
[Noah sad left 250]
[Sara surprised right 680]
Sara: Is something wrong? Are you sad?

[Noah flip]
Noah: I never knew about Limal’s birthday.

[Sara confused]
Sara: Were you not invited?
Sara: He is your best friend.
Noah: They sent the invitations on Spacebook.

[Sara sad]
Sara: Oh! That’s a shame.
Noah: Why can’t I use Spacebook?
Sara: There’s a 13-year age limit.
Sara: I don’t think it’s good for you.
Noah: Everyone else in my class uses it!

[Sara neutral]
Sara: I’ll talk to the other parents ... 
Sara: .. at Wednesday’s parents’ meeting.

[Sara happy]
Sara: Missing birthday parties is not on!

# Choice
compact = true
star = true
title.text = Why do most social media have a 13 year age limit? 
title.fontSize = 50
subtitle = Choose one correct option.  
textWhenWrong = Not correct 

choices(json) = [
  { text: 'They store personal data (data protection)' },
  { text: 'Because online stuff is so difficult to remove', wrong: true },
  { text: 'Children shouldn’t be sitting in front of a screen that much', wrong: true },
  { text: 'They are difficult to use', wrong: true },
  { text: 'To protect children from strangers', wrong: true },
  { text: 'The content is unsuitable for certain age groups', wrong: true },
]

tipsrakett.title = Why are there age limits for social media? 
tipsrakett.lines(json) = [
'There are two types of age limits on social media:',
'- Age limits for collecting personal data.',
'- Age rated content.',
'Most social media have a 13-year age limit for creating a profile. This age limit has been set to protect children’s personal data. For children under 13, parents need to authorise the creation of a profile and sharing of personal data. ',
'Social media have their own age recommendations for content or service suitability. Different app stores (like the Apple App Store and Windows Store) use different classification systems, which means that age ratings for content may vary.'
]
`
