import { useAutoMemo, useAutoCallback } from 'hooks.macro'
import context from './context'
import React from 'react'

export default function DraggableContainer({ dropDuration, children, onDrop }) {
  const [dropTargets, setDropTargets] = React.useState({})
  const [moveCount, setMoveCount] = React.useState(0)
  const [element, ref] = React.useState(null)
  const child = React.Children.only(children)

  const addDropTarget = useAutoCallback((name, element) => {
    setDropTargets(previous => {
      return {
        ...previous,
        [name]: element,
      }
    })

    return () => {
      setDropTargets(previous => {
        if (previous[name] === element) {
          const next = { ...previous }
          delete next[name]
          return next
        }
        return previous
      })
    }
  })

  const getDropTargetsAtPoint = useAutoCallback((clientX, clientY) => {
    return Object.entries(dropTargets)
      .filter(([name, element]) => {
        if (!element) return false
        const rect = element.getBoundingClientRect()
        if (clientX < rect.left) return false
        if (clientY < rect.top) return false
        if (clientX >= rect.left + rect.width) return false
        if (clientY >= rect.top + rect.height) return false
        return true
      })
      .map(([name]) => name)
  })

  const contextValue = useAutoMemo({
    container: element,
    getDropTargetsAtPoint,
    addDropTarget,
    dropDuration,
    setMoveCount,
    dropTargets,
    moveCount,
    isMoving: moveCount > 0,
    onDrop,
  })

  const newChild = useAutoMemo(React.cloneElement(child, { ref }))

  return <context.Provider value={contextValue}>{newChild}</context.Provider>
}
