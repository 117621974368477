let storage = {
  getItem(key) {
    return this[key]
  },
  setItem(key, val) {
    this[key] = val
  },
}

try {
  sessionStorage.setItem('______test', 'test')
  sessionStorage.getItem('______test')
  storage = sessionStorage
} catch (e) {}

export default {
  setItem(key: string, value: string) {
    storage.setItem(key, value)
  },
  getItem(key: string): string {
    return storage.getItem(key)
  },
}
