import { useAutoLayoutEffect } from 'hooks.macro'
import { useStore } from 'Store'
import React from 'react'

let audio = new Audio('/jellyfish-in-space.mp3')
audio.volume = 0.1
audio.loop = true

export default function useBackgroundMusic({ children }) {
  const { audioEnabled, hasStarted, setAudioEnabled } = useStore()

  useAutoLayoutEffect(() => {
    let cancelled = false

    if (!audioEnabled || !hasStarted) {
      audio.pause()
    } else {
      audio.play()
        .catch(e => {
          console.log(e.message)
          if (cancelled) return
          setAudioEnabled(false)
        })
    }

    return () => {
      cancelled = true
      audio.pause()
    }
  })

  return <>{children}</>
}
