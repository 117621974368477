const values = {}
let keys = []

let storage = {
  getItem(key) {
    return values[key]
  },
  setItem(key, val) {
    values[key] = val
    keys = Object.keys(values)
    this.length = keys.length
  },
  key(index) {
    return keys[index]
  },
  length: 0,
}

try {
  sessionStorage.setItem('______test', 'test')
  sessionStorage.getItem('______test')
  storage = sessionStorage
} catch (e) {}

try {
  localStorage.setItem('______test', 'test')
  localStorage.getItem('______test')
  storage = localStorage
} catch (e) {}

export default {
  setItem(key: string, value: string) {
    storage.setItem(key, value)
  },
  getItem(key: string): string {
    return storage.getItem(key)
  },
  get length() {
    return storage.length
  },
  key(index: number) {
    return storage.key(index)
  },
}
