import { useAutoMemo, useAutoEffect, useAutoCallback } from 'hooks.macro'
import useEventHandler from './useEventHandler'
import sleep from 'sleep-promise'
import context from './context'
import React from 'react'

export default function Draggable({ children, value, disabled }) {
  const [moving, setMoving] = React.useState(null)
  const [element, ref] = React.useState(null)
  const child = React.Children.only(children)
  const [initialCoords, setInitialCoords] = React.useState([null, null])
  const [[clientX, clientY], setClientCoords] = React.useState([null, null])
  const { getDropTargetsAtPoint, dropDuration, onDrop, setMoveCount } = React.useContext(context)
  const [initialRect, setInitialRect] = React.useState({})
  const [dropping, setDropping] = React.useState([])

  const [insetX, insetY] = useAutoMemo(() => {
    const [initialX, initialY] = initialCoords
    const { left, top } = initialRect || {}

    return [initialX - left, initialY - top]
  })

  const [relativeX, relativeY] = useAutoMemo(() => {
    const [initialX, initialY] = initialCoords

    return [clientX - initialX, clientY - initialY]
  })

  useAutoEffect(() => {
    if (moving === 'mouse' || moving === 'touch') {
      setMoveCount(moveCount => moveCount + 1)
    } else if (moving === 'dropping') {
      setMoveCount(moveCount => moveCount - 1)
    }
  })

  const resetState = useAutoCallback(() => {
    setInitialRect(null)
    setInitialCoords([null, null])
    setClientCoords([null, null])
    setMoving(null)
  })

  // Gather drop targets on drop and setup transition
  useAutoEffect(() => {
    if (moving !== 'will-drop') return

    for (const name of getDropTargetsAtPoint(clientX, clientY)) {
      setDropping(dropping => dropping.concat(name))
    }

    const timeout = setTimeout(() => {
      setMoving('dropping')
    }, 50)

    return () => {
      clearTimeout(timeout)
    }
  })

  // Take drop targets and transition them away
  useAutoEffect(() => {
    if (moving !== 'dropping') return

    if (!dropping.length) {
      let cancelled = false
      setClientCoords(initialCoords)

      sleep(dropDuration).then(() => {
        if (!cancelled) {
          resetState()
        }
      })

      return () => {
        cancelled = true
      }
    }

    let cancelled = false

    const promises = dropping.map(name => {
      return sleep(dropDuration).then(() => {
        if (!cancelled) {
          onDrop(name, value)
        }
      }, dropDuration)
    })

    Promise.all(promises).then(() => {
      if (!cancelled) {
        setDropping(null)
      }
    })

    return () => {
      cancelled = true
    }
  })

  useEventHandler(
    element,
    'mousedown',
    useAutoCallback(e => {
      setInitialRect(e.target.getBoundingClientRect())
      setInitialCoords([e.clientX, e.clientY])
      setClientCoords([e.clientX, e.clientY])
      setMoving('mouse')
    }),
    !disabled,
  )

  useEventHandler(
    document,
    'mousemove',
    useAutoCallback(e => {
      setClientCoords([e.clientX, e.clientY])
    }),
    moving === 'mouse',
  )

  useEventHandler(
    document,
    'mouseup',
    useAutoCallback(e => {
      setMoving('will-drop')
    }),
    moving === 'mouse',
  )

  useEventHandler(
    element,
    'touchstart',
    useAutoCallback(e => {
      setInitialRect(e.touches[0].target.getBoundingClientRect())
      setInitialCoords([e.touches[0].clientX, e.touches[0].clientY])
      setClientCoords([e.touches[0].clientX, e.touches[0].clientY])
      setMoving('touch')
    }),
    !disabled,
  )

  useEventHandler(
    document,
    'touchmove',
    useAutoCallback(e => {
      setClientCoords([e.touches[0].clientX, e.touches[0].clientY])
    }),
    moving === 'touch',
  )

  useEventHandler(
    document,
    'touchend',
    useAutoCallback(e => {
      setMoving('will-drop')
    }),
    moving === 'touch',
  )

  const [scale, setScale] = React.useState(1)

  useAutoEffect(() => {
    if (!moving) setScale(1)
    if (moving === 'mouse' || moving === 'touch') {
      let af = requestAnimationFrame(function scaleUp() {
        setScale(s => {
          if (s > 0.6) {
            af = requestAnimationFrame(scaleUp)
            return s - 0.1
          }
          return s
        })
      })

      return () => cancelAnimationFrame(af)
    }
  })

  const style = useAutoMemo(() => {
    const style = {
      touchAction: 'none',
      transform: null,
      transition: 'opacity .3s',
      transformOrigin: moving && `${insetX}px ${insetY}px`,
    }

    if (!moving) return style

    // style.opacity = '0.8'
    style.pointerEvents = 'none'

    if (moving === 'will-drop' || moving === 'dropping') {
      style.transition = `transform ${dropDuration || 0}ms`
    }

    const transform = [`translateX(${relativeX}px)`, `translateY(${relativeY}px)`]

    if (moving === 'mouse' || moving === 'touch') {
      transform.push(`scale(${scale})`)
    }

    if (moving === 'dropping' && dropping.length) {
      transform.push('scale(0)')
    }

    style.transform = transform.join(' ')

    return style
  })

  return useAutoMemo(React.cloneElement(child, { ref, style }))
}
