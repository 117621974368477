import { Translate, Norwegian, English } from 'languageContext'
import { useHistory, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { useAutoEffect } from 'hooks.macro'
import StoreContext from 'Store'
import React from 'react'

export default function Menu() {
  const { interfacePNG } = React.useContext(StoreContext)
  const [isOpen, setIsOpen] = React.useState(false)
  const toggle = () => setIsOpen(isOpen => !isOpen)
  const { character } = useParams()
  const history = useHistory()

  useAutoEffect(() => {
    if (!isOpen) return

    document.addEventListener('click', onClick)

    return () => {
      document.removeEventListener('click', onClick)
    }

    function onClick(event) {
      if (event.target.hasAttribute('data-menu-item')) {
        return
      }
      setIsOpen(false)
    }
  })

  return (
    <Container>
      <Button onClick={toggle} title="Åpne menyen">
        <Img
          style={{ display: 'block', cursor: 'pointer' }}
          src={interfacePNG('hamburger-button')}
          width={44}
          height={44}
          alt=""
        />
      </Button>
      <MenuItems isOpen={isOpen}>
        <MenuItem
          aria-label="Kapitteloversikt"
          tabIndex={isOpen ? 0 : -1}
          index={0}
          onClick={() => {
            setIsOpen(false)
            history.push({
              pathname: `/spill/${character}/velg-kapittel`,
              search: history.location.search,
            })
          }}
        >
          <Translate>
            <Norwegian>Kapitteloversikt</Norwegian>
            <English>Chapters</English>
          </Translate>
        </MenuItem>
        <MenuItem
          index={1}
          tabIndex={isOpen ? 0 : -1}
          onClick={() =>
            history.push({
              pathname: '/velg-karakter',
              search: history.location.search,
            })
          }
          aria-label="Gå til hovedmenyen"
        >
          <Translate>
            <Norwegian>Gå til hovedmenyen</Norwegian>
            <English>Go to the main menu</English>
          </Translate>
        </MenuItem>
      </MenuItems>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  right: calc(70px * var(--slight-scale));
  top: calc(10px * var(--slight-scale));
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Button = styled.button.attrs({
  type: 'button',
})`
  background-color: transparent;
  font-family: inherit;
  font-size: 1em;
  border: none;
  padding: 0;
  margin: 0;
`

const MenuItems = styled.div`
  position: absolute;
  pointer-events: none;
  top: calc(50px * var(--slight-scale));
  right: 0;
  ${p =>
    p.isOpen &&
    css`
      pointer-events: auto;
      & ${MenuItem} {
        transform: translateY(0);
        opacity: 1;
      }
    `}
`

const MenuItem = styled.button.attrs({
  'data-menu-item': 'data-menu-item',
  type: 'button',
})`
  font-family: inherit;
  font-size: calc(25px * var(--slight-scale));
  min-width: calc(280px * var(--slight-scale));
  width: 100%;
  background-color: #ffd89b;
  border: calc(2px * var(--slight-scale)) solid black;
  border-radius: calc(7px * var(--slight-scale));
  padding: calc(8px * var(--slight-scale)) calc(13px * var(--slight-scale));
  display: block;
  margin-top: calc(10px * var(--slight-scale));
  transition: opacity 0.5s, transform 0.5s;
  opacity: 0;
  transform-origin: 100% 0;
  transform: translateY(calc(${p => -49 * (p.index + 1)}px * var(--slight-scale)));
  cursor: pointer;
`

const Img = styled.img`
  width: calc(${p => p.width}px * var(--slight-scale));
  height: calc(${p => p.height}px * var(--slight-scale));
`
