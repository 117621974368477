import { useAutoMemo, useAutoCallback } from 'hooks.macro'
import SceneWrapper, { CollectedStars } from 'common/SceneWrapper'
import { Translate, Norwegian, English } from 'languageContext'
import styled, { css, keyframes } from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import clientId from 'clientId'
import React from 'react'
import emit from 'emit'

export default function RecommendationFeedback({
  nextPage,
  options: { title, subtitle, overflow },
}) {
  const [choice, setChoice] = React.useState(null)
  const [explanation, setExplanation] = React.useState('')
  const { character } = useParams()

  const storeChoice = useAutoCallback((event) => {
    const choice = event.target.textContent
    setChoice(choice)
  })
  const submit = useAutoCallback((event) => {
    if (!choice || !explanation?.length) return

    fetch('/api/recommendation-feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        choice,
        clientId,
        character,
        explanation,
      }),
    }).then(
      () => {
        nextPage()
      },
      (error) => {
        alert('Din respons kunne ikke sendes: ' + error.message)
        nextPage()
      },
    )

    emit('Fullført story')
  })

  return useAutoMemo(
    <>
      <SceneWrapper
        hideSpaceShip
        bgColor="#9cbed6"
        backgroundBlendMode="multiply"
        overflow={overflow}
      >
        <CharacterSelectTextBoxImage />
        <TextBox>
          <Container>
            <CollectedStars />
            <Part1 hide={choice}>
              <Subtitle>{subtitle}</Subtitle>
              <Text>
                <Translate>
                  <Norwegian>Ville du anbefalt Stjernekolonien til noen andre?</Norwegian>
                  <English>Would you recommend this game to others?</English>
                </Translate>
              </Text>
              <ButtonRow>
                <Button onClick={storeChoice}>
                  <Translate>
                    <Norwegian>Ja</Norwegian>
                    <English>Yes</English>
                  </Translate>
                </Button>
                <Button onClick={storeChoice}>
                  <Translate>
                    <Norwegian>Nei</Norwegian>
                    <English>No</English>
                  </Translate>
                </Button>
                <Button onClick={storeChoice}>
                  <Translate>
                    <Norwegian>Vet ikke</Norwegian>
                    <English>Don’t know</English>
                  </Translate>
                </Button>
              </ButtonRow>
            </Part1>
            <Part2 show={choice}>
              <Text hide={!choice} margintop={true}>
                <Translate>
                  <Norwegian>Kan du forklare hvorfor?</Norwegian>
                  <English>Can you explain why?</English>
                </Translate>
              </Text>
              <InputContainer>
                <Input
                  value={explanation || ''}
                  onChange={(event) => setExplanation(event.target.value)}
                  maxLength="200"
                />
                <TextCount>{explanation?.length + '/200'}</TextCount>
              </InputContainer>
              <ButtonRow>
                <Button onClick={submit} inactive={!explanation?.length}>
                  <Translate>
                    <Norwegian>Avslutt</Norwegian>
                    <English>Finish</English>
                  </Translate>
                </Button>
              </ButtonRow>
            </Part2>
          </Container>
        </TextBox>

        <BottomBar>
          <div>
            <img
              className="eu"
              src="/EU.png"
              alt="Co-financed by the European Union. Connecting Europe Facility."
            />
            <img className="medietilsynet" src="/medietilsynet-hvit.png" alt="Medietilsynet" />
          </div>
        </BottomBar>
      </SceneWrapper>
    </>,
  )
}

const CharacterSelectTextBoxImage = styled.img.attrs({
  src: '/media/large/interface/character-select-textbox.png',
  alt: '',
})`
  width: calc(642px * var(--scale));
  height: calc(300px * var(--scale));
  position: absolute;
  bottom: calc(100px * var(--scale));
  left: 50%;
  transform: translateX(-50%);
`

const TextBox = styled.div`
  position: absolute;
  bottom: calc((25px + 20px) * var(--scale));
  width: calc((642px - 40px) * var(--scale));
  height: calc((400px - 40px) * var(--scale));
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Title = styled.div`
  font-size: calc(30px * var(--scale));
  font-weight: 500;
  margin-top: calc((90px) * var(--scale));
`

const Subtitle = styled.div`
  font-size: calc(23px * var(--scale));
  font-weight: 500;
`

const btn = css`
  background-color: #48f5cd;
  border: calc(2px * var(--scale)) solid black;
  font-size: calc(20px * var(--scale));
  font-family: 'Walter turncoat', sans-serif;
  line-height: 1;
  padding: calc(10px * var(--scale)) calc(15px * var(--scale));
  border-radius: calc(10px * var(--scale));
  filter: drop-shadow(calc(0px * var(--scale)) calc(4px * var(--scale)) rgba(0, 0, 0, 0.5));
  margin: calc(10px * var(--scale));
  flex-basis: 100%;
  color: black;
  cursor: pointer;
  min-width: calc(138px * var(--scale));
`

const Button = styled.button.attrs({
  type: 'button',
})`
  ${btn};
  max-width: 50px;
  opacity: 1;
${ p => p?.inactive && css`
    pointer-events: none;
    opacity: 0.3;
`}
`

const BottomBar = styled.div`
  background-color: rgba(10, 91, 153, 0.79);
  height: calc(58px * var(--scale));
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    height: 100%;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      vertical-align: top;
      object-fit: contain;

      &.eu {
        width: calc(224px * var(--scale));
        height: calc(26px * var(--scale));
      }

      &.medietilsynet {
        width: calc(216px * var(--scale));
        height: calc(65px * var(--scale));
        transform: translateY(calc(-0px * var(--scale)));
      }
    }
  }
`
const Text = styled.div`
  font-size: calc(25px * var(--scale));
  font-weight: 500;

  opacity: 1;
  pointer-events: none;
  ${(p) =>
    p.margintop
      ? css`
          margin-top: calc(10px * var(--scale));
        `
      : css`
          margin-bottom: calc(10px * var(--scale));
        `}

  ${(p) =>
    p.hide
      ? css`
          opacity: 0;
        `
      : ''}
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
`
const Input = styled.textarea`
  font-size: calc(22px * var(--scale));
  border: calc(2px * var(--scale)) solid black;
  padding: calc(10px * var(--scale));
  border-radius: calc(5px * var(--scale));
  margin-top: calc(10px * var(--scale));
  max-width: calc(800px * var(--scale));
  width: calc(500px * var(--scale));
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
`
const TextCount = styled.div`
  margin-left: auto;
  position: absolute;
  right: calc(50px * var(--scale));
  bottom: calc(-17px * var(--scale));
  color: #847f7f;
  font-size: calc(18px * var(--scale));
`
const moveout = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
`

const movein = keyframes`
  from {
    transform: translateX(200);
  }
  to {
    transform: translateX(0);
  }
`
const hideAnimation = keyframes`
  from {
    display: block;
  }
  to {
    display: none;
  }
`

const showAnimation = keyframes`
  from {
    display: none;
  }
  to {
    display: block;
  }
`
const Part1 = styled.div`
  position: absolute;
  bottom: calc((50px) * var(--scale));
  width: calc((642px - 40px) * var(--scale));
  height: calc((230px - 40px) * var(--scale));
  ${(p) =>
    p.hide &&
    css`
      animation: ${moveout} 1s ease-in-out, ${hideAnimation} 1s 0.1s;
      animation-fill-mode: forwards;
    `}
`

const Container = styled.div`
  position: relative;
  height: 100%;
`
const Part2 = styled.div`
  opacity: 1;
  transform: translate(200%);
  position: absolute;
  bottom: calc((80px + 20px) * var(--scale));
  width: calc((642px - 40px) * var(--scale));
  height: calc((230px - 40px) * var(--scale));
  ${(p) =>
    p.show &&
    css`
      pointer-events: auto;
      animation: ${showAnimation} 1s 0.1s, ${movein} 1.1s 0.1s ease-in-out;
      animation-fill-mode: forwards;
    `}
`
const InputContainer = styled.div`
  position: relative;
`
