import { useAutoCallback, useAutoEffect } from 'hooks.macro'
import styled, { keyframes, css } from 'styled-components/macro'
import interpolate from 'color-interpolate'
import SceneWrapper from 'common/SceneWrapper'
import NextButton from 'common/NextButton'
import BackButton from 'common/BackButton'
import Emoji from 'common/Emoji'
import Title from 'common/Title'
import repeat from 'repeat'
import React from 'react'

const SLIDE_BACK_TIME_MS = 500
const RED = `rgb(252, 109, 81)`
const YELLOW = `rgb(255, 205, 84)`
const GREEN = `rgb(160, 211, 103)`

const interpolator = interpolate([RED, YELLOW, GREEN])

export default ({ options: { bg, title, labels = [] }, storeAnswer, answer }) => {
  const [moveJustCompleted, setMoveJustCompleted] = React.useState(false)
  const [sliderContainer, setSliderContainer] = React.useState(null)
  const [innerSlider, setInnerSlider] = React.useState(null)
  const [moving, setMoving] = React.useState(null)
  const hasAnswer = answer != null

  const position = hasAnswer ? answer : 50
  const setPosition = storeAnswer

  const setPositionFromClientX = useAutoCallback((clientX) => {
    const rect = sliderContainer.getBoundingClientRect()
    let position = ((clientX - rect.left) / rect.width) * 100
    if (position < 0) position = 0
    if (position > 100) position = 100
    requestAnimationFrame(() => setPosition(Math.round(position)))
  })

  const onKeyDown = useAutoCallback((event) => {
    console.log(event.nativeEvent)
    switch (event.key) {
      case 'ArrowLeft':
        setPosition((position) => {
          if (position == null) position = 50
          return Math.max(position - 5, 0)
        })
        break
      case 'ArrowRight':
        setPosition((position) => {
          if (position == null) position = 50
          return Math.min(position + 5, 100)
        })
        break
      default:
        break
    }
  })

  const onMouseDown = useAutoCallback((event) => {
    setPositionFromClientX(event.clientX)
    setMoving('mouse')
    setMoveJustCompleted(false)
  })

  const onTouchStart = useAutoCallback((event) => {
    setPositionFromClientX(event.touches[0].clientX)
    setMoving('touch')
    setMoveJustCompleted(false)
  })

  useAutoEffect(() => {
    if (moving !== 'mouse') return

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }

    function onMouseMove({ clientX }) {
      setPositionFromClientX(clientX)
    }

    function onMouseUp() {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      setMoving(null)
      setMoveJustCompleted(true)
    }
  })

  useAutoEffect(() => {
    if (moving !== 'touch') return

    document.addEventListener('touchmove', onMouseMove)
    document.addEventListener('touchend', onMouseUp)

    return () => {
      document.removeEventListener('touchmove', onMouseMove)
      document.removeEventListener('touchend', onMouseUp)
    }

    function onMouseMove({ touches: [{ clientX }] }) {
      setPositionFromClientX(clientX)
    }

    function onMouseUp() {
      document.removeEventListener('touchmove', onMouseMove)
      document.removeEventListener('touchend', onMouseUp)
      setMoving(null)
      setMoveJustCompleted(true)
    }
  })

  useAutoEffect(() => {
    if (!moveJustCompleted) return

    const timeout = setTimeout(() => {
      setMoveJustCompleted(false)
    }, SLIDE_BACK_TIME_MS)

    setPosition((position) => {
      return Math.round(position)
    })

    return () => {
      clearTimeout(timeout)
    }
  })

  const [sliderWidth, setSliderWidth] = React.useState(0)

  useAutoEffect(() => {
    if (!innerSlider) return

    const update = () => {
      setSliderWidth(innerSlider.clientWidth)
    }

    update()
    window.addEventListener('resize', update)
    const interval = setInterval(update, 500)

    return () => {
      window.removeEventListener('resize', update)
      clearInterval(interval)
    }
  })

  const absolutePosition = sliderWidth * (position / 100)

  return (
    <SceneWrapper bg={bg}>
      <Backdrop />
      <Modal>
        <Title title={title} />
        <EmojiContainer>
          <Emoji value={position / 100} />
        </EmojiContainer>
        <Labels>
          {labels.map((text, index) => (
            <Label key={index} count={labels.length}>
              {text}
            </Label>
          ))}
        </Labels>
        <SliderContainer count={labels.length} ref={setSliderContainer}>
          <HorizontalLine />
          {repeat(labels.length, (i) => (
            <VerticalLine key={i} />
          ))}
          <InnerSlider ref={setInnerSlider} moving={moving} empty={!hasAnswer}>
            <Drop
              tabIndex={0}
              color={interpolator(position / 100)}
              shouldSlide={moveJustCompleted}
              position={absolutePosition}
              onMouseDown={onMouseDown}
              onTouchStart={onTouchStart}
              onKeyDown={onKeyDown}
              moving={moving}
            />
          </InnerSlider>
        </SliderContainer>
        <BottomRow>
          <BackButton />
          <NextButton disabled={answer == null} />
        </BottomRow>
      </Modal>
    </SceneWrapper>
  )
}

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Backdrop = styled.div`
  position: absolute;
  background-color: rgba(51, 118, 169, 0.4);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1s forwards;
`

const Modal = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  padding: calc(60px * var(--scale));
  position: absolute;
  bottom: calc(40px * var(--scale));
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(40px * var(--scale));
  border-radius: calc(40px * var(--scale));

  animation: ${keyframes`
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  `} 0.5s forwards;
`

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Label = styled.div`
  width: ${(p) => 100 / p.count}%;
  text-align: center;
`

const SliderContainer = styled.div`
  width: ${(p) => 100 - 100 / p.count}%;
  margin: 0 auto;
  height: calc(30px * var(--scale));
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: calc(30px * var(--scale));
  z-index: 100;
`

const HorizontalLine = styled.div`
  width: 100%;
  height: calc(30px * var(--slight-scale));
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10000px;
  background-image: linear-gradient(90deg, ${RED}, ${YELLOW}, ${GREEN});
  border: 2px solid black;
`

const VerticalLine = styled.div`
  height: 100%;
  width: 2px;
  background-color: black;
`

const Drop = styled(({ position, shouldSlide, ...props }) => (
  <svg
    width={27.1}
    height={52.1}
    style={{
      transform: [
        `translateX(-50%)`,
        `scale(calc(var(--scale) * 2.5))`,
        `translateX(calc(${position}px / 2.5 / var(--scale)))`,
      ].join(' '),
    }}
    {...props}
  >
    {/* drop */}
    <path
      stroke="black"
      d="M13.5 51.6c-3.8 0-7-1-9.2-2.9-1.2-1-2.1-2.3-2.8-3.8-.7-1.6-1-3.5-1-5.6C.5 35 2 25.2 4.4 16.4 7.3 6.3 10.6.5 13.5.5s6.2 5.8 9.1 15.9c2.5 8.8 3.9 18.5 3.9 22.9 0 2.1-.3 4-1 5.6-.6 1.5-1.6 2.8-2.8 3.8-2.2 1.9-5.4 2.9-9.2 2.9z"
    />
  </svg>
))`
  fill: ${(p) => p.color};
  position: absolute;
  z-index: 100;
  top: calc(15px * var(--scale));
  left: 0;
  transform-origin: 50% 0;
  overflow: visible;
  cursor: ${(p) => (p.moving ? 'grabbing' : 'grab')};
  ${(p) =>
    p.shouldSlide &&
    css`
      transition: transform ${SLIDE_BACK_TIME_MS}ms;
    `}

  & path {
    stroke-width: calc(2px * var(--scale) / 2);
    transition: transform 0.4s;
    transform-origin: 50% 0%;
  }

  :focus {
    outline: none;
  }

  :focus > path {
    transform: translate3d(0, 0, 0) scale(1.2);
  }
`

const InnerSlider = styled.div`
  position: absolute;
  bottom: 0;
  right: calc(1px * var(--scale));
  left: calc(1px * var(--scale));
  top: 0;
  transition: transform ${SLIDE_BACK_TIME_MS}ms;
  ${(p) =>
    p.empty &&
    css`
      transform: translateY(calc(20px * var(--scale)));
    `}
  ${(p) =>
    p.moving &&
    css`
      transform: translateY(calc(15px * var(--scale)));
    `}
`

const EmojiContainer = styled.div`
  position: relative;
  max-width: calc(150px * var(--scale));
  max-height: calc(150px * var(--scale));
  margin: 0 auto;

  & svg {
    max-width: calc(150px * var(--scale));
    max-height: calc(150px * var(--scale));
  }
`
