import styled, { keyframes, css } from 'styled-components/macro'
import SceneWrapper from 'common/SceneWrapper'
import BackButton from 'common/BackButton'
import NextButton from 'common/NextButton'
import { softThumpHigh } from 'sounds'
import { useStore } from 'Store'
import React from 'react'

export default ({ pages, pageIndex, nextPage, options: { choices, bg }, answer, storeAnswer }) => {
  const isFirst = pages[pageIndex - 1]?.type === 'Chapter'
  const { audioEnabled } = useStore()
  const hasAnswer = Boolean(answer)

  return (
    <SceneWrapper bg={bg} buttonText={false}>
      <Options animateIn={isFirst}>
        {choices.map(choice => (
          <Option
            key={choice}
            onClick={() => {
              if (answer) return
              if (audioEnabled) new Audio(softThumpHigh).play()
              storeAnswer(choice)
              nextPage()
            }}
          >
            <InnerOption inactive={answer && answer !== choice}>{choice}</InnerOption>
          </Option>
        ))}
      </Options>
      <BottomRow>
        <BackButton animateIn={isFirst} />
        <NextButton animateIn={isFirst} disabled={!hasAnswer} />
      </BottomRow>
    </SceneWrapper>
  )
}

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Option = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  background-color: transparent;
  display: block;
  width: 50%;
  padding: calc(5px * var(--scale));
  text-align: center;
`

const InnerOption = styled.div`
  background-color: #50ecd1;
  border: calc(2px * var(--scale)) solid black;
  padding: calc(15px * var(--scale)) calc(20px * var(--scale));
  font-size: calc(25px * var(--scale));
  border-radius: 4px;

  ${p =>
    p.inactive &&
    css`
      background-color: white;
      border-color: #c3c3c3;
      color: #c3c3c3;
    `}
`

const Options = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 0;
  padding-bottom: calc(10px * var(--scale));
  left: 50%;
  width: calc(79.5% - 6.4%);
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Rubik, sans-serif;

  animation: ${keyframes`
    0% {
      transform: translateY(100%) translateX(-50%);
      filter: blur(4px);
    }
    100% {
      transform: translateY(0) translateX(-50%);
      filter: blur(0);
    }
  `} ${p => (p.animateIn ? '1s' : '0s')} forwards;
`
