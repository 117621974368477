import { useParams } from 'react-router-dom'
import { useAutoEffect } from 'hooks.macro'
import React from 'react'

export default Component => {
  return props => {
    const { character } = useParams()
    const [characterConfig, setCharacterConfig] = React.useState()
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const currentLanguage = params.la || 'NO'

    useAutoEffect(() => {
      let cancelled = false

      import(`config/${currentLanguage}/${character}`).then(module => {
        if (!cancelled) {
          setCharacterConfig(module)
        }
      })

      return () => {
        cancelled = true
      }
    })

    return <>{characterConfig && <Component {...props} characterConfig={characterConfig} />}</>
  }
}
