import templates from './templates'
import noConfig from './config/NO'
import enConfig from './config/EN'

export default function validateConfig() {
  if (process.env.NODE_ENV === 'development') {
    for (const config of [noConfig, enConfig]) {
      let usedIds = {}
      for (const { days } of config) {
        for (const { pages } of days) {
          for (const page of pages) {
            const { options, type, id } = page

            if (usedIds[id]) {
              throw Error(`Duplicate id: "${id}"`)
            }

            usedIds[id] = true

            if (!templates[type]) {
              console.log('Errored with:', page)
              throw Error(`Non-existant scene: "${type}"`)
            }

            switch (type) {
              case 'Dialogue':
                validateDialogue(options)
                break
              default:
                break
            }
          }
        }
      }
    }
  }
}

function validateDialogue(options) {
  if (!Array.isArray(options.conversation)) {
    console.log('Invalid options:', options)
    throw Error('Dialogue conversation must be an array')
  }

  for (const { name, text } of options.conversation) {
    if (typeof name !== 'string') throw Error('Name in conversation must be a string')
    if (typeof text !== 'string') throw Error('Text in conversation must be a string')
  }
}
