import useSetAutoCanceledTimeout from 'useSetAutoCanceledTimeout'
import styled, { keyframes, css } from 'styled-components/macro'
import { useAutoCallback, useAutoMemo } from 'hooks.macro'
import { softThumpHigh, success, failure } from 'sounds'
import SceneWrapper from 'common/SceneWrapper'
import Tipsrakett from 'common/Tipsrakett'
import NextButton from 'common/NextButton'
import BackButton from 'common/BackButton'
import { useStore } from 'Store'
import React from 'react'

export default ({
  options: {
    tipsrakett,
    verticallyCenter,
    bg,
    title,
    showWrong = false,
    subtitle,
    allowSeveral,
    choices,
    numberOfChoices = 1,
    textWhenWrong,
    compact,
  },
  storeAnswer,
  answer,
}) => {
  const [wronglySelected, setWronglySelected] = React.useState([])
  const { audioEnabled } = useStore()

  const hasWrong = useAutoMemo(choices.some((choice) => choice?.wrong))

  const isCompleted =
    (answer && numberOfChoices !== 'any' && answer.length >= numberOfChoices) ||
    (answer && numberOfChoices !== 'any' && answer.length >= 1 && allowSeveral)

  const isWrong = useAutoMemo(() => {
    const textToStatus = {}

    for (const choice of choices) {
      const text = choice.text || choice
      const wrong = Boolean(choice.wrong)
      textToStatus[text] = wrong
    }

    return (text) => textToStatus[text]
  })

  const setAutoCanceledTimeout = useSetAutoCanceledTimeout()

  const toggle = useAutoCallback((choice) => {
    if (audioEnabled) {
      if (!hasWrong) {
        new Audio(softThumpHigh).play().catch((e) => {})
      } else if (isWrong(choice)) {
        new Audio(failure).play().catch((e) => {})
      } else {
        new Audio(success).play().catch((e) => {})
      }
    }

    if (isWrong(choice)) {
      if (numberOfChoices === 1 || !isCompleted || showWrong) {
        if (!wronglySelected.includes(choice)) {
          setWronglySelected(wronglySelected.concat(choice))
          setAutoCanceledTimeout(() => {
            setWronglySelected((wronglySelected) => wronglySelected.filter((x) => x !== choice))
          }, 1000)
        }
      }
      return
    }

    storeAnswer((answer) => {
      if (!Array.isArray(answer)) return [choice]
      answer = answer.slice()
      const index = answer.indexOf(choice)
      if (index === -1) {
        if (numberOfChoices !== 'any' && answer.length >= numberOfChoices) {
          if (numberOfChoices === 1) {
            console.log('---', numberOfChoices)
            return [choice]
          }
          console.log('---')
          return answer
        }
        answer.push(choice)
        return answer
      }
      answer.splice(index, 1)
      return answer
    })
  })

  const [highlightWrong, setHighlightWrong] = React.useState(false)

  return (
    <SceneWrapper bg={bg}>
      <Backdrop />
      <Modal verticallyCenter={verticallyCenter} extraTopOffset={choices.length === 2}>
        <Title fontSize={title.fontSize}>{title.text}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Choices narrow={choices.length === 4} veryNarrow={choices.length === 2} compact={compact}>
          {choices.map((choice) => {
            const fontSize = choice.fontSize || null
            const text = choice.text || choice
            let isSelected = answer && answer.includes(text)
            const isWronglySelected = wronglySelected.includes(text)

            if (wronglySelected.length && numberOfChoices === 1) {
              isSelected = false
            }

            const highlight = isWronglySelected && highlightWrong

            return (
              <ChoiceWrapper
                key={text}
                divisor={choices.length === 4 ? 2 : 3}
                compact={choices.length > 6 || compact}
              >
                <Choice
                  isDisabled={
                    numberOfChoices !== 'any' &&
                    numberOfChoices > 1 &&
                    answer &&
                    answer.length >= numberOfChoices &&
                    !answer.includes(text)
                  }
                  useHeavyFocusOutline={
                    isCompleted && typeof numberOfChoices === 'number' && numberOfChoices > 1
                  }
                  isSelected={isSelected}
                  isWronglySelected={isWronglySelected}
                  textWhenWrong={textWhenWrong}
                  highlight={highlight}
                  fontSize={fontSize}
                  onClick={() => toggle(text)}
                >
                  {text}
                </Choice>
              </ChoiceWrapper>
            )
          })}
        </Choices>
        <BottomRow>
          <BackButton />
          {tipsrakett && <Tipsrakett />}
          <NextButton
            onDisabledClick={() => {
              if (hasWrong) {
                if (!answer || answer.length >= numberOfChoices) {
                  setHighlightWrong(true)
                }
              }
            }}
            disabled={
              !Array.isArray(answer) ||
              answer.filter((x) => !isWrong(x)).length <
                (numberOfChoices === 'any' || allowSeveral ? 1 : numberOfChoices)
            }
          />
        </BottomRow>
      </Modal>
    </SceneWrapper>
  )
}

const BottomRow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14%;
  padding: 0 calc(100px * var(--scale));
`

const Backdrop = styled.div`
  position: absolute;
  background-color: rgba(51, 118, 169, 0.4);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  animation: ${keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `} 1s forwards;
`

const Modal = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  padding: calc(60px * var(--scale));
  position: absolute;
  bottom: calc(40px * var(--scale));
  right: calc(40px * var(--scale));
  left: calc(40px * var(--scale));
  top: calc(40px * var(--scale));
  border-radius: calc(40px * var(--scale));

  ${(p) =>
    p.verticallyCenter &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
  animation: ${keyframes`
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  `} 0.5s forwards;

  ${(p) =>
    p.extraTopOffset &&
    css`
      padding-top: calc(100px * var(--scale));
    `}
`

const Title = styled.h2`
  font-size: calc(${(p) => p.fontSize}px * var(--scale));
  text-align: center;
  margin-top: calc(40px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
`

const Subtitle = styled.h2`
  font-size: calc(25px * var(--scale));
  text-align: center;
  margin-top: calc(-20px * var(--scale));
  margin-bottom: calc(30px * var(--scale));
  color: #959595;
`

const Choices = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(420px * var(--scale));

  ${(p) =>
    p.compact &&
    css`
      height: calc(320px * var(--scale));
    `}

  ${(p) =>
    p.narrow &&
    css`
      padding: 0 calc(200px * var(--scale));
    `}

  ${(p) =>
    p.veryNarrow &&
    css`
      justify-content: center;
      margin-top: calc(80px * var(--scale));
    `}
`

const ChoiceWrapper = styled.div`
  flex-basis: calc(100% / ${(p) => p.divisor});
  padding: calc(10px * var(--scale));
  display: flex;
  height: calc(200px * var(--scale));
  ${(p) =>
    p.compact &&
    css`
      height: calc(150px * var(--scale));
    `}
`

const Choice = styled.button.attrs((p) => ({
  type: 'button',
  tabIndex: p.isDisabled ? -1 : 0,
}))`
  background-color: #e8e3d7;
  font-size: calc(${(p) => p.fontSize || 30}px * var(--scale));
  flex-basis: 100%;
  border-radius: calc(10px * var(--scale));
  border: calc(4px * var(--scale)) solid transparent;
  cursor: pointer;
  width: 100%;
  padding: 0 calc(20px * var(--scale));
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: background-color .3s, border-color .3s;
  position: relative;
  ${(p) =>
    p.isDisabled &&
    css`
      background-color: #ddd;
    `}
  ${(p) =>
    p.isSelected &&
    css`
      border-color: #0a5b99;
    `}
  ${(p) =>
    p.isWronglySelected &&
    css`
      border-color: #c30000;
      background-color: #c300004d;
      ::after {
        content: '${(p) => p.textWhenWrong}';
        display: block;
        color: #c300004d;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: calc(10px * var(--scale));
        font-size: calc(15px * var(--scale));
        font-weight: bold;
      }
    `}
  ${(p) =>
    p.highlight &&
    css`
      animation: ${keyframes`
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    `} 0.5s infinite;
    `}

  :focus {
    outline: none;
  }

  ${(p) =>
    p.isSelected && !p.isDisabled
      ? css`
          :focus {
            border-color: #8eceff;
          }
        `
      : css`
          :focus {
            border-color: #aaa;
          }
        `}

  ${(p) =>
    p.useHeavyFocusOutline &&
    !p.isDisabled &&
    css`
      :focus {
        outline: 2px solid #666;
        outline-offset: 2px;
        border-radius: 10px;
      }
    `}

${(p) =>
  p.isDisabled &&
  css`
    border: none;
  `};
`
