import {
  DEFAULT_NUMBER_OF_STARS,
  useSetStarStyle,
  useSetChildrenStyle,
  useSetStarContainerStyle,
} from 'common/SceneWrapper'
import styled, { keyframes, css } from 'styled-components/macro'
import { Translate, Norwegian, English } from 'languageContext'
import { useAutoCallback, useAutoMemo } from 'hooks.macro'
import usePageState from 'Storyline/usePageState'
import storylineContext from 'Storyline/context'
import { softThump, failure } from 'sounds'
import { useConfig } from './SceneWrapper'
import sleep from 'sleep-promise'
import { useStore } from 'Store'
import useAudio from 'useAudio'
import React from 'react'

export default ({
  millisecondsDelay = 0,
  onBeforeNextPage,
  animateIn = true,
  disabled,
  overrideOnClick,
  onDisabledClick,
}) => {
  const { nextPage, isOnLastPage } = React.useContext(storylineContext)
  const setStarContainerStyle = useSetStarContainerStyle()
  const setChildrenStyle = useSetChildrenStyle()
  const playSoftThump = useAudio(softThump)
  const playFailure = useAudio(failure)
  const { audioEnabled } = useStore()
  const {
    options: { star },
    pageIndex,
    pages,
  } = usePageState()
  const setStarStyle = useSetStarStyle()
  const { buttonText } = useConfig()

  const numberOfStars = useAutoMemo(() => {
    let numberOfStars = DEFAULT_NUMBER_OF_STARS || 0
    for (let i = 0; i < pages.length && i < pageIndex; i++) {
      if (pages[i].options.star) {
        numberOfStars += 1
      }
    }
    return numberOfStars
  })

  const playSound = useAutoCallback(() => {
    if (typeof overrideOnClick === 'function') return
    if (!audioEnabled) return
    playSoftThump()
  })

  const possiblyDelayedNextPage = useAutoCallback(async () => {
    if (typeof overrideOnClick === 'function') {
      overrideOnClick()
      return Promise.resolve()
    }

    if (typeof onBeforeNextPage === 'function') {
      onBeforeNextPage()
    }

    if (star) {
      try {
        document.body.style.pointerEvents = 'none'

        // MAKE STAR LARGE
        setChildrenStyle({
          transition: 'opacity 0.5s, background-color 0.5s',
          animation: 'none',
          opacity: '1',
        })
        setStarStyle({
          transition: 'transform 0.5s',
          transform: `
          scale(10)
        `,
        })
        setStarContainerStyle({
          transition: 'transform 0.25s',
          transform: `translateY(calc(300px * var(--scale)))`,
        })
        await sleep(1000)

        // FADE OUT BACKGROUND
        setChildrenStyle({
          transition: 'opacity 0.4s, background-color 0.4s',
          animation: 'none',
          opacity: '0',
        })
        await sleep(400)

        // MOVE STAR UP AND MAKE SMALL
        setStarContainerStyle({
          transition: 'transform 0.4s',
          transform: `
          translateY(calc(-42px * var(--scale)))
          translateX(calc(-420px * var(--scale)))
          translateX(calc(${numberOfStars} * 44.0px * var(--scale)))
        `,
        })
        setStarStyle({
          transition: 'transform 0.4s linear',
          transform: `
          scale(1)
          rotate(var(--rotation))
        `,
        })
        await sleep(1000)
        setChildrenStyle(null)
        setStarStyle(null)
        setStarContainerStyle(null)
      } finally {
        document.body.style.pointerEvents = null
      }
    }

    if (millisecondsDelay) {
      await new Promise(resolve => {
        setTimeout(() => resolve(nextPage()), millisecondsDelay)
      })
    } else {
      await nextPage()
    }
  })

  return (
    <Button
      isDisabled={disabled}
      isOnLastPage={isOnLastPage}
      onClick={event => {
        if (isOnLastPage || disabled) {
          if (typeof onDisabledClick === 'function') {
            onDisabledClick(event)
          }
          if (audioEnabled) {
            playFailure()
          }
        } else {
          playSound()
          possiblyDelayedNextPage()
        }
      }}
      animateIn={animateIn}
    >
      <Scaler>
        {buttonText && (
          <Text>
            <Translate>
              <Norwegian>neste</Norwegian>
              <English>next</English>
            </Translate>
          </Text>
        )}
        <ArrowImage isDisabled={disabled} isOnLastPage={isOnLastPage} />
      </Scaler>
    </Button>
  )
}

const ArrowImage = styled.img.attrs(p => ({
  src: '/media/large/interface/next-button.png',
  alt: '',
}))`
  width: 73px; /* IE11 fallback */
  width: calc(73px * var(--scale));
  height: 66px; /* IE11 fallback */
  height: calc(66px * var(--scale));

  ${p =>
    p.isDisabled &&
    css`
      transform: translate3d(0, 0, 0);
      filter: grayscale(1);
      opacity: 0.5;
    `}

  ${p =>
    p.isOnLastPage &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`

const Button = styled.button.attrs({
  title: 'Neste',
  type: 'button',
})`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  font-size: calc(30px * var(--scale));
  font-family: 'Walter turncoat', sans-serif;

  animation: ${keyframes`
    0% {
      transform: translateX(calc(30px * var(--scale)));
    }
    100% {
      transform: translateX(0);
    }
  `} ${p => (p.animateIn ? '0.5s' : '0s')} forwards;

  :focus {
    outline: none;
  }
`

const Text = styled.span`
  transform: translateY(calc(-4px * var(--scale)));
`

const Scaler = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.4s, filter 0.4s;
  transform: translate3d(0, 0, 0);
  ${Button}:focus & {
    transform: translate3d(0, 0, 0) scale(var(--focus-scale));
    filter: var(--focus-filter);
  }
`
