import { useAutoMemo, useAutoCallback, useAutoEffect } from 'hooks.macro'
import usePersistentState from './usePersistentState'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import useIsMobile from './useIsMobile'
import React, { useState } from 'react'
import Storyline from './Storyline'
import StoreContext from 'Store'
import Menu from './Menu/Menu'
import average from 'average'
import Atom from './Atom'
import emit from 'emit'

let canvasQueries = []
const BASE_WIDTH = 1280
const BASE_HEIGHT = 800
const STEP = 10

const verySlightScale = scale => average(scale, 1, 1, 1)
const slightScale = scale => Math.min(average(scale, 1), 1)
const moderateScale = scale =>
  Math.min(average(scale, scale, scale, scale, scale, scale, scale, 1), 1)

for (let size = 50000; size >= 0; size -= STEP) {
  {
    const width = size
    let scale = width / BASE_WIDTH
    let height = BASE_HEIGHT * scale

    canvasQueries.push(`
      @media (max-width: ${width + STEP}px) and (min-height: ${height}px) {
        .game-canvas {
          --very-slight-scale: ${verySlightScale(scale)};
          --slight-scale: ${slightScale(scale)};
          --moderate-scale: ${moderateScale(scale)};
          --scale: ${scale};
          width: ${width}px;
          height: ${height}px;
        }
      }
    `)
  }
  {
    const height = size
    const scale = height / BASE_HEIGHT
    const width = BASE_WIDTH * scale

    canvasQueries.push(`
      @media (max-height: ${height + STEP}px) and (min-width: ${width}px) {
        .game-canvas {
          --slight-scale: ${slightScale(scale)};
          --scale: ${scale};
          width: ${width}px;
          height: ${height}px;
        }
      }
    `)
  }
}

// XXX: This ground styled-components to a halt,
//      so I went back to basics.
const style = document.createElement('style')
style.innerHTML = canvasQueries.join('\n')
document.body.appendChild(style)

//styled components

const Container = styled.div``

const GameCanvas = styled.div`
  height: ${BASE_HEIGHT}px;
  width: ${BASE_WIDTH}px;
  max-width: 100%;
  max-height: 100%;
  background-color: ${p => (p.white ? 'white' : 'lightgray')};
  display: flex;
  margin: auto;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform-origin: 50% 0;
  border-radius: 4px;
  --scale: 1;
`

function Game() {
  const isMobile = useIsMobile()

  //set game canvas ref
  const [gameRef, setRef] = useState(null)

  //import different assets depending on mobile or desktop
  const media = useAutoCallback(
    str => `${process.env.PUBLIC_URL}/media/${isMobile ? 'small' : 'large'}/${str}`,
  )
  const interfacePNG = useAutoCallback(filename => media(`interface/${filename}.png`))
  const charPNG = useAutoCallback((char, mood) =>
    media(`characters/${char}-${mood || 'neutral'}.png`),
  )

  const [audioFailed, setAudioFailed] = React.useState(false)
  const [audioEnabled, setAudioEnabled] = usePersistentState(
    true,
    'audioEnabled',
    enabled => enabled !== null,
  )
  const [color, setColor] = useState('blue')
  const [lang, setLang] = useState('en')

  const [hasStarted, setHasStarted] = React.useState(false)

  const start = useAutoCallback(() => setHasStarted(true))

  const toggleAudio = useAutoCallback(() => {
    setAudioEnabled(x => !x)
    setHasStarted(true)
  })

  const store = useAutoMemo(() => ({
    setAudioEnabled,
    setAudioFailed,
    setHasStarted,
    audioEnabled,
    interfacePNG,
    audioFailed,
    toggleAudio,
    hasStarted,
    setColor,
    gameRef,
    charPNG,
    setLang,
    color,
    media,
    start,
    lang,
  }))

  useAutoEffect(() => {
    emit('Åpnet siden')
  })

  useAutoEffect(() => {
    let active = false
    let inactive = 0

    const onClick = () => {
      active = true
    }

    document.addEventListener('click', onClick)

    const interval = setInterval(() => {
      if (active) {
        inactive = 0
        active = false
        emit('Klikk')
      } else {
        inactive += 10

        // If the user is inactive for
        // twenty minutes, register it
        if (inactive === 20 * 60) {
          emit('Inaktiv')
        }
      }
    }, 10000)

    return () => {
      document.removeEventListener('click', onClick)
      clearInterval(interval)
    }
  })

  const loading = !gameRef

  return (
    <StoreContext.Provider value={store}>
      <Container>
        <GameCanvas ref={setRef} white={loading} className="game-canvas">
          <>
            {loading ? (
              <Atom />
            ) : (
              <>
                <Switch>
                  <Route path="/spill/:character">
                    <Storyline />
                  </Route>
                  <Route path="/">
                    <Menu />
                  </Route>
                </Switch>
              </>
            )}
          </>
        </GameCanvas>
      </Container>
    </StoreContext.Provider>
  )
}

export default Game
