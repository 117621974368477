import { useSetCompletedCharacters } from 'completedCharactersContext'
import SceneWrapper, { CollectedStars } from 'common/SceneWrapper'
import { Translate, Norwegian, English } from 'languageContext'
import { useParams, useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { useAutoLayoutEffect } from 'hooks.macro'
import React from 'react'

export default ({ pageIndex, pages, options: { bg, title, subtitle, overflow, download } }) => {
  const setCompletedCharacters = useSetCompletedCharacters()
  const { character } = useParams()
  const history = useHistory()

  useAutoLayoutEffect(() => {
    setCompletedCharacters(characters => ({
      ...characters,
      [character]: true,
    }))
  })

  return (
    <SceneWrapper
      hideSpaceShip
      bgColor="#9cbed6"
      backgroundBlendMode="multiply"
      overflow={overflow}
    >
      <CharacterSelectTextBoxImage />
      <TextBox>
        <CollectedStars />
        <Row>
          {Boolean(download) && (
            <Download href={`/media/large/interface/${download}`} download={`${download}`}>
              <Translate>
                <Norwegian>Last ned bilde for å fargelegge</Norwegian>
                <English>Download the picture to colour it in</English>
              </Translate>
            </Download>
          )}
          <Button
            onClick={() =>
              history.push({ pathname: `/velg-karakter`, search: history.location.search })
            }
          >
            <Translate>
              <Norwegian>Tilbake til hovedmenyen</Norwegian>
              <English>Back to the main menu</English>
            </Translate>
          </Button>
        </Row>
      </TextBox>
      <BottomBar>
        <div>
          <img
            className="eu"
            src="/EU.png"
            alt="Co-financed by the European Union. Connecting Europe Facility."
          />
          <img className="medietilsynet" src="/medietilsynet-hvit.png" alt="Medietilsynet" />
        </div>
      </BottomBar>
    </SceneWrapper>
  )
}

const CharacterSelectTextBoxImage = styled.img.attrs({
  src: '/media/large/interface/character-select-textbox.png',
  alt: '',
})`
  width: calc(642px * var(--scale));
  height: calc(264px * var(--scale));
  position: absolute;
  bottom: calc(100px * var(--scale));
  left: 50%;
  transform: translateX(-50%);
`

const TextBox = styled.div`
  position: absolute;
  bottom: calc((15px + 20px) * var(--scale));
  width: calc((642px - 40px) * var(--scale));
  height: calc((370px - 40px) * var(--scale));
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.div`
  font-size: calc(30px * var(--scale));
  font-weight: 500;
`

const Subtitle = styled.div`
  font-size: calc(23px * var(--scale));
  font-weight: 500;
`

const Row = styled.div`
  display: flex;
  padding-left: calc(20px * var(--scale));
  padding-right: calc(20px * var(--scale));
`

const btn = css`
  background-color: #48f5cd;
  border: calc(2px * var(--scale)) solid black;
  font-size: calc(20px * var(--scale));
  font-family: 'Walter turncoat', sans-serif;
  line-height: 1;
  padding: calc(10px * var(--scale)) calc(15px * var(--scale));
  border-radius: calc(10px * var(--scale));
  filter: drop-shadow(calc(0px * var(--scale)) calc(4px * var(--scale)) rgba(0, 0, 0, 0.5));
  margin: calc(10px * var(--scale));
  flex-basis: 100%;
  color: black;
  cursor: pointer;
`

const Button = styled.button.attrs({
  type: 'button',
})`
  ${btn};
`

const Download = styled.a`
  text-decoration: none;
  ${btn};
`

const BottomBar = styled.div`
  background-color: rgba(10, 91, 153, 0.79);
  height: calc(58px * var(--scale));
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    height: 100%;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      vertical-align: top;
      object-fit: contain;

      &.eu {
        width: calc(224px * var(--scale));
        height: calc(26px * var(--scale));
      }

      &.medietilsynet {
        width: calc(216px * var(--scale));
        height: calc(65px * var(--scale));
        transform: translateY(calc(-0px * var(--scale)));
      }
    }
  }
`
