export default `
# Chapter
$ bg = barn-klasserom

title = Tirsdag
subtitle = På skolen

# Dialogue
[Noah flip neutral left 220]
[Limal neutral right 780]
Limal: Hei Noah!

[Noah happy]
Noah: På lørdag åpner det en butikk som...
Noah: selger ting fra MåneSverdet.

[Limal happy]
Limal: Det er så kult!
Noah: Og vi skal dra sammen dit!
Limal: Ja, moren din sa det til mamma i går.
Noah: Åh, jeg gleder meg!

# Chapter
$ bg = mira-soverom

title.text = Hjemme hos Noah
title.fontSize = 45

# Dialogue
[Noah confused flip 500]
Noah: Ingen er hjemme ennå.
Noah: Miras datamaskin er på.
Noah: Lurer på hva hun driver med?

# Dialogue
$ bg(json) = {
  image: 'mira-data',
  contain: true,
  color: '#bcbcbc',
}
Noah: Hva er Ding-passordet hennes?

# CorrectPassword
choices(list) = iloveyou, apekatt, army, prinsesse

# Dialogue
$ bg = mira-data-riktig-passord
Noah: Wow, det var lett!

$ bg = mira-soverom
[Noah happy flip 500]
Noah: Hehe, jeg er smart.

[Noah confused]
Noah: Ding-kontoen hennes er kjedelig.

[Noah angry]
Noah: Bare selfies og sminkebilder. Æsj!

# Dialogue
$ bg = menneske-stue
[Sara neutral 680 right]
Sara: Hei hei! Jeg er hjemme.

[Noah left 220 flip surprised]
Noah: Jeg gjettet Miras Ding-passord!

[Noah happy]
[Sara surprised]
Sara: Har du vært inne på Miras Ding-konto?

[Noah neutral]
Noah: Ja. Det var bare kjedelig, og litt ekkelt.

[Sara angry]
Sara: Sånt er ikke lov, Noah!

[Noah surprised]
Noah: Men passordet hennes var så lett!
Sara: Lett eller ikke. Det er ikke lov!

[Noah sad]
Noah: Ok da... Jeg skal ikke gjøre det igjen.

# Text
paragraphs(json) = [
'Mira har et litt for enkelt passord. Denne gangen var det Noah som gjettet det, men hvis det hadde vært noen andre kunne det gått værre.',
'På neste side skal du lage et bra og sikkert passord til Mira.',
]

# CreatePassword
star = true
words(list) = Synge, 28, !, ferie, Sjokolade, _, 100, +, 15, bursdag, Super, 03, stjerne, ?, 85, danse, Kattepus, best, =, søt
title.fontSize = 30
title.text = Velg ord og bygg et sikkert passord

tipsrakett.lines(json) = [
'Det er lettere å huske setninger enn vilkårlige kombinasjoner av bokstaver og tall. Passordet bør ha minst 5 ord eller 16 tegn, inneholde tall, symboler, mellomrom og små/store bokstaver. Tallene bør ikke være fødselsdato eller andre gjenkjennbare tall. Man bør ha forskjellige passord på ulike tjenester.',
'Skriv gjerne ned passordene dine',
'Det er helt i orden å notere ned passord. Det er viktigere at man har gode og unike passord på alle tjenester enn at man er i stand til å huske alle sammen. Skriv passordene ned på et papir som du lagrer på et trygt sted. De passordene du bruker ofte kan du med fordel memorere. Det er ikke lurt å skrive passordene på en lapp som ligger på skrivebordet eller i vesken/sekken, eller lagre passordene i et dokument på datamaskinen.'
]
`
