import { useAutoCallback, useAutoEffect } from 'hooks.macro'
import React from 'react'

/**
 * Auto-cancel setTimeout when unmounting.
 * This will have a memory leak when
 * called an absurd number of times.
 */
export default function useAutoCanceledTimeout() {
  const ids = useConstant(() => [])

  const autoCanceledTimeout = useAutoCallback((fn, time) => {
    const id = setTimeout(() => {
      return fn()
    }, time)
    ids.push(id)
    return id
  })

  useAutoEffect(() => {
    return () => {
      for (const id of ids) {
        clearTimeout(id)
      }
    }
  })

  return autoCanceledTimeout
}

function useConstant(fn) {
  const ref: any = React.useRef()

  if (!ref.current) {
    ref.current = {
      value: fn(),
    }
  }

  return ref.current.value
}
