import transformConfig from 'transformConfig'
import sunday from './sunday'
import monday from './monday'
import tuesday from './tuesday'
import wednesday from './wednesday'
import thursday from './thursday'
import friday from './friday'
import saturday from './saturday'

export const name = 'Noah'

// prettier-ignore
export const days = [
  {
    title: 'Sunday',
    subtitle: 'Noah is out shopping with Sara',
    pages: transformConfig('noah_sunday', sunday),
  },
  {
    title: 'Monday',
    subtitle: 'Noah has a hard time on Spacebook',
    pages: transformConfig('noah_monday', monday),
  },
  {
    title: 'Tuesday',
    subtitle: 'Noah discovers that not everyone is good at creating passwords',
    pages: transformConfig('noah_tuesday', tuesday),
  },
  {
    title: 'Wednesday',
    subtitle: 'Noah watches a film with Mira',
    pages: transformConfig('noah_wednesday', wednesday),
  },
  {
    title: 'Thursday',
    subtitle: 'Noah and Limal open loot boxes',
    pages: transformConfig('noah_thursday', thursday),
  },
  {
    title: 'Friday',
    subtitle: 'Noah and Sara talk about screen time',
    pages: transformConfig('noah_friday', friday),
  },
  {
    title: 'Saturday',
    subtitle: 'Noah and Limal visit the Moon Sword shop',
    pages: transformConfig('noah_saturday', saturday),
  },
]
