export default `
# Chapter
$ bg = barn-klasserom
title = Tirsdag
subtitle = På skolen

# Dialogue
[Luna neutral left 250 z=2]
[Bix neutral right 680]
Bix: Jeg så at det teite bildet ble tatt bort!

[Luna happy]
Luna: Ja, pappa ordnet det.

[Bix surprised up=true]
Bix: Men vet du hva eller?
Bix: Søsteren min er sur på broren din.

[Luna surprised up=true]
Luna: Hva har skjedd?

[Bix angry up=false]
Bix: Han var slem mot henne på Ding.

[Bix surprised up=true]
Bix: Hun gråt masse. Se hva han skrev!

[Lærer id=safran neutral 110 left]
[Bix 780]
[Luna 421]
Lærer: Sett dere ned, nå begynner timen.

# Chapter
$ bg = neptunianer-stue
title = Hjemme hos Luna

# Dialogue
[Luna angry left 250]
[Galea neutral right 680]
Luna: Mamma! Timo er slem!

[Galea surprised]
Galea: Er han det? Hva har han gjort?
Luna: Han var slem mot søsteren til Bix...
Luna: ...på Ding-gruppa til klassen.
Galea: Javel? Jeg skal snakke med ham.

[Luna happy music=true]
Luna: Tiiiimooo skal få kjeft...

[Galea angry]
Galea: Hysj, ikke ert. Jeg fikser dette.

# Slider
title(json) = {
  text:
    'Timo var slem mot søsteren til Bix på nettet. Har du sett eller hørt om noen som har mobbet eller vært slemme på nettet?',
  fontSize: 50,
}

labels(json) = ['Aldri', 'Noen ganger', 'Mange ganger', 'Hele tiden']

# Discuss
star = true
paragraphs(json) = [
  'Hva synes du foreldrene bør gjøre når noen er slemme på nettet?'
]

`
