export default `
# Chapter
$ bg = generisk-gang
title = Sunday 
subtitle = At the birthday party

# Dialogue
[Luna neutral left 300]
[Limal happy right 650]
Limal: Welcome to my party.
Luna: Happy birthday, Limal!
Limal: Would you like something to eat?

# ChooseImage
title.text = What should Luna eat?
title.fontSize = 50
help = Press one option and then the NEXT button
choices(json) = [ 
  {
    text: 'Chocolate cake',
    image: '/media/luna-food/chocolate.png',
  },
  {
    text: 'Cream cake',
    image: '/media/luna-food/kake.png',
  },
  {
    text: 'Taco',
    image: '/media/luna-food/taco.png',
  },
  {
    text: 'Pizza',
    image: '/media/luna-food/pizza.png',
  },
  {
    text: 'Hot dog',
    image: '/media/luna-food/hotdog.png',
  },
  {
    text: 'Crisps',
    image: '/media/luna-food/potetgull.png',
  },
]

# Dialogue
[Luna neutral left 250]
[Edan neutral right 700]
Edan: Luna, it’s late. We have to go home.
Luna: Ok, Dad. Bye, Limal!

[Edan 500]
[Limal 850 right neutral]
Limal: Bye! See you at school.
`
