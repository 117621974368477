import { useAutoMemo } from 'hooks.macro'
import { interpolate } from 'flubber'
import React from 'react'

const cache = new Map()

export default function AnimatedPath({ from, to, fill, step }) {
  const [path, setPath] = React.useState(null)

  const interpolator = useAutoMemo(() => createInterpolator(from, to))
  const d = useAutoMemo(() => interpolator(step))

  return <path ref={setPath} fill={fill} d={d} />
}

function createInterpolator(from, to) {
  if (!cache.has(from)) cache.set(from, new Map())
  if (!cache.get(from).has(to)) {
    cache.get(from).set(
      to,
      interpolate(from, to, {
        maxSegmentLength: 1,
      }),
    )
  }
  return cache.get(from).get(to)
}
