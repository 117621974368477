import { currentLanguage, Translate, Norwegian, English } from 'languageContext'
import { useCompletedCharacters } from 'completedCharactersContext'
import styled, { css } from 'styled-components/macro'
import LanguageButton from '../common/LanguageButton'
import SpaceBackground from './SpaceBackground'
import { useHistory } from 'react-router-dom'
import { availableCharacters } from 'config/NO'
import { useAutoEffect } from 'hooks.macro'
import HamburgerMenu from './HamburgerMenu'
import { useAutoMemo } from 'hooks.macro'
import StoreContext from 'Store'
import storage from '../storage'
import ucfirst from 'ucfirst'
import React from 'react'
import Logo from './Logo'
import emit from 'emit'

const charDescriptionsNO = {
  luna: {
    age: '7',
    race: 'Neptunianer',
    notes: 'Personvern, passord, oppførsel på nett, aldersgrenser på spill, skjermtid',
  },
  timo: {
    age: '13',
    info: 'Lunas bror',
    race: 'Neptunianer',
    notes: 'Fremmede online, teknologiske hjelpemidler, oppførsel på nett, skjermtid',
  },
  edan: {
    info: 'Lunas pappa',
    race: 'Neptunianer',
    notes: 'Personvern, passord, vennskap på nett, holdninger hos foreldre',
  },
  noah: {
    age: '7',
    race: 'Menneske',
    notes: 'Tilgang til sosiale medier, aldersgrense film, passord, lootboks, skjermtid',
  },
  sara: {
    race: 'Menneske',
    info: 'Noahs mamma',
    notes: 'Tilgang på sosiale medier, aldersgrense på film, lootboks, skjermtid',
  },
  mira: {
    age: '16',
    info: 'Noah søster',
    race: 'Menneske',
    notes: 'Online læring, oppførsel på nett, reklame',
  },
}

const charDescriptionsEN = {
  luna: {
    age: '7',
    race: 'Neptunian',
    notes: 'Data protection, passwords, online behaviour, age rating for games, screen time',
  },
  timo: {
    age: '13',
    info: 'Luna’s brother',
    race: 'Neptunian',
    notes: 'Strangers online, technological aids, online behaviour, screen time',
  },
  edan: {
    info: 'Luna’s dad',
    race: 'Neptunian',
    notes: 'Privacy, passwords, friendship on internet, attitudes of parents',
  },
  noah: {
    age: '7',
    race: 'Human',
    notes: 'Social media access, age rating for films, passwords, loot boxes, screen time',
  },
  sara: {
    race: 'Human',
    info: 'Noah’s mum',
    notes: 'Access to social media, age limits on film, lootboxes, screen time',
  },
  mira: {
    age: '16',
    info: 'Noah’s sister',
    race: 'Human',
    notes: 'Online learning, online behaviour, advertising',
  },
}

const Char = ({ name, chosen, chooseChar }) => {
  const completedCharacters = useCompletedCharacters()
  const { interfacePNG } = React.useContext(StoreContext)
  const big = name === 'luna' || name === 'noah'

  const isAvailable = useAutoMemo(() => {
    if (availableCharacters.includes(name)) return true
    if (name === 'luna' || name === 'noah') return true
    return completedCharacters['luna'] || completedCharacters['noah']
  })

  return (
    <CharContainer onClick={() => chooseChar(chosen)} tabIndex={1}>
      <Circle big={big} chosen={chosen} />
      <ImgContainer big={big}>
        <NametagBack big={big} />
        <CharacterImage
          transparent={chosen}
          src={`/media/large/characters/${name}-neutral.png`}
          alt={name + '-inactive'}
          name={name}
          big={big}
        />
        <CharacterImage
          transparent={!chosen}
          src={`/media/large/characters/${name}-happy.png`}
          alt={name + '-chosen'}
          name={name}
          big={big}
        />
        <NametagFront />
        <Name>{name}</Name>
        {!isAvailable && <LockImg alt="" src={interfacePNG('lock')} />}
      </ImgContainer>
    </CharContainer>
  )
}

const CharacterMenu = () => {
  const completedCharacters = useCompletedCharacters()
  const [charIndex, setCharIndex] = React.useState(null)
  const { start, interfacePNG } = React.useContext(StoreContext)
  const characters = ['edan', 'luna', 'noah', 'sara', 'timo', 'mira']
  const history = useHistory()
  const charDescriptions = currentLanguage === 'NO' ? charDescriptionsNO : charDescriptionsEN

  const cannotPlayYet = useAutoMemo(() => {
    const allAvailable = completedCharacters['luna'] || completedCharacters['noah']
    if (allAvailable) return {}
    const result = {}
    for (const name of characters) {
      if (name === 'noah') continue
      if (name === 'luna') continue
      result[name] = true
    }
    return result
  })

  const chooseChar = i =>
    setCharIndex(prev => {
      if (prev === i) return null
      return i
    })

  if (charIndex != null) {
    var selected = {
      id: characters[charIndex],
      name: ucfirst(characters[charIndex]),
      ...charDescriptions[characters[charIndex]],
    }
  }

  useAutoEffect(() => {
    if (!selected) return

    if (storage.has(`${selected.id}.pageIndex`)) {
      storage.delete(`${selected.id}.pageIndex`)
    }
  })

  return (
    <SpaceBackground noPlanets>
      <Logo />
      <CharacterMenuContainer>
        {characters.map((name, i) => (
          <React.Fragment key={name}>
            <Char chosen={charIndex === i} chooseChar={() => chooseChar(i)} name={name} />
            {/* We want the text interface to come after the fifth (index=4) character */}
            {i === 4 && (
              <TextOuter backgroundImg={interfacePNG('character-select-textbox')}>
                <TextInner>
                  {charIndex == null && (
                    <>
                      <Translate>
                        <Norwegian>
                          <h1>Velg en karakter!</h1>
                          <p>
                            (Spill Luna eller Noah først, så vil de andre karakterene bli låst opp.)
                          </p>
                        </Norwegian>
                        <English>
                          <h1>Choose a character!</h1>{' '}
                        </English>
                      </Translate>
                    </>
                  )}
                  {charIndex != null && (
                    <>
                      <h1>
                        {selected.name}
                        {Boolean(selected.age) &&
                          ' ' + selected.age + (currentLanguage === 'NO' ? ' år' : ' years')}
                        {Boolean(selected.info) && ', ' + selected.info},{' '}
                        <small>{selected.race}</small>
                      </h1>
                      {cannotPlayYet[selected.id] && (
                        <>
                          <p style={{ color: 'red' }}>
                            <Translate>
                              <Norwegian>
                                (Spill Luna eller Noah først, så vil de andre karakterene bli låst
                                opp.)
                              </Norwegian>
                              <English>(Play Luna or Noah first to unlock this character.)</English>
                            </Translate>
                          </p>
                        </>
                      )}
                      {!cannotPlayYet[selected.id] && (
                        <>
                          <p>{selected.notes}</p>
                          <StartLink
                            onClick={() => {
                              start()
                              if (storage.has(selected.id)) {
                                storage.delete(selected.id)
                              }
                              history.push({
                                pathname: `/spill/${selected.id}`,
                                search: history.location.search,
                              })
                              emit('Start-klikk')
                            }}
                            tabIndex={0}
                          >
                            Start
                          </StartLink>
                        </>
                      )}
                    </>
                  )}
                </TextInner>
              </TextOuter>
            )}
          </React.Fragment>
        ))}
      </CharacterMenuContainer>
      <LanguageButton />
      <HamburgerMenu />
    </SpaceBackground>
  )
}

export default CharacterMenu

const CharacterMenuContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  width: 100%;
  padding: calc(50px * var(--scale));
  padding-top: calc(220px * var(--scale));
  justify-content: space-between;
  align-items: center;
`

// ----- character ----- //

const CharContainer = styled.button.attrs({
  type: 'button',
})`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 0%;
  width: 22%;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding-top: 22%;
  pointer-events: auto;
  :focus {
    outline: none;
  }
`

const ImgContainer = styled.div`
  width: calc(248px * var(--scale));
  position: relative;
  z-index: 2;
  height: 100%;
  transform: ${p => (p.big ? 'scale(1)' : 'scale(0.7)')};
  pointer-events: none;
`
const Img = styled.img`
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 250ms;
  opacity: ${p => (p.transparent ? 0 : 1)};
  transition-timing-function: ease-out;
  width: 100%;
`

const Circle = styled.div`
  transition: opacity 250ms, box-shadow 250ms;
  transition-timing-function: ${p => (p.chosen ? 'ease-out' : 'ease-in')};
  border-radius: calc(230px * var(--scale));
  height: calc(248px * var(--scale));
  width: calc(248px * var(--scale));
  background-color: white;
  opacity: 0.2;
  position: absolute;
  z-index: 1;
  transform: scale(0.7) translateY(calc(26px * var(--scale)));
  bottom: calc(0px * var(--scale));
  ${p =>
    p.big &&
    css`
      bottom: calc(${p => (p.luna ? 9 : 11)}px * var(--scale));
      left: calc(${p => (p.luna ? 10 : 5)}px * var(--scale));
      transform: translateX(calc(1px * var(--scale)));
    `}
  ${CharContainer}:focus & {
    opacity: 0.5;
    box-shadow: 0 0 calc(30px * var(--scale)) calc(-8px * var(--scale)) white;
  }
  ${p =>
    p.chosen &&
    css`
      opacity: 1 !important;
      box-shadow: 0 0 calc(30px * var(--scale)) calc(-8px * var(--scale)) white !important;
    `}
`

const TextOuter = styled.div`
  background-image: url(${p => p.backgroundImg});
  background-size: contain;
  position: relative;
  height: calc(264px * var(--scale));
  width: calc(642px * var(--scale));
  padding: calc(25px * var(--scale));
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(calc(20px * var(--scale)));
`

const TextInner = styled.div`
  text-align: center;
  padding-bottom: calc(30px * var(--scale));
  max-width: 90%;

  & h1 {
    margin-bottom: calc(8px * var(--scale));
    font-size: calc(30px * var(--scale));

    & small {
      font-weight: normal;
      font-size: 0.7em;
    }
  }

  & p {
    font-size: calc(24px * var(--scale));
  }
`

const StartLink = styled.button.attrs({
  type: 'button',
})`
  font-family: 'Walter Turncoat', sans-serif;
  background-image: url('/media/large/interface/start-button.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: calc(150px * var(--scale));
  height: calc(47px * var(--scale));
  text-decoration: none;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: calc(24px * var(--scale));
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(20px * var(--scale));
  margin-bottom: calc(-47px * var(--scale));

  ::after {
    content: '';
    background-image: url('/media/large/interface/start-button-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: calc(26px * var(--scale));
    height: calc(23px * var(--scale));
    display: block;
    margin-left: calc(10px * var(--scale));
  }
`

const LockImg = styled.img`
  position: absolute;
  top: calc(100% - calc(5px * var(--scale)));
  left: 50%;
  transform: translateX(-50%);
  width: calc(29px * var(--scale));
  height: calc(40px * var(--scale));
`

const NametagBack = styled(Img).attrs({
  src: '/media/large/character-menu/nametag-back.png',
  alt: '',
})`
  width: calc(217px * var(--scale) * 1.05);
  height: calc(56px * var(--scale) * 1.05);
  transform: translateX(calc(12px * var(--scale))) translateY(calc(-12px * var(--scale)));

  ${p =>
    !p.big &&
    css`
      width: calc(217px * var(--scale));
      height: calc(56px * var(--scale));
      transform: translateX(calc(11px * var(--scale))) translateY(calc(-23px * var(--scale)));
    `}
`
const NametagFront = styled(Img).attrs({
  src: '/media/large/character-menu/nametag-front.png',
  alt: '',
})`
  width: calc(179px * var(--scale));
  height: calc(56px * var(--scale));
  transform: translateX(calc(40px * var(--scale))) scale(1.2);
`

const CharacterImage = styled(Img)`
  ${p =>
    !p.big &&
    css`
      transform: scale(0.8);
    `}

  ${p =>
    p.name === 'noah' &&
    css`
      transform: translateY(calc(-32px * var(--scale)));
    `}

  ${p =>
    p.name === 'luna' &&
    css`
      transform: translateY(calc(-20px * var(--scale)));
    `}
`

const Name = styled.div`
  position: absolute;
  height: calc(50px * var(--scale));
  bottom: calc(5px * var(--scale));
  left: 0;
  right: 0;
  text-align: center;
  text-transform: capitalize;
  font-family: 'Walter Turncoat', sans-serif;
  font-size: calc(30px * var(--scale));
  display: flex;
  align-items: center;
  justify-content: center;
`
