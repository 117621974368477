export default `
bg = generisk-gang

# Chapter
title = Søndag
subtitle = På kjøpesenteret

# Dialogue
[Noah neutral left 250]
[Sara neutral right 680]
Noah: Hva skal vi kjøpe, Mamma?

[Sara happy]
Sara: Du trenger ny jakke, Noah.

[Mira happy right 800]
[Sara 515]
Mira: Velg en kul en, a!

# ChooseImage
title(json) = { text: 'Velg en jakke for Noah', fontSize: 30 }
help = Trykk på en av jakkene og så på neste-knappen
choices(json) = [
  {
    image: '/media/noah-jackets/red.png',
  },
  {
    image: '/media/noah-jackets/blue.png',
  },
  {
    image: '/media/noah-jackets/army.png',
  },
  {
    image: '/media/noah-jackets/purple.png',
  },
  {
    image: '/media/noah-jackets/yellow.png',
  },
  {
    image: '/media/noah-jackets/refleks.png',
  },
]

# Dialogue
[Noah neutral left 250]
[Sara neutral right 515]
[Mira surprised right 800]
Mira: Woah, den var kul!

[Noah happy]
[Sara happy]
Noah: Var den jeg likte best.
Sara: Da kan vi dra hjem.
`
