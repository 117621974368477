import { cloneElement, Children } from 'react'
import React from 'react'
const TRANSLATES_LANGUAGE = Symbol('TRANSLATES_LANGUAGE')
const IS_TRANSLATE_CHILD =
  'IS_TRANSLATE_CHILD' + Math.random().toString(36).slice(2)

/**
 * Retrieves the languages available at a given
 * translation location so that only one can be chosen.
 *
 * The languages are stringified to simplify memoization.
 * This component could have used state instead, but as
 * it relies on an array of strings, serialization is a
 * simple, reliable way to prevent excessive renders.
 */
export function Translate({ children, ...translationObject }) {
  if (Object.keys(translationObject).length > 0) {
    if (translationObject[currentLanguage]) {
      return translationObject[currentLanguage]
    }

    if (translationObject[currentLanguage.slice(0, 2)]) {
      return translationObject[currentLanguage.slice(0, 2)]
    }

    return (
      translationObject.english ||
      translationObject.en ||
      Object.values(translationObject)[0]
    )
  }

  const childrenByLanguage = {}

  Children.forEach(children, child => {
    if (!child || !child.type || !child.type[TRANSLATES_LANGUAGE]) {
      throw Error(
        'All children of <Translate /> must be translators such as <English /> or <Norwegian />',
      )
    }
    childrenByLanguage[child.type[TRANSLATES_LANGUAGE]] = cloneElement(child, {
      [IS_TRANSLATE_CHILD]: true,
    })
  })

  const getChildren = () => {
    if (childrenByLanguage[currentLanguage]) {
      return childrenByLanguage[currentLanguage].props.children
    }
    if (childrenByLanguage.english) {
      setTimeoutInProd(() => {
        throw Error(
          `Expected translation "${currentLanguage}". Falling back to English. JSX: ${jsxToString(
            children,
          )}`,
        )
      })
      return childrenByLanguage.english.props.children
    }
    {
      const [fallbackLanguage, fallbackTemplate] = Object.entries(
        childrenByLanguage,
      )[0]
      setTimeoutInProd(() => {
        throw Error(
          `Expected translation "${currentLanguage}". Falling back to "${fallbackLanguage}". JSX: ${jsxToString(
            children,
          )}`,
        )
      })
      return fallbackTemplate.props.children
    }
  }

  // console.log('translation:', getChildren())

  return getChildren()
}

function factory(language) {
  function ForLanguage(props) {
    const { children, [IS_TRANSLATE_CHILD]: isTranslateChild } = props

    if (!isTranslateChild) {
      setTimeoutInProd(() => {
        throw Error(
          `Translated sections must be children of <Translate />. A translation with the language code "${language}" failed: ${jsxToString(
            children,
          )}`,
        )
      })
    }

    if (
      currentLanguage === language ||
      (!currentLanguage && language === 'english')
    ) {
      return <div>{children}</div>
    }

    return null
  }

  ForLanguage[TRANSLATES_LANGUAGE] = language

  ForLanguage.displayName = `ForLanguage<language>`

  return ForLanguage
}

export const Norwegian = factory('NO')
export const English = factory('EN')

const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())

export const currentLanguage = params.la || 'NO'

function setTimeoutInProd(fn) {
  if (process.env.NODE_ENV === 'production') {
    setTimeout(fn)
  } else {
    fn()
  }
}

function jsxToString(jsx) {
  try {
    return JSON.stringify(jsx, null, 2).slice(0, 2048)
  } catch (e) {
    return '[cyclic children]'
  }
}
