import { useAutoMemo, useAutoEffect, useAutoCallback } from 'hooks.macro'
import { Translate, Norwegian, English } from 'languageContext'
import withCharacterConfig from './withCharacterConfig'
import LanguageButton from '../common/LanguageButton'
import useCreatePageState from './useCreatePageState'
import { Route, useParams } from 'react-router-dom'
import SceneWrapper from 'common/SceneWrapper'
import usePageLogging from './usePageLogging'
import styled from 'styled-components/macro'
import DebugControls from './DebugControls'
import PreloadImages from './PreloadImages'
import SelectChapter from 'SelectChapter'
import ProvideSounds from 'ProvideSounds'
import ToggleAudio from './ToggleAudio'
import templates from '../templates'
import { useStore } from 'Store'
import context from './context'
import React from 'react'
import Menu from './Menu'


const EXPOSE_DEBUG_VARIABLES = false
/*
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_EXPOSE_DEBUG_VARIABLES === 'true' ||
  (search.debugControls !== undefined && search.debugControls !== 'off')
  */

if (EXPOSE_DEBUG_VARIABLES) {
  const title = name =>
    console.groupCollapsed(`%c${name}`, 'color: rebeccapurple; font-weight: bold')
  const exposed = name => console.log(`%c${name}`, 'color: rebeccapurple')

  title('Exposing debug variables on window')
  exposed('storeAnswer')
  exposed('firstPage')
  exposed('lastPage')
  exposed('nextPage')
  exposed('prevPage')
  exposed('answer')
  exposed('pages')
  console.groupEnd()
}

const win = window as any

export default withCharacterConfig(Storyline)

function Storyline({ characterConfig }) {
  const { hasStarted, audioEnabled, start } = useStore()
  const { character } = useParams()

  const {
    getPrevAnswerForTemplate,
    instantTyping,
    previousDays,
    setPageIndex,
    isOnLastPage,
    storeAnswer,
    pageIndex,
    nextPage,
    prevPage,
    answer,
    pages,
    page,
    days,
    day,
  } = useCreatePageState({
    characterConfig,
    character,
  })

  const Scene = templates[page.type]

  if (!Scene) {
    throw Error(`Scene not found: "${page.type}"`)
  }

  usePageLogging(page)

  const contextValue = useAutoMemo(() => ({
    getPrevAnswerForTemplate,
    instantTyping,
    previousDays,
    setPageIndex,
    isOnLastPage,
    options: page.options,
    storeAnswer,
    template: page.type,
    pageIndex,
    prevPage,
    nextPage,
    answer,
    pages,
    days,
    day,
  }))

  if (EXPOSE_DEBUG_VARIABLES) {
    // eslint-disable-next-line
    const [intervals, setIntervals] = React.useState([])

    // eslint-disable-next-line
    const clearAllIntervals = useAutoCallback(() => {
      for (const interval of intervals) {
        clearInterval(interval)
      }
      setIntervals([])
    })

    // eslint-disable-next-line
    useAutoEffect(() => {
      win.clearAllIntervals = clearAllIntervals
      win.storeAnswer = storeAnswer
      win.nextPage = nextPage
      win.prevPage = prevPage
      win.answer = answer
      win.pages = pages

      win.lastPage = timeout => {
        clearAllIntervals()
        let index = pageIndex
        const interval = setInterval(() => {
          if (index >= pages.length - 1) {
            console.log('At last page')
            clearInterval(interval)
            return
          }
          console.log('skipping...')
          nextPage()
          index += 1
        }, timeout)
        setIntervals(intervals => intervals.concat(interval))
      }

      win.firstPage = timeout => {
        clearAllIntervals()
        let index = pageIndex
        const interval = setInterval(() => {
          if (index <= 0) {
            console.log('At first page')
            clearInterval(interval)
            return
          }
          console.log('skipping...')
          prevPage()
          index -= 1
        }, timeout)
        setIntervals(intervals => intervals.concat(interval))
      }
    })
  }

  const hasBubbleDialogue = useAutoMemo(() => {
    if (page.type !== 'Dialogue') return false
    return page.options.bubble
  })

  return useAutoMemo(() => (
    <ProvideSounds>
      <context.Provider value={contextValue}>
        <PreloadImages />
        {hasStarted || !audioEnabled ? (
          <>
            <Route exact path="/spill/:character">
              <>
                <LanguageButton />
                <ToggleAudio />
                <Menu />
              </>
            </Route>
            <React.Fragment key={`${page.type}-${hasBubbleDialogue}`}>
              {EXPOSE_DEBUG_VARIABLES && <DebugControls />}
              <style>{'h2 { font-weight: 500 }'}</style>
              <Scene {...contextValue} />
            </React.Fragment>
            <Route path="/spill/:character/velg-kapittel">
              <SelectChapter />
            </Route>
          </>
        ) : (
          <SceneWrapper hideStar>
            <Overlay>
              <Continue type="button" onClick={start} title="Fortsett spillet">
                <Translate>
                  <Norwegian>Fortsett spillet</Norwegian>
                  <English>Continue</English>
                </Translate>
              </Continue>
            </Overlay>
          </SceneWrapper>
        )}
      </context.Provider>
    </ProvideSounds>
  ))
}

const Continue = styled.button`
  font-size: calc(40px * var(--scale));
  background: linear-gradient(90deg, #cff3fd, #c2fdeb);
  border: calc(3px * var(--scale)) solid black;
  border-radius: calc(10px * var(--scale));
  padding: calc(3px * var(--scale)) calc(20px * var(--scale)) calc(7px * var(--scale));
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  font-family: 'Walter Turncoat', sans-serif;
  text-transform: lowercase;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
`
