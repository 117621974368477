import { useAutoCallback } from 'hooks.macro'

let cache = {}

export default function useAudio(sound, options = { precache: 1 }) {
  if (!cache[sound]) {
    cache[sound] = []
    for (let i = 0; i < options.precache; i++) {
      cache[sound].push(new Audio(sound))
    }
  }

  return useAutoCallback(() => {
    const audio = cache[sound].shift()
    cache[sound].push(new Audio(sound))
    audio.play().catch(e => {})
  })
}
