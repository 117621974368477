export default `
# Chapter
$ bg = neptunianer-stue
title = Thursday 
subtitle = At Luna’s house 

# Dialogue
[Luna neutral left 220]
[Timo confused right 680]
Timo: I’m bored. Can we do something?

[Luna happy]
Luna: We can make muffins.

[Timo happy]
Timo: Yes! I can help.
Luna: Mum? Can we make muffins?

___: ...

[Luna confused]
Luna: Mum?

[Timo 518]
[Galea neutral 790 right]
Galea: Hm? Yes, that’s a good idea.
Timo: Where is the recipe?

[Galea happy]
Galea: I saw one on the Starpad yesterday.
Timo: Can we use it to look up the recipe?

[Galea neutral]
Galea: I’m not done yet. You have to wait.

[Luna angry]
Luna: You’ve been on it for more than two hours!

[Timo angry]
Timo: We get max two hours of screen time.

[Timo confused] /* Er dette feil??? */
Luna: We all agreed.

[Galea angry]
Galea: You have to wait till I’m done.

[Galea invisible=true 850]
___: ...

Luna: That’s unfair!
Timo: Yes! Not cool!

# Discuss
lines(json) = [
  'Many families have rules about screen time.',
  'Does your family?',
  '',
  'Should screen time rules be different for adults and children?',
]
fontSize = 45

# EvaluateStatements
star = true
title.text = How does your family do it?
title.fontSize = 40
statements(json) = [
  'Our family argues about screen time.',
  'The same screen time rules apply for adults and children.',
  'Adults’ screen time is more important than children’s screen time.',
  'Adults don’t know what the kids will be using the device for.',
  'Screen time is always fine if it’s to do with work or homework.',
]

labels(json) = {
  yes: 'Correct',
  uncertain: 'Sometimes',
  no: 'Not correct',
}

# Dialogue
[Luna neutral left 230]
[Galea sad right 649]
Galea: Sorry. You were right. I used...
Galea: ...the Star-Pad too long.

[Luna angry]
Luna: Yes, that was unfair!
Galea: Yes, you were right. 

[Galea surprised]
Galea: How about we go to the fairground on Saturday?

[Luna happy]
Luna: Fairground! Can Bix come too?

[Galea happy]
Galea: Yes, if she is allowed. 
`
