import { useAutoEffect, useAutoMemo } from 'hooks.macro'
import usePageState from './usePageState'
import * as React from 'react'

/**
 * So sorry for this...
 */
const DELIMITER = '___| This is just a magical delimiter. Never mind it! |___'

/**
 * The reason for using a weird delimiter and not
 * just an array is to ensure that images are not
 * rerendered because of a recalcuation of pages
 * where the actual images that need to be preloaded
 * haven't changed.
 */
export default function PreloadImages() {
  const { pages } = usePageState()

  const imagesString = useAutoMemo(() => {
    const nextImages = []
    for (const page of pages) {
      if (typeof page.options.bg === 'string') {
        nextImages.push(`/media/large/interface/${page.options.bg}.png`)
      }
    }
    return nextImages.join(DELIMITER)
  })

  const [cached, setCached] = React.useState({})

  const remaining = useAutoMemo(() => {
    return imagesString
      .split(DELIMITER)
      .filter(image => !cached[image])
  })

  useAutoEffect(() => {
    if (!remaining.length) return

    const timeout = setTimeout(() => {
      (new Image()).src = remaining[0]
      setCached(prevCached => ({
        ...prevCached,
        [remaining[0]]: true
      }))
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  })

  return null
}
