export default `
# Chapter
$ bg = noah-soverom
title = Thursday
subtitle = At Noah’s house

# Dialogue
[Noah 450 happy flip]
Noah: I’ll log onto Moon Sword to play.

bubble = true
$ bg = noah-laptop

[Noah 400]
[Limal happy right 600 flip]
Limal: Hi, Noah!

[Noah surprised]
Noah: Oh, your sword is really cool!

[Limal happy]
Limal: I got it from a loot box I bought!
Limal: It’s like a treasure chest...
Limal: ...but you never know what you’ll get.

[Noah happy]
Noah: Cool! I want to buy one too.

treasureChest = true
$ bg(json) = { image: 'generisk-laptop', contain: true }
whenHasAnswer(json) = {
  name: 'Noah',
  mood: 'confused',
  text: [
    'I didn’t get it. I’ll try again.',
    'Still didn’t get it! I’ll try again.',
    'No! Not this time either! One more go.',
    'It’s the last of my money. Come on!',
  ],
}
whenIsBroke(json) = {
  name: 'Noah',
  mood: 'surprised',
  text: 'I didn’t get the sword!',
}

Limal: Click the one you want, then open! 

[Limal confused]
[Noah surprised]
Limal: I got it straight away.

[Noah sad]
Noah: I’ll ask my mum for more money.

# Dialogue
$ bg = menneske-stue
[Noah flip 220 sad left]
[Sara 680 neutral right]
Noah: Mum! The game doesn’t work!

[Sara surprised]
Sara: What’s not working?
Noah: I opened loot boxes to get a sword.
Noah: But now I can’t get any more.

[Sara neutral]
Sara: That’s because you’re out of money.
Noah: But Limal got it straight away!
Noah: Can I have money to buy some more?
Sara: No. You’ve spent enough on the game.
Noah: But I want a sword too!
Sara: It’s just random what you get, love!
Sara: Limal was just very lucky.
Noah: Aach, that’s so stupid!

# Choice
title.text = Have you played a game with loot boxes or random prizes you need to pay for?
title.fontSize = 40

choices(json) = [
  { text: 'Yes', fontSize: 50 },
  { text: 'No', fontSize: 50 },
]

# Choice
star = true
title.text = What do you think it is acceptable to spend real money on in a game?
title.fontSize = 40
choices(json) = [
  'More new lives or boards',
  'Buy things that improve your skills',
  'Things that make you look cooler',
  'Not ok to use real money, only points you earn in the game',
  'Surprises or loot boxes because it is exciting',
  'Nothing',
]
`
