import styled from 'styled-components/macro'
import React from 'react'

export default () => {
  return <LogoImg src="/media/large/interface/stjernekolonien-logo.svg" />
}

const LogoImg = styled.img`
  position: absolute;
  top: calc(40px * var(--scale));
  left: 50%;
  transform: translate3d(-50%, calc(-65px * var(--scale)), 0) scale(0.65);
  width: calc(445px * var(--scale));
`
