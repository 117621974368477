export default `
# Chapter
bg = neptunianer-stue
title = Saturday 
subtitle = At Luna’s house

# Dialogue
bg = neptunianer-stue
[Luna left 220 surprised]
[Edan right 690 neutral]

Luna: Hurry up, buy tickets for the fair!
Luna: Buy online and we skip the queue.

[Edan confused]
Edan: Where have you heard that?
Luna: On the fairground website!

[Edan neutral]
Edan: Ok. I’m buying them now.

# Chapter
title = At the fairground
bg = tivoli

# Dialogue
bg = tivoli
[Luna left neutral 210]
[Bix right neutral 480]
[Edan right surprised 800]

Edan: Oy! That’s a long queue!

[Luna happy]
Luna: We were smart to buy tickets online.

[Edan happy]
Edan: Great that you found out about it!
Edan: You saved the whole fairground trip.

[Luna surprised]
Luna: Look at that cool merry-go-round!

[Bix happy]
Bix: Let’s get a ride and take a photo.
Bix: I’ll post it on the class Ding group.
Bix: Is that all right?

[Luna happy]
Luna: Yes. But I want to see it first!
Bix: Of course!

# Text
paragraphs(json) = [
  'Luna was smart and checked online so they didn’t have to queue.',
  '',
  'What other fun and useful things can you do online? There are suggestions on the next page.',
]

# Choice
title(json) = {
  text: 'Choose three things you can do online.',
  fontSize: 45,
}
numberOfChoices = 3
textWhenWrong = Impossible to do online. Choose another option.
choices(json) = [
  { text: 'Read a book' },
  { text: 'Watch a film'},
  { text: 'Stroke a cat', wrong: true },
  { text: 'Go skiing', wrong: true },
  { text: 'Brush your hair', wrong: true },
  { text: 'Listen to music'},
  { text: 'Learn Chinese'},
  { text: 'Talk to friends'},
  { text: 'Eat sweets', wrong: true },
]

# Feedback
$ bg = tegning-luna-bix
download = tegne-luna-bix.jpg
title = Congratulations.
subtitle = You have now completed Luna’s story.
overflow = 105%

# Recommendation 
$ bg = bakgrunn-edan 
title = Congratulations.
overflow = 105%

# Done
$ bg = tegning-luna-bix
download = tegne-luna-bix.jpg
title = Congratulations.
subtitle = You have now completed Luna’s story.
overflow = 105%
`
