import './styles.css'

import { CompletedCharactersController } from 'completedCharactersContext'
import { BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import validateConfig from './validateConfig'
import ReactDOM from 'react-dom'
import Game from './Game'
import React from 'react'

if (process.env.NODE_ENV === 'development') {
  validateConfig()
} else {
  setTimeout(validateConfig)
}

ReactDOM.render(
  <Router>
    <CompletedCharactersController>
      <Game />
    </CompletedCharactersController>
  </Router>,
  document.getElementById('root'),
)

serviceWorker.unregister()
