import { useAutoLayoutEffect, useAutoMemo, useAutoCallback } from 'hooks.macro'
import { useSetCompletedCharacters } from 'completedCharactersContext'
import SceneWrapper, { CollectedStars } from 'common/SceneWrapper'
import { Translate, Norwegian, English } from 'languageContext'
import styled, { css } from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import clientId from 'clientId'
import React from 'react'
import emit from 'emit'

export default function LearningFeedback({ nextPage, options: { title, subtitle, overflow } }) {
  const setCompletedCharacters = useSetCompletedCharacters()
  const [answered, setAnswered] = React.useState(false)
  const { character } = useParams()

  useAutoLayoutEffect(() => {
    setCompletedCharacters(characters => ({
      ...characters,
      [character]: true,
    }))
  })

  const respond = useAutoCallback(event => {
    if (answered) return

    setAnswered(true)

    const response = event.target.textContent

    fetch('/api/learning-feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        character,
        response,
        clientId,
      }),
    }).then(
      () => {
        nextPage()
      },
      error => {
        alert('Din respons kunne ikke sendes: ' + error.message)
        nextPage()
      },
    )

    emit('Fullført story')
  })

  return useAutoMemo(
    <>
      <SceneWrapper
        hideSpaceShip
        bgColor="#9cbed6"
        backgroundBlendMode="multiply"
        overflow={overflow}
      >
        <CharacterSelectTextBoxImage />
        <TextBox>
          <CollectedStars />
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Text>
            <Translate>
              <Norwegian>Lærte dere noe av å spille?</Norwegian>
              <English>Did you learn anything from this game?</English>
            </Translate>
          </Text>
          <ButtonRow>
            <Button onClick={respond}>
              <Translate>
                <Norwegian>Ja</Norwegian>
                <English>Yes</English>
              </Translate>
            </Button>
            <Button onClick={respond}>
              <Translate>
                <Norwegian>Nei</Norwegian>
                <English>No</English>
              </Translate>
            </Button>
            <Button onClick={respond}>
              <Translate>
                <Norwegian>Vet ikke</Norwegian>
                <English>Don’t know</English>
              </Translate>
            </Button>
          </ButtonRow>
        </TextBox>
        <BottomBar>
          <div>
            <img
              className="eu"
              src="/EU.png"
              alt="Co-financed by the European Union. Connecting Europe Facility."
            />
            <img className="medietilsynet" src="/medietilsynet-hvit.png" alt="Medietilsynet" />
          </div>
        </BottomBar>
      </SceneWrapper>
    </>,
  )
}

const CharacterSelectTextBoxImage = styled.img.attrs({
  src: '/media/large/interface/character-select-textbox.png',
  alt: '',
})`
  width: calc(642px * var(--scale));
  height: calc(300px * var(--scale));
  position: absolute;
  bottom: calc(100px * var(--scale));
  left: 50%;
  transform: translateX(-50%);
`

const TextBox = styled.div`
  position: absolute;
  bottom: calc((25px + 20px) * var(--scale));
  width: calc((642px - 40px) * var(--scale));
  height: calc((400px - 40px) * var(--scale));
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.div`
  font-size: calc(30px * var(--scale));
  font-weight: 500;
`

const Subtitle = styled.div`
  font-size: calc(23px * var(--scale));
  font-weight: 500;
`

const btn = css`
  background-color: #48f5cd;
  border: calc(2px * var(--scale)) solid black;
  font-size: calc(20px * var(--scale));
  font-family: 'Walter turncoat', sans-serif;
  line-height: 1;
  padding: calc(10px * var(--scale)) calc(15px * var(--scale));
  border-radius: calc(10px * var(--scale));
  filter: drop-shadow(calc(0px * var(--scale)) calc(4px * var(--scale)) rgba(0, 0, 0, 0.5));
  margin: calc(10px * var(--scale));
  flex-basis: 100%;
  color: black;
  cursor: pointer;
  min-width: calc(138px * var(--scale));
`

const Button = styled.button.attrs({
  type: 'button',
})`
  ${btn};
`

const BottomBar = styled.div`
  background-color: rgba(10, 91, 153, 0.79);
  height: calc(58px * var(--scale));
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    height: 100%;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      vertical-align: top;
      object-fit: contain;

      &.eu {
        width: calc(224px * var(--scale));
        height: calc(26px * var(--scale));
      }

      &.medietilsynet {
        width: calc(216px * var(--scale));
        height: calc(65px * var(--scale));
        transform: translateY(calc(-0px * var(--scale)));
      }
    }
  }
`
const Text = styled.div`
  font-size: calc(40px * var(--scale));
  font-weight: 500;
  margin-bottom: calc(10px * var(--scale));
`

const ButtonRow = styled.div`
  display: flex;
`
