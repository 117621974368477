import { useAutoMemo, useAutoCallback, useAutoEffect } from 'hooks.macro'
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom'
import usePersistentState from '../usePersistentState'
import queryString from 'query-string'
import { useStore } from '../Store'
import clientId from 'clientId'
import React from 'react'

let DEFAULT_PAGE_ID = null
const query = queryString.parse(window.location.search)

if (query.page) {
  DEFAULT_PAGE_ID = query.page
}

export default function usePageState({ characterConfig, character }) {
  const { audioEnabled, setAudioEnabled, toggleAudio } = useStore()
  const { days } = characterConfig
  const pages = days.map(({ pages }) => pages).flat(Infinity)

  const defaultPageIndex = useAutoMemo(() => {
    if (!DEFAULT_PAGE_ID) return

    for (let i = 0; i < pages.length; i++) {
      if (pages[i].id === DEFAULT_PAGE_ID) {
        return i
      }
    }
  })

  let [pageIndex, setPageIndex] = usePersistentState(0, `${character}.pageIndex`, defaultPageIndex)
  if (pageIndex >= pages.length) pageIndex = pages.length - 1
  if (pageIndex < 0) pageIndex = 0
  const page = pages[pageIndex]

  const day = useAutoMemo(days.find(day => day.pages.some(x => x === page)))
  const previousDays = useAutoMemo(days.slice(0, days.indexOf(day)))

  if (!page) {
    throw Error(`No page at page index: ${pageIndex}`)
  }

  useAutoEffect(() => {
    if (defaultPageIndex != null) {
      setPageIndex(defaultPageIndex)
    }
  })

  const [answers, replaceAnswers] = usePersistentState({}, character)
  const [instantTyping, setInstantTyping] = React.useState(false)
  const answer = answers[page.id] ?? null
  const storeAnswer = useAutoCallback(update => {
    replaceAnswers(answers => {
      let answer = answers[page.id]

      if (typeof update === 'function') {
        answer = update(answer)
      } else {
        answer = update
      }

      fetch('/api/answers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          character,
          clientId,
          pageOptions: page.options,
          template: page.type,
          pageId: page.id,
          answer,
        }),
      })

      return {
        ...answers,
        [page.id]: answer,
      }
    })
  })
  const getPrevAnswerForTemplate = useAutoCallback(type => {
    for (let i = pageIndex - 1; i >= 0; i--) {
      if (pages[i].type === type && answers[pages[i].id]) {
        return answers[pages[i].id]
      }
    }
    return null
  })
  const isOnLastPage = pageIndex === pages.length - 1

  const nextPage = useAutoCallback(() => {
    return new Promise(resolve => {
      batchedUpdates(() => {
        setInstantTyping(false)
        setPageIndex(i => {
          if (i + 1 >= pages.length) {
            return i
          }
          setTimeout(resolve)
          return i + 1
        })
      })
    })
  })

  const prevPage = useAutoCallback(() => {
    return new Promise(resolve => {
      batchedUpdates(() => {
        setInstantTyping(true)
        setPageIndex(i => {
          if (i <= 0) {
            return 0
          }
          setTimeout(resolve)
          return i - 1
        })
      })
    })
  })

  return useAutoMemo(() => ({
    getPrevAnswerForTemplate,
    setAudioEnabled,
    instantTyping,
    audioEnabled,
    previousDays,
    setPageIndex,
    isOnLastPage,
    toggleAudio,
    storeAnswer,
    pageIndex,
    nextPage,
    prevPage,
    answer,
    pages,
    page,
    days,
    day,
  }))
}
