export default `
# Chapter
$ bg = barn-klasserom
title = Wednesday 
subtitle = At school 

# Dialogue
[Noah left neutral flip 240]
[Limal right neutral 680]
Noah: Hi Limal! Have you heard that...
Noah: ... we’ll have a starstorm tomorrow?

[Limal happy]
Limal: Then we won’t have to go to school!

[Noah happy]
Noah: We can play Moon Sword all day!
Limal: Yes!

# Chapter
$ bg = menneske-stue
title = At Noah’s house 

# Dialogue
[Noah left neutral flip 210]
[Mira right neutral 720]
Mira: Hi! I’m going to babysit.

[Mira happy]
Mira: Would you like to watch a film?

[Noah happy]
Noah: Can we watch Super-Dino?
Mira: No, that’s too boring.
Mira: Let’s watch Star Battle.

[Noah surprised]
Noah: Great!

[Mira confused]
Mira: It has a 12-year rating but...

[Mira happy]
Mira: ... you’re tough, so it’ll be fine.

# Chapter
title = Two hours later 

# Dialogue
[Noah sad flip 460]
Noah: That film was pretty scary.
Noah: I don’t want to sleep on my own...
Noah: ...but I don’t dare tell Mira.

# Choice
title.fontSize = 45
title.text = Why do films have age ratings? 
subtitle = Several answers may be right, choose one 
choices(json) = [
  { text: 'The people in the film are swearing' },
  { text: 'There is lots of fighting and shooting' },
  { text: 'Scary things are happening in them' },
  { text: 'Fun things are happening in them', wrong: true },
  { text: 'The people in the film are naked' },
  { text: 'The film is boring for children', wrong: true },
]
tipsrakett.title = Why are films age rated?
tipsrakett.lines(json) = [
'Films are age rated to protect children from content that may be harmful. This means that children may become anxious or frightened by what they see, or that the content makes them feel uncomfortable, upset or offended. As age ratings do not indicate whether a film is suitable for children, it may be a good idea to check the film’s target audience as well. Children have different comfort thresholds and levels of maturity. It is important to take this into account when you choose a film.',
'If you want to know more about age ratings or to look up the age rating of a film at the cinema, go to the Norwegian Media Authority’s website or film database: https://medietilsynet.no/filmdatabasen',
]

# Discuss
star = true
paragraphs(json) = [
'What do you do if you have watched something a bit too scary? And what do you think your mum or dad should do?',
]
`
