export default `
# Chapter
bg = neptunianer-stue
title = Lørdag
subtitle = Hjemme hos Luna

# Dialogue
bg = neptunianer-stue
[Luna left 220 surprised]
[Edan right 690 neutral]

Luna: Vi må forte oss å kjøpe billetter til tivoliet!
Luna: Kjøp på nettet, så slipper vi å stå i kø.

[Edan confused]
Edan: Hvor har du hørt det?
Luna: På nettsiden til tivoliet!

[Edan neutral]
Edan: Ok. Jeg kjøper nå.

# Chapter
title = På tivoliet
bg = tivoli

# Dialogue
bg = tivoli
[Luna left neutral 210]
[Bix right neutral 480]
[Edan right surprised 800]

Edan: Oi! Det var lang kø!

[Luna happy]
Luna: Det var smart å kjøpe billetter på nett.

[Edan happy]
Edan: Bra at du fant ut av det!
Edan: Du reddet hele tivolituren.

[Luna surprised]
Luna: Oh, se den fine karusellen!

[Bix happy]
Bix: La oss kjøre og ta et bilde sammen.
Bix: Jeg kan poste det på Ding-gruppa...
Bix: ...til klassen. Er det greit?

[Luna happy]
Luna: Ja. Men jeg vil se på bildet først!
Bix: Selvfølgelig!

# Text
paragraphs(json) = [
  'Luna var smart og sjekket på internett, så de slapp å stå i den lange køen.',
  '',
  'Hvilke andre nyttige og morsomme ting er det mulig å gjøre på nettet? På neste side ser dere forslag.'
]

# Choice
title(json) = {
  text: 'Velg tre ting du kan gjøre på nett',
  fontSize: 45,
}
numberOfChoices = 3
textWhenWrong = Kan ikke gjøres på nett. Velg en annen.
choices(json) = [
  { text: 'Lese en bok' },
  { text: 'Se en film' },
  { text: 'Klappe en katt', wrong: true },
  { text: 'Stå på ski ute', wrong: true },
  { text: 'Gre håret ditt', wrong: true },
  { text: 'Høre på musikk' },
  { text: 'Lære kinesisk' },
  { text: 'Snakke med venner' },
  { text: 'Spise godteri', wrong: true },
]

# Feedback
$ bg = tegning-luna-bix
download = tegne-luna-bix.jpg
title = Gratulerer.
subtitle = Du er nå ferdig med Luna sin historie.
overflow = 105%


# Recommendation 
$ bg = bakgrunn-edan 
title = Gratulerer.
overflow = 105%

# Done
$ bg = tegning-luna-bix
download = tegne-luna-bix.jpg
title = Gratulerer.
subtitle = Du er nå ferdig med Luna sin historie.
overflow = 105%
`
