import { Translate, Norwegian, English } from 'languageContext'
import { useHistory, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import usePageState from 'Storyline/usePageState'
import { useAutoMemo } from 'hooks.macro'
import React from 'react'

export default function SelectChapter() {
  const { days, day: currentDay, setPageIndex } = usePageState()
  const { character } = useParams()
  const history = useHistory()

  const jumpTo = jumpToDay => {
    let pageIndex = 0

    for (const day of days) {
      if (day === jumpToDay) break
      for (let i = 0; i < day.pages.length; i++) {
        pageIndex += 1
      }
    }

    setPageIndex(pageIndex)
    history.push({ pathname: `/spill/${character}`, search: history.location.search })
  }

  return useAutoMemo(
    <Container>
      <Days>
        {days?.map?.(day => (
          <Day key={day.title} active={day === currentDay} onClick={() => jumpTo(day)}>
            <Title>{day.title}</Title>
            <Subtitle>{day.subtitle}</Subtitle>
            {day === currentDay ? (
              <YouAreHere>
                <Translate>
                  <Norwegian>Du er her</Norwegian>
                  <English>You are here</English>
                </Translate>
              </YouAreHere>
            ) : (
              <JumpHere>
                <Translate>
                  <Norwegian>Hopp hit</Norwegian>
                  <English>Jump here</English>
                </Translate>
              </JumpHere>
            )}
          </Day>
        ))}
      </Days>
      <CloseButton
        onClick={() =>
          history.push({ pathname: `/spill/${character}`, search: history.location.search })
        }
        title="Lukk menyen"
        width={44}
        height={44}
      />
    </Container>,
  )
}

const Container = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background-color: rgba(10, 91, 153, 0.8);
  display: flex;
  align-items: flex-start;
`

const Days = styled.div`
  margin: auto;
`

const Day = styled.button.attrs({
  type: 'button',
})`
  width: calc(660px * var(--scale));
  background-color: white;
  border: calc(2px * var(--scale)) solid black;
  padding: calc(20px * var(--scale)) calc(40px * var(--scale));
  border-radius: calc(10px * var(--scale));
  margin-bottom: calc(10px * var(--scale));
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;

  ${p =>
    !p.active &&
    css`
      :hover {
        background-color: #48f5cd;
        & ${JumpHere} {
          opacity: 1;
        }
      }
    `}
`

const Title = styled.div`
  font-size: calc(30px * var(--scale));
  width: calc(160px * var(--scale));
  flex-shrink: 0;
`

const Subtitle = styled.div`
  flex-basis: 100%;
  font-size: calc(21px * var(--scale));
  padding-right: calc(20px * var(--scale));
  padding-left: calc(15px * var(--scale));
`

const btn = css`
  border: calc(2px * var(--scale)) solid black;
  filter: drop-shadow(0 calc(4px * var(--scale)) 0 rgba(0, 0, 0, 0.5));
  width: calc(60px * var(--scale));
  height: calc(60px * var(--scale));
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) translate3d(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(5px * var(--scale));
  font-family: 'Walter turncoat', sans-serif;
  font-size: calc(15px * var(--scale));
  line-height: 1;
`

const YouAreHere = styled.div`
  ${btn}
  background-color: #d381ff;
  border-radius: calc(1000px * var(--scale));
  text-align: center;
`

const JumpHere = styled.div`
  ${btn}
  background-color: #48F5CD;
  border-radius: calc(10px * var(--scale));
  text-align: center;
  opacity: 0;
`

const CloseButton = styled.button.attrs({
  type: 'button',
})`
  background: url('/media/large/interface/close-button.svg');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  position: absolute;
  right: calc(10px * var(--slight-scale));
  top: calc(10px * var(--slight-scale));
  width: calc(${p => p.width}px * var(--slight-scale));
  height: calc(${p => p.height}px * var(--slight-scale));
`
