import localStorage from 'localStorage'

const PREFIX = 'update1_familiespill.'

const storage = {
  delete(key, { emitUpdate = true } = {}) {
    localStorage.setItem(PREFIX + key, '')
    if (emitUpdate) {
      this.emitUpdate(key)
    }
  },
  set(key, value, { emitUpdate = true } = {}) {
    localStorage.setItem(PREFIX + key, JSON.stringify(value))
    if (emitUpdate) {
      this.emitUpdate(key, value)
    }
  },
  get(key) {
    const raw = localStorage.getItem(PREFIX + key)
    try {
      return JSON.parse(raw)
    } catch (e) {}
  },
  has(key) {
    const raw = localStorage.getItem(PREFIX + key)

    if (!raw) {
      return false
    }

    try {
      JSON.parse(raw)
      return true
    } catch (e) {
      return false
    }
  },
  listeners: new Map(),
  onChange(key, fn) {
    if (!this.listeners.has(key)) {
      this.listeners.set(key, new Set())
    }
    this.listeners.get(key).add(fn)
    return () => {
      this.listeners.get(key).delete(fn)
    }
  },
  emitUpdate(key, value) {
    if (!this.listeners.has(key)) return
    this.listeners.get(key).forEach(fn => fn(value))
  },
  list() {
    const result = {}
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key.startsWith(PREFIX)) {
        result[key.replace(PREFIX, '')] = localStorage.getItem(key)
      }
    }
    return result
  },
}

if (process.env.NODE_ENV === 'development') {
  ;(window as any).storage = storage
}

export default storage
