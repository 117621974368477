import { useAutoEffect, useAutoMemo } from 'hooks.macro'

export default function useEventHandler(element, event, handler, ...restArgs) {
  const shouldCancel = useAutoMemo(() => {
    if (restArgs.length) {
      const condition = restArgs[0]
      return !condition
    }
  })

  useAutoEffect(() => {
    if (!element || shouldCancel) return

    element.addEventListener(event, handler)

    return () => {
      element.removeEventListener(event, handler)
    }
  })
}
