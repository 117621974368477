export default `
# Chapter
$ bg = neptunianer-stue
title = Torsdag
subtitle = Hjemme hos Luna

# Dialogue
[Luna neutral left 220]
[Timo confused right 680]
Timo: Jeg kjeder meg. Skal vi finne på noe?

[Luna happy]
Luna: Vi kan lage muffins.

[Timo happy]
Timo: Ja! Jeg kan hjelpe til.
Luna: Mamma? Kan vi lage muffins?

___: ...

[Luna confused]
Luna: Mamma?

[Timo 518]
[Galea neutral 790 right]
Galea: Hm? Ja det høres godt ut.
Timo: Hvor er oppskriften?

[Galea happy]
Galea: Jeg så en på Star-Padden i går.
Timo: Kan vi bruke den for å se på oppskriften?

[Galea neutral]
Galea: Jeg er ikke ferdig. Dere må vente litt.

[Luna angry]
Luna: Du har brukt Star-Padden i over to timer!

[Timo angry]
Timo: Alle får maks to timer skjerm om dagen.

[Timo confused] /* Er dette feil??? */
Luna: Alle var enige om det.

[Galea angry]
Galea: Dere får vente til jeg er ferdig.

[Galea invisible=true 850]
___: ...

Luna: Så urettferdig!
Timo: Ja! Ikke greit!

# Discuss
lines(json) = [
  'Mange familier har regler om skjermtid.',
  'Har dere noen?',
  '',
  'Bør det være ulike regler om skjermtid for voksne og barn?',
]
fontSize = 45

# EvaluateStatements
star = true
title.text = Hvordan er det hos dere?
title.fontSize = 40
statements(json) = [
  'Vi krangler om skjermtid i vår familie.',
  'Det er samme regler om skjermtid for voksne som for barn.',
  'Det er viktigere når voksne sitter foran en skjerm, enn når barn gjør det.',
  'Voksne vet ikke hva barna skal bruke skjermen til.',
  'Det er alltid greit å sitte foran en skjerm hvis det er til arbeid eller lekser.',
]
labels(json) = {
  yes: 'Stemmer',
  uncertain: 'Av og til',
  no: 'Stemmer ikke',
}

# Dialogue
[Luna neutral left 230]
[Galea sad right 649]
Galea: Unnskyld. Dere hadde rett. Jeg brukte...
Galea: ...Star-Padden for lenge i stad.

[Luna angry]
Luna: Ja, det var urettferdig!
Galea: Ja, dere hadde rett.

[Galea surprised]
Galea: Hva sier du til å dra på tivoli på lørdag?

[Luna happy]
Luna: Tivoli! Kan Bix bli med?

[Galea happy]
Galea: Ja, hvis hun får lov.
`
