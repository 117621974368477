export default `
# Chapter
$ bg = noah-soverom
title = Lørdag
subtitle = Hjemme hos Noah

# Dialogue
[Noah neutral 240 flip left]
[Sara happy 700 right]
Sara: Jeg fikk en mail fra læreren din.
Sara: Han sa du har blitt så flink i engelsk.

[Noah happy]
Noah: Jeg har lært masse fra MåneSverdet.
Noah: Alt i spillet er jo på engelsk.

[Sara surprised]
Sara: Hm. Det har jeg ikke tenkt på.
Noah: Vi snakker jo engelsk med andre som spiller.

[Sara happy]
Sara: Åja, så bra!
Sara: Du skal få noe fra Måne-butikken i dag...
Sara: ...siden du har vært så flink på skolen.
Noah: Jaaa!

# Chapter
$ bg = monesverdet-shop
title.text = I MåneSverdet butikken
title.fontSize = 40

# Dialogue
[Noah 180 neutral left flip]
[Limal 454 surprised]
[Sara neutral 800 neutral]
Limal: Woah! Kul butikk!

[Noah surprised]
Noah: Ja! De har jo alt her!
Limal: Se! Ekte versjoner av det sverdet jeg har!
Noah: Mamma, kan jeg få et sånt?

[Sara happy]
Sara: Jada, dere skal få et hver fordi...
Sara:...Limal må jo få en bursdagspresang.

[Noah happy]
Noah: Yeeeey!

# Text
paragraphs(json) = [
  'Noah har blitt ekstra flink til å lese og skrive engelsk fordi han spiller et online spill.',
  'Hvilke andre nyttige og morsomme ting er det mulig å gjøre på nettet? På neste side ser dere forslag.',
]

# Choice
title.fontSize = 30
title.text = Velg tre ting du kan gjøre på nett
textWhenWrong = Kan ikke gjøres online. Velg en annen.

choices(json) = [
  { text: 'Lese om dinosaurer' },
  { text: 'Skrive historier' },
  { text: 'Spise middag', wrong: true },
  { text: 'Svømme i svømmehallen', wrong: true },
  { text: 'Leke gjemsel ute', wrong: true },
  { text: 'Gjøre lekser' },
  { text: 'Se en film' },
  { text: 'Snakke med venner' },
  { text: 'Spille fotball ute', wrong: true },
]
numberOfChoices = 3

# Feedback
$ bg = tegning-limal-noah
title = Gratulerer.
subtitle = Du er nå ferdig med Noah sin historie.
overflow = 132%

# Recommendation 
$ bg = bakgrunn-edan 
title = Gratulerer.
overflow = 105%

# Done
$ bg = tegning-limal-noah
download = tegne-limal-noah.jpg
title = Gratulerer.
subtitle = Du er nå ferdig med Noah sin historie.
overflow = 132%
`
