import { useAutoLayoutEffect, useAutoMemo } from 'hooks.macro'
import usePersistentState from 'usePersistentState'
import storage from 'storage'
import React from 'react'

const context = React.createContext<{
  value: string[]
  set: any
}>({
  value: [],
  set: () => {},
})

const { Provider } = context

export function CompletedCharactersController({ children }) {
  const [value, set] = usePersistentState({}, 'completedCharactersObject')

  // XXX: This is to backport unlocking characters
  //      to previously completed participants.
  useAutoLayoutEffect(() => {
    if (storage.get('luna.pageIndex') >= 147) {
      set(prev => ({
        ...prev,
        luna: true,
      }))
    }

    if (storage.get('noah.pageIndex') >= 157) {
      set(prev => ({
        ...prev,
        noah: true,
      }))
    }
  })

  const contextValue = useAutoMemo({
    value,
    set,
  })

  return <Provider value={contextValue}>{children}</Provider>
}

export function useCompletedCharacters() {
  return React.useContext(context).value
}

export function useSetCompletedCharacters() {
  return React.useContext(context).set
}
