export default `
# Chapter
$ bg = barn-klasserom
title = Fredag
subtitle = På skolen

# Dialogue
[Noah flip left happy 240]
[Limal right neutral 700]
Noah: MåneSverdet-butikken åpner i morgen.

[Limal happy]
Limal: Ja! Jeg gleder meg sånn.
Limal: Skal vi spille MåneSverdet i kveld?

[Noah confused]
Noah: Nope. Skal spille brettspill med mamma.

[Noah neutral]
Noah: Vi har skjermfri dag hver fredag.

[Limal neutral]
Limal: Kult. Kan jeg bli med en gang?
Noah: Hehe. Det kan du sikkert.

# Chapter
$ bg = menneske-stue
title = Hjemme hos Noah

# Dialogue
[Noah flip 240 left happy]
[Sara right neutral 700]
Noah: Du er hjemme! Skal vi spille brettspill nå?

[Sara surprised]
Sara: Oi da! Vi skulle jo ha skjermfri kveld.

[Sara sad]
Sara: Men jeg må jobbe.

[Noah angry]
Noah: Men du har sittet med laptopen...
Noah: ...hele dagen på jobben allerede.
Sara: Ja, jeg vet det.

[Noah confused]
Noah: Hvor lenge er det til du er ferdig da?
Sara: Det tar nok hele kvelden.

[Noah sad]
Noah: Men jeg får bare lov til å sitte med...
Noah: ...laptopen én time hver dag. Hvorfor...
Noah: ...får du bruke den så mye du vil?
Sara: Dette er annerledes. Det er jobb.

[Noah angry]
Noah: Det er urettferdig! Du lovet å spille.
Sara: Vi får gjøre det en annen dag Noah.

[Noah sad]
Noah: Du lovet...

[Sara right sad 700]
[Noah invisible=true flip=false 100]
___: ...

# EvaluateStatements
star = true
title.text = Hvordan er det hos dere?
title.fontSize = 40
labels.yes = Stemmer
labels.uncertain = Av og til
labels.no = Stemmer ikke

statements(json) = [
  'Vi krangler om skjermtid i vår familie',
  'Det er samme regler om skjermtid for voksne som for barn',
  'Det er viktigere når voksne sitter foran en skjerm enn når barn gjør det',
  'Voksne vet ikke hva barna bruker skjermen til',
  'Det er alltid greit å sitte foren skjerm hvis det er arbeid eller lekser',
]

# Discuss
fontSize = 45
lines(json) = [
  'Det kan være lurt å ha regler for skjermtid som alle blir enige om. Kom frem til tre gode regler for barn og voksne.',
]
`
